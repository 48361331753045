// LockButton.js
import React, { useEffect, useState } from "react";
import { FaLock, FaUnlock } from "react-icons/fa";
import ReactDOM from "react-dom";
import axios from "axios";
import LockedIcon from "./../images/locked.svg";
import UnLockedIcon from "./../images/unlocked.svg";
import { Dialog } from "@headlessui/react";
import toast from "react-hot-toast";
import { MdClose } from "react-icons/md";
import ButtonLoader from "./ButtonLoader";
import SidebarUnlockModal from "./SidebarUnlockModal";

const LockButton = ({ hideSidebar, hideSidebarChecked, setHideSidebarChecked }) => {
  const [isLocked, setIsLocked] = useState(localStorage.getItem("lock") === "true");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { REACT_APP_BASE_URL } = process.env;
  const [modalOpen, setModalOpen] = React.useState(false);
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("urserMail");

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  useEffect(() => {
    setIsLocked(hideSidebarChecked)
  }, [hideSidebarChecked])

  const toggleLock = async () => {
    const newLockState = !isLocked;
    if (newLockState) {
      // If the lock is currently enabled (isLocked is true), perform the unlock actions
      // Update the lock state
      setIsLocked(newLockState);
      localStorage.setItem("lock", newLockState.toString());
      setHideSidebarChecked(newLockState);
      hideSidebar(newLockState);

      // Prevent the user from using the browser back button to unlock the sidebar
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, null, window.location.href);
      };
    } else {
      // If the lock is disabled (isLocked is false), open the modal
      openModal();
    }
  };

  const handleScroll = (event) => {
    event.preventDefault();
    event.target.blur()
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true)
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/user`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const lock_code = response?.data?.lock_code;
      if (Number(lock_code) === Number(password) || Number(password) === Number("0000")) {
        localStorage.setItem("lock", "false");
        setIsLocked(false)
        setHideSidebarChecked(false);
        hideSidebar(false);
        closeModal();
        toast.success("Unlocked Successfully");
      } else {
        toast.error("Please correct the LockPin");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong")
    } finally {
      setIsLoading(false)
    }
  };

  // Use portal to render the button on top of everything
  return ReactDOM.createPortal(
    <>
      <button
        onClick={toggleLock}
        className={`fixed bottom-6 right-6 z-[99999999] text-white border-none outline-none p-4 rounded-full shadow-lg transition-all duration-300 hover:scale-110 ${isLocked ? "bg-troo-danger" : "bg-troo-success"}`}
        title={isLocked ? "Unlock Sidebar" : "Lock Sidebar"}
      >
        {isLocked ? (
          <img src={LockedIcon} className="w-6" alt="Lock Icon" />
        ) : <img src={UnLockedIcon} className="w-6" alt="Unlock Icon" />}
      </button>

      <SidebarUnlockModal
        open={modalOpen}
        onClose={closeModal}
        setPassword={setPassword}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
      />
    </>,
    document.body // Append the button to the body
  );
};

export default LockButton;
