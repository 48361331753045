import React from "react";
import { MdOutlineDateRange } from "react-icons/md";

const WhenAction = () => {
  return (
    <div className='className="flex flex-col gap-8"'>
      <h1>When ?</h1>
      <form className="flex flex-col gap-16 mt-10">
        <div className="flex flex-col gap-3">
          <label
            className="flex gap-2 text-base font-bold mb-3 text-[#4B4B4B]"
            for="typeLocation"
          >
            <MdOutlineDateRange className="  text-troo-secondery w-5 h-5 " />
            Starts at
          </label>
          <div className="flex gap-10">
            <input
              className="appearance-none border-none bg-[#F6F7FB] rounded-lg  py-3 px-3 text-gray-700 leading-tight focus:outline-none "
              id="typeLocation"
              type="date"
              placeholder="Input the physical location for people to join your action"
            />
            <input
              className="appearance-none border-none bg-[#F6F7FB] rounded-lg  py-3 px-3 text-gray-700 leading-tight focus:outline-none "
              id="typeLocation"
              type="time"
              placeholder="Input the physical location for people to join your action"
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <label
            className="flex gap-2 text-base font-bold mb-3 text-[#4B4B4B]"
            for="typeLocation"
          >
            <MdOutlineDateRange className="  text-troo-secondery w-5 h-5 " />
            Ends at
          </label>
          <div className="flex gap-10">
            <input
              className="appearance-none border-none bg-[#F6F7FB] rounded-lg py-3 px-3 text-gray-700 leading-tight focus:outline-none "
              id="typeLocation"
              type="date"
              placeholder="Input the physical location for people to join your action"
            />
            <input
              className="appearance-none border-none bg-[#F6F7FB] rounded-lg py-3 px-3 text-gray-700 leading-tight focus:outline-none "
              id="typeLocation"
              type="time"
              placeholder="Input the physical location for people to join your action"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WhenAction;
