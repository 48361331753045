import React from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { FaUserCheck } from "react-icons/fa6";

const InvitedList = () => {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between gap-5 border-b-2 pb-5">
        <h1>Invitee List</h1>
        <form className="flex gap-3 items-center">
          <button>
            <HiOutlineSearch className="w-6 h-6" />
          </button>
          <input
            type="text"
            placeholder="Search users"
            className="border-none bg-[#F6F7FB] rounded font-outfit"
          />
        </form>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex justify-between gap-5 items-center">
          <div className="flex gap-5 items-center">
            <div>
              <h5 className="text-troo-white rounded-full bg-troo-primary w-10 h-10 flex justify-center items-center">
                JH
              </h5>
            </div>
            <div>
              <h5>Juan Guillermo Reyes Hernandez</h5>
              <p className="text-troo-secondery">Account Owner</p>
            </div>
          </div>
          <button>
            <FaUserCheck className="w-6 h-6 text-troo-secondery" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvitedList;
