import React from "react";
import { FaLocationDot } from "react-icons/fa6";

const WhereLocation = () => {
  return (
    <div className="flex flex-col gap-5">
      <h1>Where?</h1>
      <form>
        <div className="mb-4">
          <label
            className="flex gap-2 text-base font-bold mb-3 text-[#4B4B4B]"
            for="typeLocation"
          >
            <FaLocationDot className="  text-troo-secondery w-5 h-5 " />
            Action Location
          </label>
          <input
            className="appearance-none border-none bg-[#F6F7FB] rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none "
            id="typeLocation"
            type="text"
            placeholder="Input the physical location for people to join your action"
          />
        </div>
        <div className="mt-8">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d59521.67979231097!2d72.82051765!3d21.18798795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707735778947!5m2!1sen!2sin"
            className="w-full min-h-[300px]"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="mapp"
          />
        </div>
      </form>
    </div>
  );
};

export default WhereLocation;
