import React, { useEffect, useState } from "react";
import { IoMdStar } from "react-icons/io";

import axios from "axios";
import toast from "react-hot-toast";
const { REACT_APP_BASE_URL } = process.env;

const DigitalReach = () => {
  const token = localStorage.getItem("token");
  const [totalAttempt, setTotalAttempts] = useState(0);
  const [totalConversion, setTotalConversion] = useState(0);
  const [totalRating, setTotalRating] = useState(0);
  const [contactedVoters, setContactedVoters] = useState(0);
  const userMail = localStorage.getItem("urserMail");

  const fatchStats = async () => {
    await axios
      .post(
        `${REACT_APP_BASE_URL}/contactStatistics `,
        {
          email: userMail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTotalAttempts(res?.data?.tracking_attempts || 0);
        setTotalConversion(res?.data?.tracking_conversion_percent || 0);
        setTotalRating(res?.data?.tracking_rating || 0)
        setContactedVoters(res?.data?.tracking_contacted_voters_percent || 0)

      }).catch((e) => {
        console.error(e)
      })
  };
  // const fatchAttempts = async () => {
  //   await axios
  //     .post(
  //       `${REACT_APP_BASE_URL}/householdAttempts`,
  //       {
  //         email: userMail,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )

  //     .then((res) => {
  //       setTotalAttempts(res?.data?.total);
  //     });
  // };
  // const fatchConverssion = async () => {
  //   await axios
  //     .post(
  //       `${REACT_APP_BASE_URL}/householdConversion`,
  //       {
  //         email: userMail,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setTotalConversion(res?.data?.conversion_rate);
  //     });
  // };
  // const fatchRating = async () => {
  //   await axios
  //     .post(
  //       `${REACT_APP_BASE_URL}/householdRating`,
  //       {
  //         email: userMail,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )

  //     .then((res) => {
  //       setTotalRating(res?.data?.averageRating);
  //     });
  // };

  useEffect(() => {
    if (navigator.onLine) {
      setTimeout(() => {
        fatchStats()
        // fatchAttempts();
      }, 1000);
      // setTimeout(() => {
      //   fatchConverssion();
      // }, 3000);
      // setTimeout(() => {
      //   fatchRating();
      // }, 2000);
    }
  }, []);

  return (
    <div className="bg-troo-userbar h-screen w-full md:px-12 px-4 py-8 flex flex-col gap-8">
      <div className="bg-troo-white md:p-8 p-3 flex flex-col gap-8 md:gap-3 rounded">
        <h1>Dashboard</h1>
        <div className="flex flex-col md:flex-row md:items-center gap-6 lg:gap-16">
          <div className="flex flex-col gap-1">
            <h3 className="text-troo-secondery font-semibold">Attempts</h3>
            <h3 className="font-normal">{totalAttempt}</h3>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-troo-secondery font-semibold">
              Contacted Rate
            </h3>
            <h3 className="font-normal">{contactedVoters} %</h3>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-troo-secondery font-semibold">
              Conversion Rate
            </h3>
            <h3 className="font-normal">{totalConversion} %</h3>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-troo-secondery font-semibold">Rating</h3>
            <h3 className="font-normal flex gap-1 items-center">
              {totalRating} <IoMdStar />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalReach;
