import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";

const WhoInvited = () => {
  const [active, setActive] = useState("Permission");
  const [teamActive, setTeamActive] = useState("Juan G Reyes's Team");

  const handleTabClick = (tab) => {
    setActive(tab);
  };

  const handleTeamClick = (team) => {
    setTeamActive(team);
  };

  return (
    <div className="flex flex-col gap-5">
      <h1>Who's Invited?</h1>
      <div className="flex gap-5 items-center">
        {["Everyone", "Permission", "Teams", "Users"].map((tab) => (
          <button
            key={tab}
            className={`px-5 py-2 uppercase text-troo-white rounded ${
              active === tab ? "bg-[#169265]" : "bg-[#9B9B9B]"
            }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      {active === "Permission" && (
        <div className="flex flex-col gap-3">
          <h3 className="flex items-center gap-3 text-troo-secondery">
            <FaUserCircle className="w-6 h-6" /> Permission
          </h3>
          <p className="text-lg">
            Give access to this action by permission level, allowing any users
            with the selected permissions to attend this action.
          </p>
          <form>
            <div className="flex flex-col gap-3">
              {[
                "Account Owner",
                "Account Manager",
                "Staffer",
                "Lead Organizer",
                "Organizer",
              ].map((role) => (
                <div key={role} className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="shrink-0 mt-0.5 h-6 w-6 border-2 border-troo-secondery dark:checked:bg-troo-primary dark:checked:border-troo-primary"
                    id={role}
                  />
                  <label
                    htmlFor={role}
                    className="font-outfit font-semibold text-[20px]"
                  >
                    {role}
                  </label>
                </div>
              ))}
            </div>
            <div className="flex gap-3 items-center border-t mt-5 pt-5">
              <input
                type="checkbox"
                className="shrink-0 mt-0.5 h-6 w-6 border-2 border-troo-secondery dark:checked:bg-troo-primary dark:checked:border-troo-primary"
                id="send-email-invite"
              />
              <div className="flex flex-col font-outfit text-base">
                <h5 className="font-semibold">
                  Send an email invite to all invitees?
                </h5>
                <p className="text-troo-secondery">
                  If you do not opt to send email invites, invitees will still
                  have access to view and attend the action, however, they will
                  not receive an email invite.
                </p>
              </div>
            </div>
          </form>
        </div>
      )}
      {active === "Teams" && (
        <div className="flex flex-col gap-3">
          <h3 className="flex items-center gap-3 text-troo-secondery">
            <FaUserCircle className="w-6 h-6" /> Teams
          </h3>
          <p className="text-lg">
            Give access to this action by permission level, allowing any users
            with the selected permissions to attend this action.
          </p>
          <div>
            <div className="inline-flex flex-col gap-5">
              {[
                "Juan G Reyes's Team",
                "Digital Reach’s Team",
                "Roberto’s Team",
              ].map((team) => (
                <button
                  key={team}
                  onClick={() => handleTeamClick(team)}
                  className={`px-3 py-1 text-troo-white rounded ${
                    teamActive === team ? "bg-[#169265]" : "bg-[#9B9B9B]"
                  }`}
                >
                  {team}
                </button>
              ))}
            </div>
          </div>
          <div className="flex gap-3 items-center border-t mt-5 pt-5">
            <input
              type="checkbox"
              className="shrink-0 mt-0.5 h-6 w-6 border-2 border-troo-secondery dark:checked:bg-troo-primary dark:checked:border-troo-primary"
              id="send-email-invite"
            />
            <div className="flex flex-col font-outfit text-base">
              <h5 className="font-semibold">
                Send an email invite to Team’s invitees?
              </h5>
              <p className="text-troo-secondery">
                If you do not opt to send email invites, invitees will still
                have access to view and attend the action, however, they will
                not receive an email invite.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhoInvited;
