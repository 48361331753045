import React from 'react';

const SearchInputField = ({
  label,
  name,
  value,
  onChange,
  type = false,
  options = [],
}) => {
  if (type === "select" || type === "radio") {
    return (
      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3 flex items-start justify-start">
          <label
            className="text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4 w-auto"
            htmlFor="input-field"
          >
            {label}
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            name={name}
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="assigned-email-select"
            value={value}
            onChange={onChange}
          >
            <option value="">Select {label}</option>
            {options.map((data, i) => (
              <option key={i} value={data.value}>
                {data.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  } else {
    return (
      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label
            className="text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4"
            htmlFor="input-field"
          >
            {label}
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="input-field"
            type="text"
            value={value}
            name={name}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
};

export default SearchInputField;
