import React from "react";
import { BiQuestionMark } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { TbCameraPlus } from "react-icons/tb";

const ActionFinal = () => {
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File uploaded:", file);
    } else {
      console.log("No file selected.");
    }
  };
  return (
    <div className="bg-troo-userbar w-full px-12 py-8 flex flex-col gap-8">
      <div className="bg-troo-white p-8 flex flex-col gap-3 rounded">
        <div className="flex items-center gap-5">
          <h1>Actions</h1>
          <div className="w-6 h-6 bg-troo-secondery text-troo-white rounded-full flex justify-center items-center">
            <BiQuestionMark />
          </div>
        </div>
        <h4 className="text-troo-secondery">
          For all your outreach scheduling, shift management, field operations
          and
          <br />
          event planning
        </h4>
      </div>
      <div className="py-5 border-b-2 flex justify-between items-center gap-5">
        <div className="flex items-center gap-8">
          <div className="flex gap-2 items-center">
            <h5>Upcoming </h5>
            <div className="bg-[#169265] rounded-full text-troo-white w-7 h-7 font-semibold font-outfit flex items-center justify-center">
              1
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <h5 className="text-troo-secondery">Upcoming </h5>
            <div className="bg-troo-secondery rounded-full text-troo-white w-7 h-7 font-semibold font-outfit flex items-center justify-center">
              0
            </div>
          </div>
        </div>
        <div>
          <button className="bg-[#169265] text-troo-white flex items-center gap-2 font-outfit rounded px-4 py-2 ">
            <FiPlus className="w-5 h-5" /> Create Action
          </button>
        </div>
      </div>
      <div className="">
        <h4 className="mb-5">November 2023</h4>
        <div className="flex flex-col bg-troo-white rounded-lg w-[50%]">
          <form>
            <div className="">
              <input
                id="fileInput"
                type="file"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              <button
                className="bg-[#34495E] min-h-[300px] w-full text-troo-secondery px-5 py-2 rounded flex items-center justify-center"
                onClick={() => {
                  document.getElementById("fileInput").click();
                }}
              >
                <TbCameraPlus className="w-10 h-10" />
              </button>
            </div>
          </form>
          <div className="flex flex-col gap-2 p-5">
            <h3 className="text-[#F3425F]">Nov 20 9:00PM - Nov 20 10:00PM</h3>
            <h3>canvass</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionFinal;
