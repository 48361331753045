import React from "react";
import { BsLightning } from "react-icons/bs";
import { MdTour } from "react-icons/md";
import { PiFileTextFill } from "react-icons/pi";

const ActionDetails = () => {
  return (
    <div className="flex flex-col gap-5">
      <h1>Action details</h1>
      <form className="flex flex-col gap-10">
        <div className="mb-4">
          <label
            className="flex gap-2 text-base font-bold mb-3 text-[#4B4B4B]"
            for="username"
          >
            <BsLightning className="p-1 bg-troo-secondery text-troo-white w-6 h-6 rounded-full" />
            Action Name
          </label>
          <input
            className="appearance-none border-none bg-[#F6F7FB] rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none "
            id="username"
            type="text"
            placeholder="Action Name"
          />
        </div>
        <div className="mb-4">
          <label
            className="flex gap-2 text-base font-bold mb-3 text-[#4B4B4B]"
            for="username"
          >
            <PiFileTextFill className="  text-troo-secondery w-6 h-6 " />
            Description
          </label>
          <textarea
            id="textarea-label"
            className="py-3 px-4 block w-full  rounded-lg  border-none  bg-[#F6F7FB]"
            rows="8"
            placeholder="Provide more information about your action so guests know what to expect"
          ></textarea>
        </div>
      </form>
      <div className="flex gap-3 items-center text-[#4B4B4B] font-bold">
        <MdTour className="w-6 h-6" /> Select Associated Effort
      </div>
    </div>
  );
};

export default ActionDetails;
