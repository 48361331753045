import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import React from 'react'
import { FaChevronDown } from 'react-icons/fa6'

const Accordian = ({ id, title, children, isActive }) => {
  return (
    <Disclosure key={id}>
      {({ open }) => (
        <>
          <DisclosureButton className="w-full flex items-center justify-between px-5 py-2.5  bg-troo-primary/15">
            <p className="text-troo-primary font-bold uppercase">{title}</p>
            <FaChevronDown className={`text-base text-troo-primary ${open ? "rotate-180" : ""}`} />
          </DisclosureButton>
          <DisclosurePanel
            transition
            className="origin-top transition duration-200 ease-out w-full rounded-lg"
          >
            <div className='w-full text-gray-500'>
              {children}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  )
}

export default Accordian
