import React from "react";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { IoMailOpenOutline } from "react-icons/io5";

const Helpdesk = () => {
  return (
    <div className="p-4">
      <div className="bg-white flex flex-col items-start p-4 border-b border-troo-tersery">
        <h2>Helpdesk</h2>
        <p>Receive messages from general public.</p>
      </div>
      <div className="bg-white px-4 py-8 overflow-hidden w-full">
        <div className="rounded-xl overflow-hidden w-full flex flex-col items-start">
          <div className="font-semibold text-xl text-gray-700 w-full bg-gray-400 bg-opacity-10 p-5">
            General Instructions
          </div>
          <div className=" bg-white border border-gray-200 w-full flex">
            <div className="flex items-center gap-5 p-5 border-r">
              <input
                type="checkbox"
                className="shrink-0 mt-0.5 border-gray-400 w-5 h-5 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                id="hs-default-checkbox"
              />
              <div className="flex">
                <div className="relative min-w-[200px]">
                  <select
                    className="outline-none appearance-none w-full bg-transparent border-y-0 border-x-0  text-gray-700 py-2  font-outfit px-2 leading-tight font-semibold"
                    id="grid-state"
                  >
                    <option>All</option>
                    <option>Canvass1</option>
                    <option>Canvass2</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex items-center p-5">
              <input
                type="checkbox"
                id="hs-basic-with-description-unchecked"
                className="relative w-10 h-[23px] p-px bg-[#9E9E9E] border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-5 before:bg-[#F0F6F8] checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400 dark:checked:before:bg-blue-200"
              />
            </div>
          </div>
          <div className="w-full flex items-center text-lg gap-3 justify-between text-gray-700 p-5 bg-white border border-gray-200">
            <div className="flex items-center gap-5">
              <input
                type="checkbox"
                className="shrink-0 mt-0.5 border-gray-400 w-5 h-5 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                id="hs-default-checkbox"
              />
              <div className="flex flex-col items-start gap-2">
                <p className="font-semibold">Stacey Davis</p>
                <p>
                  Remember to close garden gates and be polite with all members
                  of the public. Also don't forget to leave a door hanger on
                  every door!
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center text-2xl h-full gap-2 justify-between">
              <BsFillExclamationTriangleFill className="text-[#FEED33]" />
              <IoMailOpenOutline className="#9E9E9E" />
            </div>
          </div>
          <div className="w-full flex items-center text-lg gap-3 justify-between text-gray-700 p-5 bg-white border border-gray-200">
            <div className="flex items-center gap-5">
              <input
                type="checkbox"
                className="shrink-0 mt-0.5 border-gray-400 w-5 h-5 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                id="hs-default-checkbox"
              />
              <div className="flex flex-col items-start gap-2">
                <p className="font-semibold">Melissa Morgan</p>
                <p>
                  Remember to close garden gates and be polite with all members
                  of the public. Also don't forget to leave a door hanger on
                  every door!
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center text-2xl h-full gap-2 justify-between">
              <BsFillExclamationTriangleFill className="text-[#FEED33]" />
              <IoMailOpenOutline className="#9E9E9E" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Helpdesk;
