import { useEffect, useState } from "react";
import CustomForm from "../Components/CustomForm";
import mailIcon from "../images/mailIcon.svg";
import axios from "axios";
import toast from "react-hot-toast";
import {
  createDatabase,
  fetchDataFromIndexedDB,
  storeDataInIndexedDB,
} from "../Hooks/IndexedDBUtils";
import { db4 } from "../Components/UserMenu";
import SurveyInputes from "../Components/SurveyInputes";
const { REACT_APP_BASE_URL, REACT_APP_HUBSPOT_PORTAL_ID } = process.env;

export const surveyForm = createDatabase("surveyformDB", "surveyformStore");

export default function MyPage() {
  const [formData, setFormData] = useState({
    email: "",
    firstname: "",
    lastname: "",
    how_long_have_you_lived_in_waterbury_: "",
    what_is_your_top_issue_that_the_city_needs_to_address_: "",
    are_you_a_parent_: "",
    would_you_like_to_set_up_a_1v1_conversation_: "",
    survey_notes: "",
    submitted_survey: "Yes"
  });
  const [canvasser_email, setCanvasserMail] = useState("");
  const [formList, setFormList] = useState([]);
  const [activeForm, setActiveForm] = useState(formList?.[0] || null);
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("urserMail");
  const [surveyData, setSurveyData] = useState([]);
  const isOnline = navigator.onLine;
  const [refreshBtn, setRefreshBtn] = useState(false)
  const surveyFieldDatabase = createDatabase(
    "surveyFieldDB",
    "surveyFieldStore"
  );

  const getSurveyFormList = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/list_survey_forms`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let formsAll = response?.data?.forms_all || [];
      localStorage.setItem('forms_all', JSON.stringify(formsAll));
    } catch (error) {
      toast.error("Error while fetching forms");
      console.log(error);
      localStorage.setItem('forms_all', JSON.stringify([]));
    }

    // Retrieve from local storage and set the form list and active form
    const storedFormsAll = JSON.parse(localStorage.getItem('forms_all') || '[]');
    setFormList(storedFormsAll);
    setActiveForm(storedFormsAll[0]);
  };

  const getCurrentUser = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/user`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCanvasserMail(response?.data?.email);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSurveyData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/get_survey_form`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const surveyField = response?.data?.fieldGroups;
      const surveyFieldWithId = surveyField.map((field, index) => ({
        id: index + 1,
        ...field,
      }));
      setSurveyData(surveyFieldWithId);
      if (surveyField.length > 0) {
        storeDataInIndexedDB(
          surveyFieldDatabase,
          "surveyFieldStore",
          surveyFieldWithId
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataFromIndexedDB(surveyFieldDatabase, "surveyFieldStore").then(
      (data) => {
        if (!navigator.onLine && Array.isArray(data)) {
          setSurveyData(data);
        }
      }
    );
    fetchDataFromIndexedDB(db4, "userProfileStore").then((data) => {
      if (Array.isArray(data) && data.length > 0) {
        setCanvasserMail(data[0]?.email);
      } else if (typeof data === "object" && Object.keys(data).length > 0) {
        setCanvasserMail(data?.email);
      }
    });
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      getCurrentUser();
      fetchSurveyData();
    }
  }, []);
  useEffect(() => {
    if (navigator.onLine) {
      getSurveyFormList();
    } else {
      const storedFormsAll = JSON.parse(localStorage.getItem('forms_all') || '[]');
      setFormList(storedFormsAll)
      setActiveForm(storedFormsAll?.[0])
    }
  }, []);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        send_mail_to: formData.email,
        url_to_send: `http://${window.location.host}/voter-servey/?CanvasserEmail=${canvasser_email}&formid=${formData?.formid}`,
      };
      await axios.post(`${REACT_APP_BASE_URL}/surveyMail`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Email sent successfully!");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleRefresh = (reloadScript) => {
    if (navigator.onLine) {
      reloadScript(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const id = Date.now();
      const mappedData = {};
      surveyData.forEach((group) => {
        group.fields.forEach((field) => {
          if (
            field.name !== "Submitted Survey" &&
            field.name !== "Canvasser Email"
          ) {
            mappedData[field.name] = formData[field.name];
          }
        });
      });
      mappedData["id"] = id;
      mappedData["canvasser_email"] = canvasser_email;

      await storeDataInIndexedDB(surveyForm, "surveyformStore", mappedData);
      setFormData({
        email: "",
        firstname: "",
        lastname: "",
        how_long_have_you_lived_in_waterbury_: "",
        what_is_your_top_issue_that_the_city_needs_to_address_: "",
        are_you_a_parent_: "",
        would_you_like_to_set_up_a_1v1_conversation_: "",
        survey_notes: "",
        submitted_survey: "Yes",
      });
      toast.success("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };


  return (
    <div className="bg-troo-userbar h-full p-10">
      <div className="flex justify-between gap-8">
        <h3 className="text-troo-black text-opacity-75">Submit Survey </h3>
        <div className="flex gap-5 items-center">
          <button
            className="flex items-center bg-troo-white gap-2 p-2 rounded font-semibold"
            type="button"
            data-hs-overlay="#hs-basic-modal6"
          >
            <img className="w-5 h-5" src={mailIcon} alt="mailIcon" />
            <h4 className="uppercase">Send Email</h4>
          </button>
        </div>
      </div>
      <div className="flex items-center gap-2 border w-full border-troo-primary my-6 overflow-x-auto p-1">
        {
          formList?.map((data, index) => {
            return (
              <div className={`px-5 py-3 text-sm whitespace-nowrap font-bold hover:bg-troo-primary hover:text-troo-white transition-all cursor-pointer ${data?.id === activeForm?.id ? "bg-troo-primary text-troo-white" : "text-troo-primary"}`} onClick={() => {
                setRefreshBtn(false)
                setActiveForm(data)
              }}>
                {data?.form_tab}
              </div>
            )
          })
        }
      </div>
      {activeForm?.form && (
        <CustomForm
          handleRefresh={handleRefresh}
          key={activeForm?.id}
          refreshBtn={refreshBtn}
          setRefreshBtn={setRefreshBtn}
          region="na1"
          portalId={REACT_APP_HUBSPOT_PORTAL_ID}
          formId={activeForm?.form}
        />
      )
      }

      <div
        id="hs-basic-modal6"
        className="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
            <div className="p-4 overflow-y-auto">
              <form className="w-full " onSubmit={handleEmailSubmit}>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4"
                      for="inline-full-name"
                    >
                      Enter Email
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="inline-full-name"
                      type="email"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4"
                      htmlFor="dropdown"
                    >
                      Select Survey
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="dropdown"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, formid: e.target.value })
                      }
                    >
                      <option value="">Select...</option>
                      {
                        formList?.map((data, index) => {
                          return (
                            <option value={data?.form}>{data?.form_tab}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>

                <div className="flex justify-end items-center gap-x-2">
                  <button
                    type="submit"
                    data-hs-overlay="#hs-basic-modal6"
                    className="py-3 px-6 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-troo-primary text-troo-white "
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
