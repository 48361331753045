import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { ErrorMessage, Formik, FieldArray } from "formik";
import Select from "react-select";
import {
  createDatabase,
  storeDataInIndexedDB,
  fetchDataFromIndexedDB,
  updateDataInIndexedDB,
} from "../Hooks/IndexedDBUtils";
import { db4 } from "../Components/UserMenu";

const { REACT_APP_BASE_URL } = process.env;

export const syncableProfile = createDatabase(
  "syncableProfileDB",
  "syncableProfileStore"
);

const ProfileSetting = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [allCityOptions, setAllCityOptions] = useState([]);
  const cityIDB = createDatabase("CityDB", "AllCityStore");
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("urserMail");
  const isSuperAdmin = currentUser?.roles === "ROLE_SUPER_ADMIN"

  const getCityOptions = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/getCity`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const options = response.data.cities.map((city, i) => ({
          id: i + 1,
          value: city.name,
          label: city.name,
        }));
        if (options) {
          await storeDataInIndexedDB(cityIDB, "AllCityStore", options);
        }
        setAllCityOptions(options);
      }
    } catch (error) {
      console.error("Error fetching city data:", error);
    }
  };

  const getCurrentUser = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/user`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userData = response.data;
      setCurrentUser(userData);
      if (userData) {
        await storeDataInIndexedDB(db4, "userProfileStore", userData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values, event) => {
    try {
      const data = {
        id: currentUser?.id,
        username: values?.username || "",
        firstname: values?.firstname || "",
        lastname: values?.lastname || "",
        email: values?.email || "",
        city: values?.city || null,
        phone: values?.phone || "",
        lock_code: values?.lock_code || "",
        meet_embedded: values?.meet_embedded || "",
        forms: isSuperAdmin ? values?.forms.map(({ form, form_tab, id }) => ({ form, form_tab, id: id || null })) || [] : null,
      };

      if (navigator.onLine == false) {
        const updatedIndexData = {
          id: data?.id,
          ...data,
        };
        if (updatedIndexData) {
          toast.success("data updated offline in indexDB ");
        }
        storeDataInIndexedDB(
          syncableProfile,
          "syncableProfileStore",
          updatedIndexData
        );
        updateDataInIndexedDB(
          db4,
          "userProfileStore",
          data?.id,
          updatedIndexData
        );
      } else {
        axios
          .put(`${REACT_APP_BASE_URL}/updateprofile`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data) {
              toast.success(res?.data?.message);
              localStorage.setItem(
                "cityname",
                res?.data?.updated_user?.city || ""
              );
            }
          });
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      console.log("update successfully");
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      getCurrentUser();
      getCityOptions();
    } else {
      fetchDataFromIndexedDB(db4, "userProfileStore").then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setCurrentUser(data[0]);
        } else if (typeof data === "object" && Object.keys(data).length > 0) {
          setCurrentUser(data);
        }
      });
      fetchDataFromIndexedDB(cityIDB, "AllCityStore").then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setAllCityOptions(data);
        }
      });
    }
  }, []);

  return (
    <div className="bg-troo-userbar min-h-screen w-full px-5 md:px-12 py-8">
      <div className="bg-troo-white rounded">
        <div className="flex flex-col p-3 md:p-8 gap-3 rounded border-b-2">
          <h2>Profile Details</h2>
          <h5 className="text-troo-directoryText">
            Manage your personal Canvassing profile information
          </h5>
        </div>
        <Formik
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
          initialValues={{
            username: currentUser?.username || "",
            firstname: currentUser?.firstname || "",
            lastname: currentUser?.lastname || "",
            city: currentUser?.city || "",
            email: currentUser?.email || "",
            phone: currentUser?.phone || "",
            lock_code: currentUser?.lock_code || "",
            meet_embedded: currentUser?.meet_embedded || "",
            forms: isSuperAdmin && currentUser?.forms?.length > 0
              ? [...currentUser.forms, ...Array(10 - currentUser.forms.length).fill({ form: "", form_tab: "" })]
              : Array(10).fill({ form: "", form_tab: "" }),
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setValues,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <tbody className="">
                          <tr className=" border-y">
                            <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                              Photo
                            </td>
                            <td className="md:px-6 px-3 py-2 whitespace-nowrap text-sm font-outfit font-normal text-start">
                              <h4 className="md:w-10 w-7 md:h-10 h-7 bg-troo-primary text-troo-white rounded-full flex items-center justify-center">
                                {currentUser &&
                                currentUser.firstname &&
                                currentUser.lastname
                                  ? `${currentUser.firstname
                                      .charAt(0)
                                      .toUpperCase()}${currentUser.lastname
                                      .charAt(0)
                                      .toUpperCase()}`
                                  : null}
                              </h4>
                            </td>
                          </tr>
                          <tr className=" border-y-2">
                            <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                              User Name
                            </td>
                            <td className="md:px-6 px-3 py-4 whitespace-nowrap text-sm font-semibold font-outfit text-start">
                              <input
                                type="text"
                                name="username"
                                disabled
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.username && !errors.username}
                                isInvalid={
                                  touched.username && !!errors.username
                                }
                                className="border-none w-full placeholder:text-troo-black placeholder:text-sm placeholder:font-outfit p-0"
                              />
                            </td>
                            <td>
                              <ErrorMessage
                                name="username"
                                component="span"
                                className="text-red-500 text-xs"
                              />
                            </td>
                          </tr>
                          <tr className=" border-y-2">
                            <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                              First Name
                            </td>
                            <td className="md:px-6 px-3 py-4 whitespace-nowrap text-sm font-semibold font-outfit text-start">
                              <input
                                type="text"
                                name="firstname"
                                value={values.firstname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.firstname && !errors.firstname}
                                isInvalid={
                                  touched.firstname && !!errors.firstname
                                }
                                className="border-none w-full placeholder:text-troo-black placeholder:text-sm placeholder:font-outfit p-0"
                              />
                            </td>
                            <td>
                              <ErrorMessage
                                name="firstname"
                                component="span"
                                className="text-red-500 text-xs"
                              />
                            </td>
                          </tr>
                          <tr className=" border-y-2">
                            <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                              Last Name
                            </td>
                            <td className="md:px-6 px-3 py-4 whitespace-nowrap text-sm font-semibold font-outfit text-start">
                              <input
                                type="text"
                                name="lastname"
                                value={values.lastname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.lastname && !errors.lastname}
                                isInvalid={
                                  touched.lastname && !!errors.lastname
                                }
                                className="border-none w-full placeholder:text-troo-black placeholder:text-sm placeholder:font-outfit p-0"
                              />
                            </td>
                            <td>
                              <ErrorMessage
                                name="lastname"
                                component="span"
                                className="text-red-500 text-xs"
                              />
                            </td>
                          </tr>
                          <tr className=" border-y-2">
                            <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                              Email
                            </td>
                            <td className="md:px-6 px-3 py-4 whitespace-nowrap text-sm font-semibold font-outfit text-start">
                              <input
                                type="email"
                                name="email"
                                disabled
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.email && !errors.email}
                                isInvalid={touched.email && !!errors.email}
                                className="border-none w-full placeholder:text-troo-black placeholder:text-sm placeholder:font-outfit p-0"
                              />
                            </td>
                            <td>
                              <ErrorMessage
                                name="email"
                                component="span"
                                className="text-red-500 text-xs"
                              />
                            </td>
                          </tr>
                          {currentUser.roles === "ROLE_REGIONAL_MANAGER" && (
                            <tr className=" border-y-2">
                              <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                                City
                              </td>
                              <td className="md:px-6 px-3 py-4 whitespace-nowrap text-sm font-semibold font-outfit text-start">
                                <Select
                                  id="city"
                                  name="city"
                                  className="w-1/3"
                                  options={allCityOptions}
                                  value={
                                    values.city
                                      ? allCityOptions.find(
                                          (option) =>
                                            option.value === values.city
                                        )
                                      : null
                                  }
                                  onChange={(selectedOption) =>
                                    setValues({
                                      ...values,
                                      city: selectedOption
                                        ? selectedOption.value
                                        : null,
                                    })
                                  }
                                  onBlur={handleBlur}
                                  isClearable
                                />
                              </td>
                              <td>
                                <ErrorMessage
                                  name="city"
                                  component="span"
                                  className="text-red-500 text-xs"
                                />
                              </td>
                            </tr>
                          )}
                          <tr className=" border-y-2">
                            <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                              Phone
                            </td>
                            <td className="md:px-6 px-3 py-4 whitespace-nowrap text-sm font-semibold font-outfit text-start">
                              <input
                                type="number"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.phone && !errors.phone}
                                isInvalid={touched.phone && !!errors.phone}
                                className="border-none w-full placeholder:text-troo-black placeholder:text-sm placeholder:font-outfit p-0"
                              />
                            </td>
                            <td>
                              <ErrorMessage
                                name="phone"
                                component="span"
                                className="text-red-500 text-xs"
                              />
                            </td>
                          </tr>
                          <tr className=" border-y-2">
                            <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                              Meeting Code
                            </td>
                            <td className="md:px-6 px-3 py-4 whitespace-nowrap text-sm font-semibold font-outfit text-start">
                              <input
                                type="text"
                                name="meet_embedded"
                                value={values.meet_embedded}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={
                                  touched.meet_embedded && !errors.meet_embedded
                                }
                                isInvalid={
                                  touched.meet_embedded &&
                                  !!errors.meet_embedded
                                }
                                className="border-none w-full placeholder:text-troo-black placeholder:text-sm placeholder:font-outfit p-0"
                              />
                            </td>
                          </tr>
                          <tr className=" border-y-2">
                            <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                              Lock password
                            </td>
                            <td className="md:px-6 px-3 whitespace-nowrap text-sm font-semibold font-outfit text-start">
                              <input
                                type="text"
                                className="border-2 border-gray-200  placeholder:text-troo-black placeholder:text-sm placeholder:font-outfit py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-200"
                                name="lock_code"
                                value={values.lock_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                          {/* New field array for "forms" */}
                          {/* <FieldArray name="forms">
                            {({ remove, push }) => (
                              <>
                                {values.forms.map((form, index) => (
                                  <React.Fragment key={index}>
                                    <tr className="border-y-2">
                                      <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                                        Form ID
                                      </td>
                                      <td className="md:px-6 px-3 py-4 whitespace-nowrap text-sm font-semibold font-outfit text-start">
                                        <input
                                          type="text"
                                          name={`forms.${index}.form_id`}
                                          value={form.form_id}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className="border-none w-full placeholder:text-troo-black placeholder:text-sm placeholder:font-outfit p-0"
                                        />
                                      </td>
                                      <td>
                                        <ErrorMessage
                                          name={`forms.${index}.form_id`}
                                          component="span"
                                          className="text-red-500 text-xs"
                                        />
                                      </td>
                                    </tr>
                                    <tr className="border-y-2">
                                      <td className="md:px-8 px-3 py-4 whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                                        Tab Name
                                      </td>
                                      <td className="md:px-6 px-3 py-4 whitespace-nowrap text-sm font-semibold font-outfit text-start">
                                        <input
                                          type="text"
                                          name={`forms.${index}.tab_name`}
                                          value={form.tab_name}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className="border-none w-full placeholder:text-troo-black placeholder:text-sm placeholder:font-outfit p-0"
                                        />
                                      </td>
                                      <td>
                                        <ErrorMessage
                                          name={`forms.${index}.tab_name`}
                                          component="span"
                                          className="text-red-500 text-xs"
                                        />
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          onClick={() => remove(index)}
                                        >
                                          Remove
                                        </button>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                                <tr>
                                  <td colSpan="3">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        push({ form_id: "", tab_name: "" })
                                      }
                                    >
                                      Add Form
                                    </button>
                                  </td>
                                </tr>
                              </>
                            )}
                          </FieldArray> */}
                      {
                        isSuperAdmin && (
                          <div className="md:px-8 px-3 py-4 flex flex-col items-start gap-4 w-full">
                            <div className="whitespace-nowrap flex items-center gap-3 text-base font-outfit font-bold text-[#707173]">
                              Survey Forms
                            </div>
                            {/* New field array for "forms" */}
                            <FieldArray name="forms">
                              {({ remove, push }) => (
                                <>
                              {values?.forms?.map((form, index) => {
                                return (
                                    <div className="whitespace-nowrap text-sm font-semibold font-outfit text-start grid grid-cols-2 gap-2 w-full">
                                      <input
                                        type="text"
                                        name={`forms.${index}.form_tab`}
                                        value={form.form_tab}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Tab Title"
                                        className="border-2 border-gray-200  placeholder:text-troo-black/30 placeholder:text-sm placeholder:font-outfit py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-200"
                                      />
                                      <input
                                        type="text"
                                        name={`forms.${index}.form`}
                                        value={form.form}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Hubspot Form ID"
                                        className="border-2 border-gray-200  placeholder:text-troo-black/30 placeholder:text-sm placeholder:font-outfit py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-200"
                                      />
                                    </div>
                                  )})}
                                </>)}
                              </FieldArray>
                          </div>
                        )
                      }
                        <div className="md:px-8 px-3 py-4">
                          <button
                            type="submit"
                            className="px-3 py-2 bg-troo-primary text-troo-white rounded"
                          >
                            Save Changes
                          </button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProfileSetting;
