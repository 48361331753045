import React from "react";
import logo from "../images/logoDocumentation.svg";
import { HiOutlineSearch } from "react-icons/hi";
import { DocumentsData } from "../Data/DocumentsData";

const DocumentationPage = () => {
  return (
    <div>
      <div className=" bg-troo-primary py-12">
        <div className="mainContainer">
          <div className="flex flex-col gap-6">
            <div className="flex justify-center mb-10">
              <img src={logo} alt="logo" />
            </div>
            <h2 className="text-troo-white">
              Advice and answers from the Canvassing Team
            </h2>
            <form className="relative">
              <input
                type="search"
                placeholder="Search for articles..."
                className="bg-troo-white bg-opacity-20 placeholder:text-troo-white placeholder:text-lg placeholder:font-outfit font-outfit pl-12 py-5 w-[70%] rounded-xl text-troo-white"
              />
              <HiOutlineSearch className="absolute -translate-y-1/2 top-1/2 text-troo-white left-4 w-6 h-6 " />
            </form>
          </div>
        </div>
      </div>
      <div className="bg-troo-white py-12">
        <div className="mainContainer">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {DocumentsData.map((ele) => (
              <div className="rounded-lg border">
                <div className="bg-[#F5F5F5] flex justify-center py-8 ">
                  <img src={ele.img} alt={ele.name} />
                </div>
                <div className="py-5 flex flex-col items-center gap-2 px-8">
                  <h5 className="font-bold">{ele.name}</h5>
                  <p className="min-h-[40px] text-center">{ele.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentationPage;
