import React, { useEffect, useState } from "react";
import { SyncLoader } from "react-spinners";
import { MdModeEdit } from "react-icons/md";
import EditInputField from "./EditInputField";
import toast from "react-hot-toast";
import axios from "axios";
import { IoArrowForwardSharp } from "react-icons/io5";
import Select from "react-select";

const { REACT_APP_BASE_URL } = process.env;

const UserTableDirectory = ({
  loading,
  userData,
  searchName,
  searchEmail,
  fetchUserList,
  inviteCity,
  setInviteCity,
  allCityOptions,
}) => {
  const userRole = localStorage.getItem("userRole");
  const [filteredData, setFilteredData] = useState(userData);
  const [userId, setUserId] = useState("");
  const [userPermission, setUserPermission] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [editUserId, setEditUserId] = useState("");
  const [editCity, setEditCity] = useState("");

  const applyFilters = () => {
    let filtered = userData.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      const searchValue = searchName.toLowerCase();
      return fullName.includes(searchValue);
    });

    if (searchEmail) {
      filtered = filtered.filter(
        (user) =>
          user.email &&
          user.email.toLowerCase().includes(searchEmail.toLowerCase())
      );
    }
    setFilteredData(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [userData, searchName, searchEmail]);

  const token = localStorage.getItem("token");

  const handleEditClick = (user) => {
    setUserId(user?.id);
    setUserPermission(user?.userPermission);
    setUserEmail(user?.userEmail);
  };

  const handleCityEditClick = (user) => {
    setEditUserId(user.id);
    setEditCity(user.city);
  };

  const handleCitySubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(
        `${REACT_APP_BASE_URL}/updateRole`,
        {
          role: userPermission,
          email: userEmail,
          userId: editUserId,
          city: editCity,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res?.data) {
        fetchUserList();
        toast.success("updated user successfully");
      }
    } catch (error) {
      console.error("Error inviting user:", error);
      toast.error("Error inviting user");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(
        `${REACT_APP_BASE_URL}/updateRole`,
        {
          role: userPermission,
          email: userEmail,
          userId: userId,
          city: inviteCity,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res?.data) {
        fetchUserList();
        toast.success("updated user successfully");
      }
    } catch (error) {
      console.error("Error inviting user:", error);
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <table className="test min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="md:px-5 px-3 py-3 text-start text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="md:px-6 px-3  py-3 text-start text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="md:px-6 px-3  py-3 text-start text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      City
                    </th>

                    <th
                      scope="col"
                      className="md:px-6 px-3  py-3 text-start text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      Permission
                    </th>

                    {/* <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      Teams
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {loading ? (
                    <div className="text-center flex justify-center items-center h-[50px]">
                      <SyncLoader color="#43524f" size={8} />
                    </div>
                  ) : (
                    <>
                      {filteredData?.map((e, i) => (
                        <tr className="hover:bg-gray-100" key={i}>
                          <td className="md:px-5 px-3 md:py-4 py-2 whitespace-nowrap flex items-start gap-3">
                            <div className="bg-troo-primary text-troo-white text-xs rounded-full w-7 h-7 p-1 flex justify-center items-center">
                              {e?.firstName?.charAt(0)}
                              {e?.lastName?.charAt(0)}
                            </div>
                            <p>
                              {e?.firstName}&nbsp;
                              {e?.lastName}
                            </p>
                          </td>
                          <td className="md:px-6 px-3  md:py-4 py-2 whitespace-nowrap font-outfit text-sm font-normal text-start">
                            {e?.email}
                          </td>
                          <td className="md:px-6 px-3  md:py-4 py-2 whitespace-nowrap font-outfit text-sm font-normal text-start ">
                            <div className="flex gap-3 items-center">
                              {e?.city}
                              {e?.city ? (
                                <button
                                  type="button"
                                  onClick={() => handleCityEditClick(e)}
                                  data-hs-overlay="#hs-basic-modal10"
                                >
                                  <MdModeEdit className="w-6 h-6 text-troo-secondary" />
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>

                          <td className="md:px-6 px-3  md:py-4 py-2 whitespace-nowrap text-sm font-outfit font-normal text-start flex gap-5 items-center">
                            {e?.role
                              ? e.role.replace(/_/g, " ")
                              : e?.superAdmin == true
                              ? "ROLE SUPER ADMIN"
                              : ""}
                            {userRole === "ROLE_ACCOUNT_MANAGER" ||
                            userRole == "ROLE_SUPER_ADMIN" ? (
                              <button
                                type="button"
                                onClick={() => handleEditClick(e)}
                                data-hs-overlay="#hs-basic-modal9"
                              >
                                <MdModeEdit className="w-6 h-6 text-troo-secondary" />
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs-basic-modal9"
        className="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-5xl sm:w-full m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
            <div className="p-4">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-10 py-12">
                  {/* <div className="flex items-center gap-3">
                    <input
                      name="userPermission"
                     type="radio"
                      value="ROLE_ACCOUNT_MANAGER"
                      required
                      className="form-radio h-5 w-5 text-troo-secondery border-2 rounded"
                      checked={userPermission === "ROLE_ACCOUNT_MANAGER"}
                      onChange={(e) => setUserPermission(e.target.value)}
                    />
                    <div className="flex flex-col">
                      <h4 className="font-outfit font-bold">Account Manager</h4>
                      <h4 className="font-medium text-troo-secondery">
                        Access to all features within the dashboard
                        administration. Note: Account Owner may grant access to
                        the administration to any Account Manager.
                      </h4>
                    </div>
                  </div> */}
                  <div className="flex items-center gap-3">
                    <input
                      name="userPermission"
                      type="radio"
                      required
                      value="ROLE_REGIONAL_MANAGER"
                      className="form-radio h-5 w-5 text-troo-secondery border-2 rounded"
                      checked={userPermission === "ROLE_REGIONAL_MANAGER"}
                      onChange={(e) => setUserPermission(e.target.value)}
                    />
                    <div className="flex flex-col">
                      <h4 className="font-outfit font-bold">Reginal Manager</h4>
                      <h4 className="font-medium text-troo-secondery">
                        Access to most features within the dashboard. This
                        permission level is restricted from creating lists, and
                        does not have access to the Analytics page.
                      </h4>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <input
                      name="userPermission"
                      type="radio"
                      required
                      value="ROLE_CANVASSER"
                      className="form-radio h-5 w-5 text-troo-secondery border-2 rounded"
                      checked={userPermission === "ROLE_CANVASSER"}
                      onChange={(e) => setUserPermission(e.target.value)}
                    />
                    <div className="flex flex-col">
                      <h4 className="font-outfit font-bold">Canvasser</h4>
                      <h4 className="font-medium text-troo-secondery">
                        Access to most features within the dashboard. This
                        permission level is restricted from creating lists, and
                        does not have access to the Analytics page.
                      </h4>
                    </div>
                  </div>
                  {userPermission === "ROLE_REGIONAL_MANAGER" && (
                    <div className="md:flex md:items-center mb-6">
                      <div className="">
                        <label
                          className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4 font-outfit"
                          htmlFor="inline-full-name"
                        >
                          Enter City *
                        </label>
                      </div>
                      <div className="w-1/2">
                        <Select
                          id="city"
                          name="city"
                          className="w-1/2"
                          options={allCityOptions}
                          value={allCityOptions.find(
                            (option) => option.value === inviteCity
                          )}
                          onChange={(selectedOption) =>
                            setInviteCity(
                              selectedOption ? selectedOption.value : null
                            )
                          }
                          isClearable
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-center mt-6">
                  <button
                    type="submit"
                    className="bg-troo-permissionButton rounded flex justify-center items-center px-8 font-outfit font-semibold text-troo-white gap-2 uppercase py-3"
                    data-hs-overlay="#hs-basic-modal9"
                  >
                    Submit <IoArrowForwardSharp />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs-basic-modal10"
        className="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
            <div className="p-4 ">
              <form onSubmit={handleCitySubmit}>
                <div className="flex flex-col gap-10 py-8">
                  <div className="md:flex md:items-center w-full">
                    <div className="w-1/3">
                      <label
                        className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4 font-outfit"
                        htmlFor="inline-full-name"
                      >
                        Enter City *
                      </label>
                    </div>
                    <div className="w-full">
                      {/* <select
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        id="assigned-email-select"
                        value={editCity}
                        onChange={(e) => setEditCity(e.target.value)}
                      >
                        <option value="">{editCity}</option>
                        {allCityOptions.map((data, i) => (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select> */}
                      <Select
                        id="city"
                        name="city"
                        options={allCityOptions}
                        value={allCityOptions.find(
                          (option) => option.value === editCity
                        )}
                        onChange={(selectedOption) =>
                          setEditCity(
                            selectedOption ? selectedOption.value : null
                          )
                        }
                        isClearable
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="bg-troo-permissionButton rounded flex justify-center items-center px-8 font-outfit font-semibold text-troo-white gap-2 uppercase py-3"
                    data-hs-overlay="#hs-basic-modal10"
                  >
                    Submit <IoArrowForwardSharp />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTableDirectory;
