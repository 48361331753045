import React, { useState } from "react"; 

const Input = ({
  icon,
  lebel,
  type,
  BsEyeFill,
  value,
  onChange,
  onBlur,
  name,
  BsEyeSlashFill
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="flex items-end gap-2">
      <img src={icon} alt="icon" />
      <div className="w-[100%]">
        <label className="text-xs font-outfit font-normal leading-[10.63px]">
          {lebel}
        </label>
        <input
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          type={showPassword ? "text" : type}
          className="outline-none w-full border-b border-x-0 p-0 focus:ring-troo-white border-t-0 border-troo-black font-outfit"
        />
      </div>
      <span className="cursor-pointer" onClick={togglePasswordVisibility}>{showPassword ? BsEyeFill : BsEyeSlashFill }</span>
    </div>
  );
};

export default Input;
