import React, { useEffect, useState } from "react";
import { BiQuestionMark } from "react-icons/bi";
import { HiMiniUserCircle } from "react-icons/hi2";
import { MdMail, MdMap } from "react-icons/md";
import { PiUsersThreeBold } from "react-icons/pi";
import filterIcon from "../images/filterIcon.png";
import UserTableDirectory from "./UserTableDirectory";
import { IoArrowForwardSharp } from "react-icons/io5";
import axios from "axios";
import toast from "react-hot-toast";
import {
  createDatabase,
  storeDataInIndexedDB,
  fetchDataFromIndexedDB,
} from "../Hooks/IndexedDBUtils";
const { REACT_APP_BASE_URL } = process.env;

const Directory = () => {
  const [activeTab, setActiveTab] = useState("users");
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [userPermission, setUserPermission] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteCity, setInviteCity] = useState(null);
  const [inviteFname, setInviteFname] = useState("");
  const [inviteLname, setInviteLname] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const db = createDatabase("UserlistDB", "userlistStore");
  const [allCityOptions, setAllCityOptions] = useState([]);

  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRole");

  const fetchUserList = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/userlist`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newData = response.data.results;
      setUserData(newData);
      setLoading(false);
      if (newData.length > 0) {
        storeDataInIndexedDB(db, "userlistStore", newData);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchDataFromIndexedDB(db, "userlistStore").then((data) => {
      if (navigator.onLine === false) {
        setUserData(data);
        setLoading(false);
      }
    });
  }, []);

  const getCityOptions = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/getCity`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const options = response.data.cities.map((city) => ({
          value: city.name,
          label: city.name,
        }));
        setAllCityOptions(options);
      }
    } catch (error) {
      console.error("Error fetching city data:", error);
    }
  };

  useEffect(() => {
   if(navigator.onLine){
    fetchUserList();
    getCityOptions();
   }
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const ShowPermission = (e) => {
    e.preventDefault();
    toast.error("You are Not Access for invite user");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${REACT_APP_BASE_URL}/inviteUser`,
        {
          role: userPermission,
          email: inviteEmail,
          firstname: inviteFname,
          lastname: inviteLname,
          city: inviteCity,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData([res.data, ...userData]);
      toast.success("Invite user successfully");
      setUserPermission("");
      setInviteEmail("");
      setInviteFname("");
      setInviteLname("");
    } catch (error) {
      console.error("Error inviting user:", error);
      toast.error("Error inviting user");
    }
  };

  const handleNameSearch = (name) => {
    setSearchName(name);
  };

  const handleEmailSearch = (email) => {
    setSearchEmail(email);
  };

  const handleReset = () => {
    setSearchName("");
    setSearchEmail("");
    fetchUserList();
  };
  return (
    <div className="bg-troo-white">
      <div className="flex flex-col gap-2 p-5">
        <div className="flex items-center gap-36">
          <h1>Directory</h1>
        </div>
        <h4 className="text-troo-secondery">
          Manage your users, licenses and teams, all in one place
        </h4>
      </div>
      <div className="border-y border-troo-black border-opacity-10 flex justify-between flex-wrap items-center">
        <div className="flex items-center gap-10 pl-5 ">
          <div
            className={`flex gap-2 items-center text-troo-directoryText py-2 cursor-pointer ${
              activeTab === "users" && "border-b-2 border-troo-primary"
            }`}
            onClick={() => handleTabClick("users")}
          >
            <HiMiniUserCircle className="w-5 h-5 font-semibold" />
            <h5>Users</h5>
          </div>
          {/* <div
            className={`flex gap-2 items-center text-troo-directoryText py-2 cursor-pointer ${
              activeTab === "invite" && "border-b-2 border-troo-primary"
            }`}
            onClick={() => handleTabClick("invite")}
          >
            <MdMail className="w-5 h-5 font-semibold" />
            <h5>Invites</h5>
          </div> */}
          {/* <div
            className={`flex gap-2 items-center text-troo-directoryText py-2 cursor-pointer ${
              activeTab === "teams" && "border-b-2 border-troo-primary"
            }`}
            onClick={() => handleTabClick("teams")}
          >
            <PiUsersThreeBold className="w-5 h-5 font-semibold" />
            <h5>Teams</h5>
          </div> */}
          {/* <div
            className={`flex gap-2 items-center text-troo-directoryText py-2 cursor-pointer ${
              activeTab === "map" && "border-b-2 border-troo-primary"
            }`}
            onClick={() => handleTabClick("map")}
          >
            <MdMap className="w-5 h-5 font-semibold" />
            <h5>Map</h5>
          </div> */}
        </div>
        <div className="flex items-center gap-8 md:pr-8">
          {/* <p>Showing </p> */}
          {userRole === "ROLE_ACCOUNT_MANAGER" ||
          userRole == "ROLE_SUPER_ADMIN" ? (
            <button
              data-hs-overlay="#hs-large-modal"
              type="button"
              className="font-semibold text-troo-primary cursor-pointer text-base font-outfit uppercase"
            >
              + Invite User
            </button>
          ) : (
            <button
              onClick={(e) => ShowPermission(e)}
              className="font-semibold text-troo-primary cursor-pointer text-base font-outfit uppercase"
            >
              + Invite User
            </button>
          )}

          <div className="flex gap-2">
            <div className="w-8 h-8 rounded-full bg-troo-secondery bg-opacity-20 flex items-center justify-center">
              <button type="button" data-hs-overlay="#hs-basic-modal7">
                <img src={filterIcon} alt="filterIcon" />
              </button>
            </div>
            <button
              className="px-2 bg-troo-secondery bg-opacity-30 rounded"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      {activeTab === "users" && (
        <UserTableDirectory
          inviteCity={inviteCity}
          setInviteCity={setInviteCity}
          allCityOptions={allCityOptions}
          userRole={userRole}
          searchName={searchName}
          searchEmail={searchEmail}
          userData={userData}
          loading={loading}
          fetchUserList={fetchUserList}
        />
      )}
      {/* {activeTab === "invite" && <UserTableDirectory />} */}
      {/* {activeTab === "teams" && <TeamListDirectory />} */}
      {/* {activeTab === "map" && <UserTableDirectory />} */}

      <div
        id="hs-large-modal"
        className="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all lg:max-w-5xl lg:w-full m-3 lg:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto w-full">
            <div className="flex justify-center items-center py-6 px-4 border-b dark:border-gray-700">
              <h2 className="font-bold text-center">Invite</h2>
            </div>
            <div className="py-6 md:px-24 px-8 overflow-y-auto">
              <h3 className="py-5 text-center">
                Select the specific permission level for the user(s)
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col md:gap-10 gap-5 md:py-12 py-5">
                  <div className="flex items-center gap-3">
                    <input
                      name="userPermission"
                      type="radio"
                      required
                      value="ROLE_REGIONAL_MANAGER"
                      className="form-radio h-5 w-5 text-troo-secondery border-2 rounded"
                      checked={userPermission === "ROLE_REGIONAL_MANAGER"}
                      onChange={(e) => setUserPermission(e.target.value)}
                    />
                    <div className="flex flex-col">
                      <h4 className="font-outfit font-bold">
                        Regional Manager
                      </h4>
                      <h4 className="font-medium text-troo-secondery">
                        Access to most features within the dashboard. This
                        permission level is restricted from creating lists, and
                        does not have access to the Analytics page.
                      </h4>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <input
                      name="userPermission"
                      type="radio"
                      required
                      value="ROLE_CANVASSER"
                      className="form-radio h-5 w-5 text-troo-secondery border-2 rounded"
                      checked={userPermission === "ROLE_CANVASSER"}
                      onChange={(e) => setUserPermission(e.target.value)}
                    />
                    <div className="flex flex-col">
                      <h4 className="font-outfit font-bold">Canvasser</h4>
                      <h4 className="font-medium text-troo-secondery">
                        Access to most features within the dashboard. This
                        permission level is restricted from creating lists, and
                        does not have access to the Analytics page.
                      </h4>
                    </div>
                  </div>

                  <div className="md:flex md:items-center md:mb-6 mb-2">
                    <div className="">
                      <label
                        className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4 font-outfit"
                        htmlFor="inline-full-name"
                      >
                        * First Name
                      </label>
                    </div>
                    <div>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        id="inline-full-name"
                        type="test"
                        placeholder="First Name"
                        onChange={(e) => setInviteFname(e.target.value)}
                        value={inviteFname}
                        required
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:mb-6 mb-2">
                    <div className="">
                      <label
                        className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4 font-outfit"
                        htmlFor="inline-full-name"
                      >
                        * Last Name
                      </label>
                    </div>
                    <div>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        id="inline-full-name"
                        type="test"
                        placeholder="Last Name"
                        onChange={(e) => setInviteLname(e.target.value)}
                        value={inviteLname}
                        required
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:mb-6 mb-2">
                    <div className="">
                      <label
                        className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4 font-outfit"
                        htmlFor="inline-full-name"
                      >
                        * Enter Email
                      </label>
                    </div>
                    <div>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        id="inline-full-name"
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setInviteEmail(e.target.value)}
                        value={inviteEmail}
                        required
                      />
                    </div>
                  </div>
                  {userPermission === "ROLE_REGIONAL_MANAGER" && (
                    <div className="md:flex md:items-center md:mb-6 mb-2">
                      <div className="">
                        <label
                          className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4 font-outfit"
                          htmlFor="inline-full-name"
                        >
                          * Enter City
                        </label>
                      </div>
                      <div>
                        <select
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                          id="assigned-email-select"
                          value={inviteCity}
                          onChange={(e) => setInviteCity(e.target.value)}
                        >
                          <option value="">Select city</option>
                          {allCityOptions.map((data, i) => (
                            <option key={i} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-center md:mt-12 mt-2">
                  <button
                    type="submit"
                    className="bg-troo-permissionButton rounded flex justify-center items-center px-8 text-[18px] font-outfit font-semibold text-troo-white gap-2 uppercase py-3"
                    data-hs-overlay="#hs-large-modal"
                  >
                    Submit <IoArrowForwardSharp />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        id="hs-basic-modal7"
        className="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
            <div className="p-4 overflow-y-auto">
              <form className="w-full">
                <div className="md:flex md:items-center mb-6 w-full">
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      for="inline-full-name"
                    >
                      Search By Name
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="inline-full-name"
                      type="text"
                      onChange={(e) => setSearchName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      for="inline-password"
                    >
                      Search By Email
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="inline-password"
                      type="text"
                      onChange={(e) => setSearchEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex justify-end items-center gap-x-2">
                  <button
                    type="button"
                    className="py-3 px-6 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-troo-primary text-troo-white "
                    data-hs-overlay="#hs-basic-modal7"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Directory;
