import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <div className="flex justify-end">
      {payload.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{ display: "inline-block", marginRight: "10px" }}
        >
          <div
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "100%",
              backgroundColor: entry.color,
              display: "inline-block",
              marginRight: "5px",
            }}
          />
          <span>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const LineChartSection = () => {
  const data = [
    {
      name: "23 February",
      Canvasses: 4000,
      Avg: 2400,
      Locations: 2400,
    },
    {
      name: " 24 February",
      Canvasses: 3000,
      Avg: 1398,
      Locations: 2210,
    },
    {
      name: "25 February",
      Canvasses: 2000,
      Avg: 9800,
      Locations: 2290,
    },
    {
      name: "26 February",
      Canvasses: 2780,
      Avg: 3908,
      Locations: 2000,
    },
    {
      name: "27 February",
      Canvasses: 1890,
      Avg: 4800,
      Locations: 2181,
    },
    {
      name: "28 February",
      Canvasses: 2390,
      Avg: 3800,
      Locations: 2500,
    },
    {
      name: "29 February",
      Canvasses: 3490,
      Avg: 4300,
      Locations: 2100,
    },
  ];
  return (
    <div className="rounded bg-troo-white ">
      <div className="py-5 px-8 flex justify-between border-b-2">
        <h5 className="font-semibold">Interactions</h5>
        <p>Showing All Time</p>
      </div>
      <div className="py-10 px-5">
        <ResponsiveContainer width="100%" height={506}>
          <LineChart width={730} height={250} data={data}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" tickLine={false} />
            <YAxis tickCount={6} />
            <Tooltip />
            <Legend
              content={<CustomLegend />}
              verticalAlign="top"
              height={36}
            />
            <Line type="linear" dataKey="Avg" stroke="#EEAF27" />
            <Line type="linear" dataKey="Canvasses" stroke="#169265" />
            <Line type="linear" dataKey="Locations" stroke="#52BED6" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default LineChartSection;
