import React from "react";
import Directory from "../Components/Directory";

const DirectoryPage = () => {
  return (
      <Directory />
  );
};

export default DirectoryPage;
