import React, { useState } from "react";
import Sidebar from "../Components/Sidebar";
import Usersbar from "../Components/Usersbar";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { AccountSettingData } from "../Data/AccountSettingData";

const AccountSetting = () => {
  const [open, setOpen] = useState(true);
  const [activeAccordion, setActiveAccordion] = useState(0);

  const toggleAccordion = (index) => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? -1 : index));
  };
  return (
    <div className={`all bg-troo-userbar ${open ? "opened-sidebar" : ""}`}>
      <div className="sticky top-0 z-50 py-3 pr-5  flex justify-between items-center bg-troo-userbar">
        <Sidebar open={open} setOpen={setOpen} />
        <Usersbar />
      </div>
      <div className="bg-troo-userbar h-screen w-full px-12 py-8">
        <div className="bg-troo-white rounded">
          <div className="flex flex-col p-8 gap-3 rounded">
            <h2>Account Settings</h2>
            <h5 className="text-troo-directoryText">
              Manage your account details from here
            </h5>
            <h5 className="text-troo-directoryText">Account Owner</h5>
          </div>
        <div className="hs-accordion-group flex flex-col">
          {AccountSettingData.map((e, i) => (
            <div
              className="hs-accordion px-5 py-4 border-t-2 "
              key={`accordion-${i}`}
              id={`hs-basic-with-title-and-arrow-stretched-heading-${i}`}
            >
              <button
                className="hs-accordion-toggle inline-flex items-center justify-between gap-x-3 w-full font-normal text-start text-troo-black text-opacity-85 font-outfit "
                onClick={() => toggleAccordion(i)}
                aria-controls={`hs-basic-with-title-and-arrow-stretched-collapse-${i}`}
              >
                <div className="flex items-center gap-5">
                  {e.icon}
                  <h5>{e.text}</h5>
                </div>
                {activeAccordion === i ? (
                  <FiChevronDown className=" w-6 h-6 text-troo-black text-opacity-55" />
                ) : (
                  <FiChevronRight className="w-6 h-6 text-troo-black text-opacity-55" />
                )}
              </button>
              <div
                id={`hs-basic-with-title-and-arrow-stretched-collapse-${i}`}
                className={`hs-accordion-content w-full overflow-hidden transition-height duration-300 ease-in-out ${
                  activeAccordion === i ? "max-h-96" : "max-h-0"
                }`}
                aria-labelledby={`hs-basic-with-title-and-arrow-stretched-heading-${i}`}
              >
                <p className="mt-3">
                  Functionality – Our Company uses these cookies so that we
                  recognize you on our website and remember your previously
                  selected preferences. These could include what language you
                  prefer and location you are in. A mix of first-party and
                  third-party cookies are used
                </p>
              </div>
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSetting;
