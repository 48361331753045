import React, { useEffect, useState } from "react";
import { FaCircleUser } from "react-icons/fa6";
import { BsQuestionCircleFill } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  createDatabase,
  fetchDataFromIndexedDB,
  storeDataInIndexedDB,
} from "../Hooks/IndexedDBUtils";
import Dexie from "dexie";
import toast from "react-hot-toast";

const { REACT_APP_BASE_URL } = process.env;
export const db4 = createDatabase("userProfileDB", "userProfileStore");

const UserMenu = () => {
  const navigate = useNavigate()
  const handleLogOut = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("urserMail");
    localStorage.removeItem("userRole");
    localStorage.removeItem("ownerId");
    localStorage.removeItem("cityname");
    localStorage.removeItem("role");
    const databases = [
      { name: "databaseContactDB", store: "databaseContactStore" },
      { name: "walkingListDB", store: "walkingListStore" },
      { name: "UserlistDB", store: "userlistStore" },
      { name: "userProfileDB", store: "userProfileStore" },
      { name: "syncableProfileDB", store: "syncableProfileStore" },
      { name: "syncableTrackingDB", store: "syncableTrackingStore" },
      { name: "TrackingDB", store: "trackingStore" },
      { name: "syncableContactDB", store: "syncableContactsStore" },
      { name: "surveyformDB", store: "surveyformStore" },
      { name: "surveyEntryDB", store: "surveyEntryStore" },
      { name: "syncableWolkingListDB", store: "syncableWalkingListStore" },
      { name: "walkingListDB", store: "walkingListStore" },
      { name: "surveyFieldDB", store: "surveyFieldStore" },
      { name: "CityDB", store: "AllCityStore" },
    ];

    await Promise.all(
      databases.map(async (dbInfo) => {
        const db = createDatabase(dbInfo.name, dbInfo.store);
        await db[dbInfo.store].clear();
        Dexie.delete(dbInfo.name).then(function () {
          console.log(" database deleted ");
        });
      })
    );

    clearSiteData()
  };

  const [currentUser, setCurrentUser] = useState({});
  const [cityName, setCityName] = useState("");
  const [role, setRole] = useState("");
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("urserMail");

  // Clear all relevant site data
  const clearSiteData = () => {
    // Clear localStorage, sessionStorage, and IndexedDB
    localStorage.clear();
    sessionStorage.clear();

    // Clear IndexedDB (asynchronously)
    indexedDB.databases().then((dbs) => {
      dbs.forEach((db) => indexedDB.deleteDatabase(db.name));
    });

    // Clear cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // Optionally clear cache if necessary (cache API or service workers)
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
    }

    // Optional: unregister all service workers
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
      });
    }
  };

  const preventBackNavigation = () => {
    window.history.replaceState(null, null, window.location.href);
    window.history.pushState(null, null, window.location.href);

    window.onpopstate = () => {
      window.history.go(1); // Prevent the back button from navigating back
    };
  };

  const getCurrentUser = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/user`,
        { email: email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userData = response.data;
      setCurrentUser(userData);
      if (userData) {
        await storeDataInIndexedDB(db4, "userProfileStore", userData);
      }
      setCityName(response?.data?.city);
      setRole(response?.data?.roles);
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.message === "Unauthorized!") {
        toast.error(error?.response?.data?.message)
        clearSiteData()
        preventBackNavigation()
        navigate("/")
      }
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      getCurrentUser();
    } else {
      fetchDataFromIndexedDB(db4, "userProfileStore").then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setCurrentUser(data[0]);
          setCityName(data[0].city);
          setRole(data[0].roles);
        } else if (typeof data === "object" && Object.keys(data).length > 0) {
          setCurrentUser(data);
          setCityName(data.city);
          setRole(data.roles);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (role) {
      localStorage.setItem("cityname", cityName);
      localStorage.setItem("userRoles", role);
    }
  }, [role]);

  let mailtoSupport = "mmedina@ctproject.org";
  const userData = [
    {
      name: "Profile Settings",
      path: "/profileSetting",
      icon: <FaCircleUser className="h-6 w-6 text-troo-secondery" />,
    },
    {
      name: "Support",
      path: `mailto:${mailtoSupport}`,
      icon: <BsQuestionCircleFill className="h-6 w-6 text-troo-secondery" />,
    },
  ];

  return (
    <div className="hs-dropdown relative inline-flex">
      <button
        type="button"
        className="hs-dropdown-toggle bg-troo-primary w-8 h-8 rounded-full flex justify-center items-center text-troo-white font-outfit font-bold uppercase"
      >
        {currentUser && currentUser.firstname && currentUser.lastname
          ? `${currentUser.firstname.charAt(0).toUpperCase()}${currentUser.lastname.charAt(0).toUpperCase()}`
          : null}
      </button>

      <div
        className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 w-80 hidden z-10 mt-2 min-w-60 bg-white shadow-md rounded-lg "
        aria-labelledby="hs-dropdown-basic"
      >
        <button className="flex gap-5 py-3 px-3  w-full border-b">
          <div>
            <h2 className="w-16 h-16 bg-troo-primary text-troo-white rounded-full items-center justify-center flex font-outfit font-bold ">
              {currentUser && currentUser.firstname && currentUser.lastname
                ? `${currentUser.firstname.charAt(0).toUpperCase()}${currentUser.lastname.charAt(0).toUpperCase()}`
                : null}
            </h2>
          </div>
          <div className="flex flex-col text-left">
            <h5 className="font-bold flex gap-1">
              <span>{currentUser?.firstname}</span>
              <span>{currentUser?.lastname}</span>
            </h5>
            <h6>{currentUser.email}</h6>
            <p className="text-troo-secondery">
              {currentUser?.roles ? currentUser.roles.replace(/_/g, " ") : ""}
            </p>
          </div>
        </button>
        {userData.map((ele, i) =>
          ele.path.startsWith("mailto:") ? (
            <a
              key={i}
              href={ele.path}
              className="flex items-center  gap-3 py-3 px-3  w-full border-b"
            >
              <div>{ele.icon}</div>
              <h6>{ele.name}</h6>
            </a>
          ) : (
            <Link
              key={i}
              to={ele.path}
              className="flex items-center  gap-3 py-3 px-3  w-full border-b"
            >
              <div>{ele.icon}</div>
              <h6>{ele.name}</h6>
            </Link>
          )
        )}
        <Link
          className="flex items-center  gap-3 py-3 px-3  w-full border-b"
          to="/"
          onClick={() => handleLogOut()}
        >
          <div>
            <BiLogOut />
          </div>
          <h6>LogOut</h6>
        </Link>
      </div>
    </div>
  );
};

export default UserMenu;
