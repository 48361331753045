import React, { useEffect, useState } from "react";
import * as yup from "yup";
import Input from "./Input";
import logo from "../images/login_Logo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import passwordIcon from "../images/passwordIcon.svg";
import toast from "react-hot-toast";

const { REACT_APP_BASE_URL } = process.env;

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(5, "Atleast 6 characters long")
    .max(50, "Too Long")
    .required("New Password is Required"),
  confirm_password: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ResetPassword = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const saveApiEmail = searchParams.get("email");
    const tokenfromURL = searchParams.get("token");

    let data = {
      email: saveApiEmail,
    };

    axios
      .get(
        `${REACT_APP_BASE_URL}/user/reset-password-load/?email=${saveApiEmail}&token=${tokenfromURL}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (
          response.data.message !== "Fotgot Token is validated, User found."
        ) {
          toast.error("Token and Email is not validated");
          navigate("/forget-password");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error(error.response.data.message);
        navigate("/forget-password");
      });
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const saveApiEmail = searchParams.get("email");
      const tokenfromURL = searchParams.get("token");

      const data = {
        email: saveApiEmail,
        token: tokenfromURL,
        password: values.password,
        confirm_password: values.confirm_password,

      };

      await axios
        .post(`${REACT_APP_BASE_URL}/user/reset-password`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.message) {
            toast.success(res?.data?.message);
            resetForm();
            navigate("/");
          }
        });
    } catch (error) {
      console.error("Password reset error:", error);
      toast.error("Failed to reset password. Please try again.");
    }
  };

  return (
    <div className="bg-troo-primary h-screen">
      <div className="mainContainer flex flex-col justify-center items-center w-full h-full py-12 lg:py-24">
        <div className="bg-troo-white py-11 px-5 flex gap-2 flex-col items-center rounded-3xl w-[350px]">
          <div>
            <img src={logo} alt="logo" />
          </div>
          <h1 className="text-troo-primary font-bold">CANVASSING APP</h1>

          <div className="flex flex-col items-center">
            <p className="text-[11.25px] text-troo-secondery">
              Enter a New password with conformation password
            </p>
          </div>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            initialValues={{
              password: "",
              confirm_password: "",
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values }) => (
              <Form
                className="flex flex-col gap-5 w-full"
                noValidate
                onSubmit={handleSubmit}
              >
                <div>
                  <Field
                    name="password"
                    type="password"
                    as={Input}
                    value={values?.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon={passwordIcon}
                    lebel="Password"
                  />
                  <ErrorMessage
                    name="password"
                    component="span"
                    className="text-red-500 text-xs"
                  />
                </div>
                <div>
                  <Field
                    name="confirm_password"
                    type="password"
                    as={Input}
                    value={values?.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon={passwordIcon}
                    lebel="Confirm Password"
                  />
                  <ErrorMessage
                    name="confirm_password"
                    component="span"
                    className="text-red-500 text-xs"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full font-outfit text-[12px] uppercase font-bold bg-troo-primary text-troo-white py-3 px-5 rounded-[37px]"
                >
                  CONTINUE
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <Link
          to="/resister"
          className="text-troo-white font-outfit mt-4 font-semibold text-[12px]"
        >
          Don't have an account?
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
