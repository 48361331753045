import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import ActionDetails from "./ActionDetails";
import AddCoverPhoto from "./AddCoverPhoto";
import WhereLocation from "./WhereLocation";
import WhenAction from "./WhenAction";
import WhoInvited from "./WhoInvited";
import InvitedList from "./InvitedList";
import AssoiatedList from "./AssoiatedList";
import ActionPriview from "./ActionPriview";
import ActionFinal from "./ActionFinal";

const ActionStepForm = () => {
  const [stepNumber, setStepNumber] = useState(1);
  const [showFinalPage, setShowFinalPage] = useState(false);

  const nextStep = () => {
    if (stepNumber < 7) {
      setStepNumber((prevStep) => prevStep + 1);
    } else {
      setShowFinalPage(true);
    }
  };

  const prevStep = () => {
    setStepNumber((prevStep) => prevStep - 1);
  };

  return (
    showFinalPage ? (
      <ActionFinal />
    ) : (
      <div className="bg-troo-userbar h-screen">
        <div className="px-5 py-2 border-b-2">
          <div className="flex gap-2 uppercase items-center font-semibold font-outfit text-troo-black text-opacity-85 cursor-pointer ">
            <IoCloseCircleOutline className="w-5 h-5" />
            Discard
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-[60%] bg-troo-white flex flex-col">
            <div className="min-h-[500px] lg:px-8 px-5 py-10">
              {stepNumber === 1 && <ActionDetails />}
              {stepNumber === 2 && <AddCoverPhoto />}
              {stepNumber === 3 && <WhereLocation />}
              {stepNumber === 4 && <WhenAction />}
              {stepNumber === 5 && <WhoInvited />}
              {stepNumber === 6 && <InvitedList />}
              {stepNumber === 7 && <AssoiatedList />}
            </div>
            <div className="lg:px-8 px-5 py-5 flex flex-col gap-5">
              <div className="grid grid-cols-7 gap-5">
                {[1, 2, 3, 4, 5, 6, 7].map((step) => (
                  <div
                    key={step}
                    className={`h-2 rounded-xl ${
                      step <= stepNumber ? "bg-troo-permissionButton" : "bg-[#F0F2F5]"
                    }`}
                  ></div>
                ))}
              </div>
              <div className="flex justify-between gap-8 items-center w-full">
                <button
                  onClick={prevStep}
                  className={`px-5 py-2  text-troo-white font-semibold font-outfit rounded-lg ${
                    stepNumber > 1 ? "bg-troo-primary" : "bg-[#9B9B9B]"
                  }`}
                >
                  Back
                </button>

                <button
                  onClick={nextStep}
                  className="w-full px-5 py-2 bg-troo-permissionButton text-troo-white rounded-lg font-semibold font-outfit"
                >
                  {stepNumber === 7 ? "Create Action" : "Next"}
                </button>
              </div>
            </div>
          </div>
          <div className="w-[40%] p-6">
            <ActionPriview />
          </div>
        </div>
      </div>
    )
  );
};

export default ActionStepForm;
