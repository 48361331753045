import React from 'react'
import Modal from "react-modal";
import AddContactHubspotForm from './AddContactHubspotForm';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "80%",
    height: "90%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  },
  overlay: {
    backgroundColor: "rgba(17, 24, 39, 0.5)",
    zIndex: 9999,
  },
};

const AddContactModal = ({ isOpen, onRequestClose, data, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Add Contact Modal"
    >
      <div className='flex flex-col items-start gap-4 w-full'>
        <h2>Add Contact</h2>
        <AddContactHubspotForm data={data} closeModal={onRequestClose} />
      </div>
    </Modal>
  )
}

export default AddContactModal