import SurveyEntry from "../Components/SurveyEntry";

const SurveyEntryPage = () => {

  return (
      <SurveyEntry />
  );
};

export default SurveyEntryPage;
