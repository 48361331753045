import React, { createContext, useState, useEffect } from "react";

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [locationPermission, setLocationPermission] = useState(
    JSON.parse(localStorage.getItem("isAllowLocation")) || false
  );
  const [location, setLocation] = useState(
    JSON.parse(localStorage.getItem("currentLocation")) || {}
  );

  useEffect(() => {
    const storedPermission =
      JSON.parse(localStorage.getItem("isAllowLocation")) || false;
    setLocationPermission(storedPermission);
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedPermission =
        JSON.parse(localStorage.getItem("isAllowLocation")) || false;
      setLocationPermission(updatedPermission);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  useEffect(() => {
    if (locationPermission) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setLocation(currentLocation);
          localStorage.setItem(
            "currentLocation",
            JSON.stringify(currentLocation)
          );
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    }
  }, [locationPermission]);

  return (
    <LocationContext.Provider
      value={{
        locationPermission,
        setLocationPermission,
        location,
        setLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
