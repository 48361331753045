import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import filterIcon from "../images/filterIcon.png";
import searchIcon from "../images/scarchIcon.svg";
import { MdOutlineWifi, MdOutlineWifiOff } from "react-icons/md";
import { LuArrowDownUp } from "react-icons/lu";
import { FiDownload, FiRefreshCcw } from "react-icons/fi";
import UserMenu from "./UserMenu";
import axios from "axios";
import toast from "react-hot-toast";

import {
  deleteDataFromIndexedDB,
  fetchDataFromIndexedDB,
} from "../Hooks/IndexedDBUtils";
import { syncableContactDB } from "./DatabaseComponent";
import { syncableWolkingListDB } from "./WalkingList";
import { syncableProfile } from "../Pages/ProfileSetting";
import { syncableTracking } from "./CanvassMap";
import { surveyForm } from "../Pages/HubsportForm";
import SidebarUnlockModal from "./SidebarUnlockModal";
import { MdOutlineLocationOff } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";
import { clearStorage, storeLocalData, storeObjectData } from "../utils";
import { LocationContext } from "./LocationContext";

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     width: "50%",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
//   overlay: {
//     backgroundColor: "rgba(17, 24, 39, 0.5)",
//   },
// };

const Usersbar = ({
  isMapPage,
  hideSidebar,
  hideSidebarChecked,
  setHideSidebarChecked,
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { REACT_APP_BASE_URL } = process.env;
  const token = localStorage.getItem("token");
  const subtitleRef = useRef(null);
  const email = localStorage.getItem("urserMail");
  const { locationPermission, setLocationPermission, location, setLocation } =
    useContext(LocationContext);

  function openModal() {
    setIsOpen(true);
  }
  const showLocationToggle = useLocation()?.pathname === "/canvass-map";

  const checkLocationPermission = async () => {
    try {
      const permission = await navigator.permissions.query({
        name: "geolocation",
      });
      if (permission.state === "granted") {
        getCurrentLocation();
      } else if (permission.state === "prompt") {
        getCurrentLocation();
      } else if (permission.state === "denied") {
        alert(
          "Location access is blocked. Please enable location permissions in your browser settings."
        );
        setLocation({});
        storeObjectData("currentLocation", {});
        setLocationPermission(false);
      }
    } catch (error) {
      console.error("Permission check failed:", error);
    }
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const newLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        setLocation(newLocation);
        storeObjectData("currentLocation", newLocation);
        setLocationPermission(true);
      },
      (error) => {
        console.error("Geolocation error:", error.message);
        setLocationPermission(false);
        storeObjectData("currentLocation", {});
      }
    );
  };

  const handleLocationClick = () => {
    const newState = !locationPermission;
    setLocationPermission(newState);

    if (newState) {
      checkLocationPermission();
    } else {
      setLocation({});
      storeObjectData("currentLocation", {});
    }
  };

  useEffect(() => {
    storeLocalData("isAllowLocation", locationPermission);
    storeObjectData("currentLocation", location);
  }, [locationPermission]);

  function afterOpenModal() {
    if (subtitleRef.current) {
      subtitleRef.current.style.color = "#43182780";
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      localStorage.setItem("lock", isChecked ? "true" : "false");
      setHideSidebarChecked(isChecked);
      hideSidebar(isChecked);
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, null, window.location.href);
      };
    } else {
      openModal();
    }
  };

  const userMail = localStorage.getItem("urserMail");

  const [owner, setOwner] = useState("");
  const ownerID = localStorage.getItem("ownerId");

  const fetchUserList = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/ownerlist`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newData = response.data.results;
      const ownerMail = newData.filter((e) => e.email === userMail);
      setOwner(ownerMail);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      fetchUserList();
    }
  }, []);

  useEffect(() => {
    const OwnerId = owner[0]?.id;
    if (OwnerId) {
      localStorage.setItem("ownerId", OwnerId);
    }
  }, [owner]);

  const handleSyncData = async () => {
    const offlineSubmissions =
      JSON.parse(localStorage.getItem("offlineSubmissions")) || [];
    if (offlineSubmissions && offlineSubmissions?.length > 0) {
      const cleanedOfflineSubmission = offlineSubmissions.map((item) => {
        const { hs_context, ...formObjectWithoutHsContext } = item.formObject;
        return {
          ...item,
          formObject: formObjectWithoutHsContext,
        };
      });
      axios
        .post(
          `${REACT_APP_BASE_URL}/hs_form_submit`,
          { all_forms_data: cleanedOfflineSubmission },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // Handle the response
          if (response.status === 200) {
            response.data.responses.forEach((res) => {
              if (res.status === "success") {
                toast.success(res.data.inlineMessage);
              } else {
                toast.error("There was an error with your survey submission.");
              }
            });
            localStorage.removeItem("offlineSubmissions");
          } else {
            toast.error("Failed to submit the forms.");
          }
        })
        .catch((error) => {
          toast.error(
            error?.message ||
              "something went wrong with your survey submission."
          );
        });
    }
    const storedSyncableContacts = await fetchDataFromIndexedDB(
      syncableContactDB,
      "syncableContactsStore"
    );
    const storedSyncableWalkingList = await fetchDataFromIndexedDB(
      syncableWolkingListDB,
      "syncableWalkingListStore"
    );
    const storedSyncableProfile = await fetchDataFromIndexedDB(
      syncableProfile,
      "syncableProfileStore"
    );
    const storedSyncableTracking = await fetchDataFromIndexedDB(
      syncableTracking,
      "syncableTrackingStore"
    );
    const storedSyncableSurvey = await fetchDataFromIndexedDB(
      surveyForm,
      "surveyformStore"
    );

    if (storedSyncableContacts && storedSyncableContacts?.length > 0) {
      let index = 0;

      const syncContacts = async () => {
        if (index < storedSyncableContacts.length) {
          const contact = storedSyncableContacts[index];
          const data = {
            id: contact?.id,
            ...contact?.properties,
          };
          await syncContactsCallApi(data, token);
          index++;
          setTimeout(syncContacts, 1000);

          deleteDataFromIndexedDB("syncableContactDB", "syncableContactsStore");
        } else {
          console.log(
            "All data synced and deleted from syncableContactsStore store."
          );
        }
      };
      syncContacts();
    }
    if (storedSyncableWalkingList && storedSyncableWalkingList?.length > 0) {
      let index = 0;
      const syncContacts = async () => {
        if (index < storedSyncableWalkingList.length) {
          const contact = storedSyncableWalkingList[index];
          const data = {
            id: contact?.id,
            ...contact?.properties,
          };
          await syncContactsCallApi(data, token);
          index++;
          setTimeout(syncContacts, 1000);
          deleteDataFromIndexedDB(
            "syncableWolkingListDB",
            "syncableWalkingListStore"
          );
        } else {
          console.log(
            "All data synced and deleted from syncableWalkingListStore store."
          );
        }
      };
      syncContacts();
    }
    if (storedSyncableProfile && storedSyncableProfile?.length > 0) {
      let index = 0;
      const syncProfile = async () => {
        if (index < storedSyncableProfile.length) {
          const contact = storedSyncableProfile[index];
          const data = {
            id: contact?.id,
            ...contact,
          };
          await syncProfileCallApi(data, token);
          index++;
          setTimeout(syncProfile, 1000);
          deleteDataFromIndexedDB("syncableProfileDB", "syncableProfileStore");
        } else {
          console.log(
            "All data synced and deleted from syncableProfileStore store."
          );
        }
      };
      syncProfile();
    }
    if (storedSyncableTracking && storedSyncableTracking?.length > 0) {
      let index = 0;

      const syncContacts = async () => {
        if (index < storedSyncableTracking.length) {
          const contact = storedSyncableTracking[index];
          const data = {
            id: contact?.id,
            ...contact?.properties,
          };
          await syncTrackingCallApi(data, token);
          index++;

          setTimeout(syncContacts, 1000);
          deleteDataFromIndexedDB(
            "syncableTrackingDB",
            "syncableTrackingStore"
          );
        } else {
          console.log(
            "All data synced and deleted from syncableTrackingStore store."
          );
        }
      };
      syncContacts();
    }
    if (storedSyncableSurvey && storedSyncableSurvey?.length > 0) {
      let index = 0;

      const syncSurveys = async () => {
        if (index < storedSyncableSurvey.length) {
          const contact = storedSyncableSurvey[index];
          const { id, ...data } = contact;
          await syncSurveyApi(data, token);
          index++;

          setTimeout(syncSurveys, 1000);
          deleteDataFromIndexedDB("surveyformDB", "surveyformStore");
        } else {
          console.log(
            "All data synced and deleted from surveyformStore store."
          );
        }
      };
      syncSurveys();
    }
  };

  const syncContactsCallApi = async (data, token) => {
    try {
      const res = await axios.put(`${REACT_APP_BASE_URL}/database`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data) {
        console.log("Data successfully sent to the server.");
      } else {
        console.error("Failed to send data to the server.");
      }
    } catch (error) {
      console.log("Error occurred while sending data to the server:", error);
    }
  };
  const syncProfileCallApi = async (data, token) => {
    try {
      const res = await axios.put(`${REACT_APP_BASE_URL}/updateprofile`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data) {
        console.log("Data successfully sent to the server.");
      } else {
        console.error("Failed to send data to the server.");
      }
    } catch (error) {
      console.log("Error occurred while sending data to the server:", error);
    }
  };
  const syncTrackingCallApi = async (data, token) => {
    try {
      const res = await axios.put(
        `${REACT_APP_BASE_URL}/householdList`,
        { ...data, owner_id: ownerID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data) {
        console.log("Data successfully sent to the server.");
      } else {
        console.error("Failed to send data to the server.");
      }
    } catch (error) {
      console.log("Error occurred while sending data to the server:", error);
    }
  };
  const syncSurveyApi = async (data, token) => {
    try {
      const res = await axios.post(
        `${REACT_APP_BASE_URL}/post_survey_form`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data) {
        toast.success("Data successfully sent to the server");
      } else {
        console.error("Failed to send data to the server.");
      }
    } catch (error) {
      console.log("Error occurred while sending data to the server:", error);
    }
  };

  const handleScroll = (event) => {
    event.preventDefault();
    event.target.blur();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/user`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const lock_code = response?.data?.lock_code;
      if (
        Number(lock_code) === Number(password) ||
        Number(password) === Number("0000")
      ) {
        localStorage.setItem("lock", "false");
        setHideSidebarChecked(false);
        hideSidebar(false);
        closeModal();
        toast.success("Unlock Successfully");
      } else {
        toast.error("please correct the LockPin");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefreshData = () => {
    clearStorage();
  };
  return (
    <div className={`flex gap-5 items-center`}>
      {isMapPage && (
        <form className="flex gap-1 items-center">
          <button>
            <img
              className={`${
                isMapPage && "bg-troo-white rounded-full w-8 h-8 p-2"
              }`}
              src={searchIcon}
              alt="scarchIcon"
            />
          </button>
          <div className="max-w-[220px]">
            <input
              type="text"
              placeholder="Connecticut"
              className="px-5 py-1 rounded placeholder:text-troo-black w-full border-b border-troo-black"
            />
          </div>
        </form>
      )}
      {isMapPage && (
        <Link>
          <img
            src={filterIcon}
            alt="filterIcon"
            className={`${
              isMapPage && "bg-troo-white rounded-full w-8 h-8 p-2"
            }`}
          />
        </Link>
      )}
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => handleRefreshData()}
      >
        <FiRefreshCcw size={24} className="text-troo-primary cursor-pointer" />
      </div>

      <div>
        {showLocationToggle && (
          <div
            className="flex gap-5 items-center cursor-pointer"
            onClick={handleLocationClick}
          >
            {locationPermission === true ? (
              <MdOutlineLocationOn size={27} className="text-troo-primary" />
            ) : (
              <MdOutlineLocationOff size={27} className="text-troo-primary" />
            )}
          </div>
        )}
      </div>
      <div>
        <input
          type="checkbox"
          checked={hideSidebarChecked}
          onChange={handleCheckboxChange}
          id="hs-basic-with-description-unchecked"
          className="relative w-10 h-[24px] p-px bg-white border-troo-primary/50 text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-troo-primary checked:border-troo-primary before:inline-block before:size-5 before:bg-troo-primary checked:before:bg-troo-white before:border-troo-primary before:checked:border-troo-primary before:translate-x-0 checked:before:translate-x-4  before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 ring-0 outline-none focus:ring-0"
        />
        <div>
          <SidebarUnlockModal
            open={modalIsOpen}
            onClose={closeModal}
            setPassword={setPassword}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>

      <div className="hs-dropdown relative inline-flex">
        <button
          id="hs-dropdown-basic"
          type="button"
          className="hs-dropdown-toggle "
        >
          {navigator.onLine === true ? (
            <MdOutlineWifi className="w-5 h-5" />
          ) : (
            <MdOutlineWifiOff className="w-5 h-5" />
          )}
        </button>

        <div
          className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 w-56 hidden z-10 mt-2 min-w-60 bg-white shadow-md rounded-lg "
          aria-labelledby="hs-dropdown-basic"
        >
          <button className="flex items-center justify-between gap-5 py-3 px-3  w-full border-b">
            <h4>Status {navigator.onLine === true ? "Online" : "Offline"}</h4>
            {navigator.onLine === true ? (
              <MdOutlineWifi className="w-5 h-5 text-troo-secondery" />
            ) : (
              <MdOutlineWifiOff className="w-5 h-5 text-troo-secondery" />
            )}
          </button>
          {navigator.onLine === true && (
            <button
              className="flex items-center justify-between gap-5 py-3 px-3  w-full border-b"
              onClick={handleSyncData}
            >
              <h6>Synchronize Now</h6>
              <LuArrowDownUp className="w-5 h-5 text-troo-secondery" />
            </button>
          )}

          <button
            disabled
            className="flex items-center opacity-40 justify-between gap-5 py-3 px-3  w-full border-b"
          >
            <h6>Save Data</h6>
            <FiDownload className="w-5 h-5 text-troo-secondery" />
          </button>
        </div>
      </div>
      {!hideSidebarChecked && <UserMenu />}
    </div>
  );
};

export default Usersbar;
