import React, { useEffect, useState } from "react";
import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

const ScheduleMeet = () => {
  const [currentUser, setCurrentUser] = useState({});
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("urserMail");

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    script.onload = getCurrentUser;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getCurrentUser = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/user`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCurrentUser(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-troo-userbar h-screen md:p-10 p-2">
      {currentUser?.meet_embedded ? (
        <iframe
          title="meeting embeded"
          className="meetings-iframe-container w-full h-screen"
          src={currentUser?.meet_embedded}
        ></iframe>
      ) : (
        <h2 className="flex items-center justify-center font-outfit text-troo-primary">
          Please add user Meeting Code URL in Profile Setting
        </h2>
      )}
    </div>
  );
};

export default ScheduleMeet;
