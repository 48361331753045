import React from "react";
import { TbCameraPlus } from "react-icons/tb";

const AddCoverPhoto = () => {
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File uploaded:", file);
    } else {
      console.log("No file selected.");
    }
  };
  return (
    <div className="flex flex-col gap-5">
      <h1>Add Cover Photo?</h1>
      <h5>Upload a photo to give context to your action.</h5>
      <form className="mb-12 flex flex-col">
        <input
          id="fileInput"
          type="file"
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
        <button
          className="bg-[#34495E] min-h-[300px] text-troo-secondery px-5 py-2 rounded flex items-center justify-center"
          onClick={() => {
            document.getElementById("fileInput").click();
          }}
        >
          <TbCameraPlus className="w-10 h-10" />
        </button>
      </form>
    </div>
  );
};

export default AddCoverPhoto;
