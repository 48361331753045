import React, { useState } from "react";
import { BiQuestionMark } from "react-icons/bi";
import { PrivencyData } from "../Data/PrivencyData";
import { FiChevronDown, FiChevronRight, FiPlus } from "react-icons/fi";
import { MdVerifiedUser } from "react-icons/md";

const Privacy = () => {
  const [activeAccordion, setActiveAccordion] = useState(0);

  const toggleAccordion = (index) => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? -1 : index));
  };
  return (
    <div className="bg-troo-userbar w-full px-12 py-8 flex flex-col gap-8">
      <div className="bg-troo-white p-8 flex flex-col gap-3 rounded">
        <div className="flex items-center gap-5">
          <h1>Privacy Dashboard</h1>
          <div className="w-6 h-6 bg-troo-secondery text-troo-white rounded-full flex justify-center items-center">
            <BiQuestionMark />
          </div>
        </div>
        <h4 className="text-troo-secondery">
          Your dedicated Privacy Dashboard helps you comply with your data{" "}
          <br />
          protection obligations
        </h4>
      </div>
      <div className="bg-troo-white rounded">
        <div className="flex flex-col p-8 gap-3 rounded">
          <h2>Privacy Settings</h2>
          <h5 className="text-troo-directoryText">
            View and manage your account's privacy settings
          </h5>
        </div>
        <div className="hs-accordion-group flex flex-col">
          {PrivencyData.map((e, i) => (
            <div
              className="hs-accordion px-5 py-4 border-t-2 "
              key={`accordion-${i}`}
              id={`hs-basic-with-title-and-arrow-stretched-heading-${i}`}
            >
              <button
                className="hs-accordion-toggle inline-flex items-center justify-between gap-x-3 w-full font-normal text-start text-troo-black text-opacity-85 font-outfit "
                onClick={() => toggleAccordion(i)}
                aria-controls={`hs-basic-with-title-and-arrow-stretched-collapse-${i}`}
              >
                <div className="flex items-center gap-5">
                  {e.icon}
                  <h5>{e.text}</h5>
                </div>
                {activeAccordion === i ? (
                  <FiChevronDown className=" w-6 h-6 text-troo-black text-opacity-55" />
                ) : (
                  <FiChevronRight className="w-6 h-6 text-troo-black text-opacity-55" />
                )}
              </button>
              <div
                id={`hs-basic-with-title-and-arrow-stretched-collapse-${i}`}
                className={`hs-accordion-content w-full overflow-hidden transition-height duration-300 ease-in-out ${
                  activeAccordion === i ? "max-h-96" : "max-h-0"
                }`}
                aria-labelledby={`hs-basic-with-title-and-arrow-stretched-heading-${i}`}
              >
                <p className="mt-3">
                  Functionality – Our Company uses these cookies so that we
                  recognize you on our website and remember your previously
                  selected preferences. These could include what language you
                  prefer and location you are in. A mix of first-party and
                  third-party cookies are used
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-troo-white rounded">
        <div className="flex flex-col p-8 gap-3 rounded">
          <h2>Privacy Notices</h2>
          <h5 className="text-troo-directoryText">
            Manage your members acknowledgement of privacy compliance across
            Canvassing apps
          </h5>
        </div>
        <div className="px-8 py-5 flex justify-between gap-5 border-t-2 ">
          <div className="flex gap-3">
            <MdVerifiedUser className="w-6 h-6 text-troo-primary" />
            <div className="flex flex-col gap-1">
              <h5>Privacy Notice for DigitalReach </h5>
              <p className="text-troo-black text-opacity-55 font-outfit font-semibold">
                Version 0
              </p>
            </div>
            <div>
              <p className="px-2 py-1 text-troo-white bg-troo-primary rounded text-xs flex items-center justify-center">
                Sample
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1 items-end text-troo-tersery">
            <p>Created by Admin</p>
            <p>6 days ago</p>
          </div>
        </div>
        <div className="px-8 py-5 flex justify-between gap-5 border-t-2">
          <div className="flex items-center gap-3 text-troo-black text-opacity-85 font-outfit">
            <FiPlus className=" w-5 h-5" />
            <h5>Create new version</h5>
          </div>
          <div>
            <FiChevronRight className=" w-6 h-6 text-troo-black text-opacity-55" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
