import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import Input from "./Input";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import logo from "../images/login_Logo.svg";
import passwordIcon from "../images/passwordIcon.svg";
import mailIcon from "../images/mailIcon.svg";
// import otpIcon from "../images/otpIcon.svg";
import axios from "axios";
import OtpInput from "react-otp-input";
import toast from "react-hot-toast";
import ButtonLoader from "../Components/ButtonLoader";
import { useNavigate } from "react-router-dom";

const { REACT_APP_BASE_URL } = process.env;

const validationSchemaStep1 = yup.object().shape({
  email: yup.string().required("Email is Required").email("Invalid email format"),
});

const validationSchemaStep3 = yup.object().shape({
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is Required"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const GeneratePassword = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitStep1 = async (values, { setSubmitting }) => {
    try {
      let data = { email: values.email };

      await axios.post(`${REACT_APP_BASE_URL}/send_otp`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response?.data?.status?.toLowerCase() === "success") {
          setStep(2);
          setEmail(values.email);
          toast.success(response?.data?.message || "OTP sent to your email");
        } else {
          toast.error(response?.data?.message || "Error sending OTP");
        }
      })

    } catch (error) {
      toast.error(error?.response?.data?.message || "Error sending OTP");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitStep2 = async () => {
    try {
      setIsLoading(true)
      let data = { otp, email };

      await axios.post(`${REACT_APP_BASE_URL}/verify_otp`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response?.data?.status?.toLowerCase() === "success") {
          setStep(3);
          toast.success(response?.data?.message || "OTP verified successfully");
        } else {
          toast.error(response?.data?.message || "Error verifying OTP");
        }
      })
    } catch (error) {
      console.log("error response", error?.response)
      toast.error(error?.response?.data?.message || "Error verifying OTP");
    } finally {
      setIsLoading(false)
    }
  };

  const handleSubmitStep3 = async (values, { resetForm, setSubmitting }) => {
    try {
      let data = {
        email: email,
        password: values.password,
      };

      await axios.post(`${REACT_APP_BASE_URL}/auth/generatePassword`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response?.data?.status?.toLowerCase() === "success") {
          navigate("/")
          setStep(1);
          resetForm();
          toast.success(response?.data?.message || "Password reset successfully");
        } else {
          toast.error(response?.data?.message || "Error verifying OTP");
        }
      })
    } catch (error) {
      console.log("error response message", error)
      toast.error(error?.response?.data?.message || "Error resetting password");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-troo-primary h-screen">
      <div className="mainContainer flex flex-col justify-center items-center w-full h-full py-12 lg:py-24">
        <div className="bg-troo-white py-11 px-5 flex gap-2 flex-col items-center rounded-3xl w-[375px]">
          <img src={logo} alt="logo" />
          <h1 className="text-troo-primary font-bold">Generate Password</h1>
          <p className="text-[11.25px] text-troo-secondery">
            {step === 1 ? "Enter your email to receive an OTP" :
              step === 2 ? "Enter the 6-digit OTP sent to your email" :
                "Set your new password"}
          </p>

          {step === 1 && (
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchemaStep1}
              onSubmit={handleSubmitStep1}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-5 w-full" noValidate>
                  <div>
                    <Field
                      name="email"
                      type="email"
                      as={Input}
                      icon={mailIcon}
                      lebel="Email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-xs"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full flex items-center gap-3 justify-center text-center font-outfit text-[12px] uppercase font-bold bg-troo-primary text-troo-white py-3 px-5 rounded-[37px] disabled:cursor-not-allowed disabled:bg-troo-primary/20 disabled:text-troo-primary"
                    disabled={isSubmitting}
                  >
                    {
                      isSubmitting && (
                        <ButtonLoader/>
                      )
                    }
                    Send OTP
                  </button>
                </Form>
              )}
            </Formik>
          )}

          {step === 2 && (
            <div className="flex flex-col gap-5 w-full items-center">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="number"
                renderSeparator={<span> &nbsp; &nbsp; </span>}
                inputStyle="border borer-troo-primary rounded-xl py-2 px-2 text-center !size-11 lg:!size-12 !aspect-square otp-input"
                renderInput={(props) => <input {...props} />}
              />
              <button
                type="submit"
                className="w-full flex items-center gap-3 justify-center text-center font-outfit text-[12px] uppercase font-bold bg-troo-primary text-troo-white py-3 px-5 rounded-[37px] disabled:cursor-not-allowed disabled:bg-troo-primary/20 disabled:text-troo-primary"
                disabled={otp?.length < 6 || isLoading}
                onClick={handleSubmitStep2}
              >
                {
                  isLoading && (
                    <ButtonLoader />
                  )
                }
                Verify OTP
              </button>
            </div>
          )}

          {step === 3 && (
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={validationSchemaStep3}
              onSubmit={handleSubmitStep3}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-5 w-full" noValidate>
                  <div>
                    <Field
                      name="password"
                      type="password"
                      as={Input}
                      icon={passwordIcon}
                      lebel="Password"
                      BsEyeFill={<BsEyeFill />}
                      BsEyeSlashFill={<BsEyeSlashFill />}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-xs"
                    />
                  </div>
                  <div>
                    <Field
                      name="confirmPassword"
                      type="password"
                      as={Input}
                      icon={passwordIcon}
                      lebel="Confirm Password"
                      BsEyeFill={<BsEyeFill />}
                      BsEyeSlashFill={<BsEyeSlashFill />}
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="text-red-500 text-xs"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full flex items-center gap-3 justify-center text-center font-outfit text-[12px] uppercase font-bold bg-troo-primary text-troo-white py-3 px-5 rounded-[37px] disabled:cursor-not-allowed disabled:bg-troo-primary/20 disabled:text-troo-primary"
                    disabled={isSubmitting}
                  >
                    {
                      isSubmitting && (
                        <ButtonLoader />
                      )
                    }
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneratePassword;
