import React from "react";
import { FaFileLines } from "react-icons/fa6";
import { LuArrowRightLeft } from "react-icons/lu";
import { RiCalendar2Line } from "react-icons/ri";

const GridForm = () => {
  return (
    <div>
      <form className="grid grid-cols-4 gap-8">
        <div className="relative">
          <select
            className="outline-none appearance-none w-full bg-transparent border-t-0 border-x-0 border-b border-troo-tersery text-gray-700 py-3 text-base font-medium font-outfit px-8 leading-tight "
            id="grid-state"
          >
            <option>Canvass</option>
            <option>Canvass1</option>
            <option>Canvass2</option>
          </select>
          <div className="absolute left-2 top-1/2 -translate-y-1/2">
            <FaFileLines className="w-5 h-5 text-[#69719C]" />
          </div>
        </div>
        <div className="relative">
          <select
            className="outline-none appearance-none w-full bg-transparent border-t-0 border-x-0 border-b border-troo-tersery text-gray-700 py-3 text-base font-medium font-outfit px-8 leading-tight "
            id="grid-state"
          >
            <option>In Progress</option>
            <option>Yet to start</option>
            <option>Completed</option>
          </select>
          <div className="absolute left-2 top-1/2 -translate-y-1/2">
            <LuArrowRightLeft className="w-5 h-5 text-[#69719C]" />
          </div>
        </div>
        <div className="relative">
          <select
            className="outline-none appearance-none w-full bg-transparent border-t-0 border-x-0 border-b border-troo-tersery text-gray-700 py-3 text-base font-medium font-outfit px-8 leading-tight "
            id="grid-state"
          >
            <option>All Time</option>
            <option>Day</option>
            <option>Night</option>
          </select>
          <div className="absolute left-2 top-1/2 -translate-y-1/2">
            <RiCalendar2Line className="w-5 h-5 text-[#69719C]" />
          </div>
        </div>
        <div className="flex justify-end">
          <button className="py-2 px-5 text-troo-white bg-troo-primary rounded font-outfit uppercase">
            Export
          </button>
        </div>
      </form>
    </div>
  );
};

export default GridForm;
