import React, { useEffect, useState } from "react";
import { MdOutlineHome } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { SyncLoader } from "react-spinners";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import EditInputField from "./EditInputField";
import Modal from "react-modal";
import {
  storeDataInIndexedDB,
  fetchDataFromIndexedDB,
  updateDataInIndexedDB,
} from "../Hooks/IndexedDBUtils";
import Accordian from "./Accordian";
import ButtonLoader from "./ButtonLoader";
import { ANALYTICS_SOURCE, MEETING_OUTCOME } from "../constants";
import { Dialog, DialogPanel } from "@headlessui/react";
import CommonSurveySelection from "./CommonSurveySelection";
import CustomModal from "./CustomModal";
const { REACT_APP_BASE_URL } = process.env;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "100%",
    // height: "90%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    border: "none",
    zIndex: 9999,
  },
  overlay: {
    backgroundColor: "rgba(17, 24, 39, 0.5)",
    zIndex: 9999,
  },
};

const ContactTableDatabase = ({
  IDBtype,
  active,
  handleClick,
  allCount,
  dataProperty,
  checkedItems,
  handleCheckedItem,
  userData,
  filteredData,
  fetchDataBase,
  isDatabasePage,
  sortDatabaseFirstNameDescending,
  sortDatabaseFirstNameAscending,
  sortDatabaseLastNameDescending,
  sortDatabaseLastNameAscending,
  sortDatabaseAddressDescending,
  sortDatabaseAddressAscending,
  sortDatabaseZipDescending,
  sortDatabaseZipAscending,
  handleCheckedAll,
  loading,
  isAllCheckActive,
  db2,
  db3,
  syncableContactDB,
  syncableWolkingListDB,
}) => {
  const [userId, setUserId] = useState("");
  const [firstname, setFirstname] = useState("");
  const [middle_initial, setMiddle_initial] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [canvasser_email, setCanvasser_email] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [marital_status, setMarital_status] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [individual_ethnic_categories, setIndividual_ethnic_categories] =
    useState("");
  const [broad_ethnic_groupings, setBroad_ethnic_groupings] = useState("");
  const [hispanic_country_origin, setHispanic_country_origin] = useState("");
  const [languages, setLanguages] = useState("");
  const [landline_telephone, setLandline_telephone] = useState("");
  const [household_primary_language, setHousehold_primary_language] =
    useState("");
  const [cell_phone, setCell_phone] = useState("");
  const [residence_address_line_1, setResidence_address_line_1] = useState("");
  const [residence_address__continued_, setResidence_address__continued_] =
    useState("");
  const [residence_city, setResidence_city] = useState("");
  const [residence_state, setResidence_state] = useState("");
  const [residence_zip, setResidence_zip] = useState("");
  const [education, setEducation] = useState("");
  const [occupation, setOccupation] = useState("");
  const [occupation_industry, setOccupation_industry] = useState("");
  const [presence_of_children_code, setPresence_of_children_code] =
    useState("");
  const [
    residence_household_gender_composition,
    setResidence_household_gender_composition,
  ] = useState("");
  const [opt_in, setOpt_in] = useState("");
  const token = localStorage.getItem("token");
  const [political_party, setPolitical_party] = useState("");
  const [national_voter_cluster, setNational_voter_cluster] = useState("");
  const [national_voter_subcluster, setNational_voter_subcluster] =
    useState("");
  const [voter_registration_status, setVoter_registration_status] =
    useState("");

  const [meetingTitle, setMeetingTitle] = React.useState(null);
  const [meetingStartDate, setMeetingStartDate] = React.useState(null);
  const [meetingOutcome, setMeetingOutcome] = React.useState(null);
  // const [meetingEndDate, setMeetingEndDate] = React.useState(null);
  const [meetingPost, setMeetingPost] = React.useState(null);
  const [meetingLoading, setMeetingLoading] = useState(false);
  const profileEmail = localStorage.getItem("urserMail");

  const [isFirstNameAscending, setIsFirstNameAscending] = useState(false);
  const [isLastNameAscending, setIsLastNameAscending] = useState(false);
  const [isAddressAscending, setIsAddressAscending] = useState(false);
  const [isZipCodeAscending, setIsZipCodeAscending] = useState(false);
  const [tracking_attempts, setTracking_attempts] = useState("");
  const [tracking_rating, setTracking_rating] = useState("");
  const [tracking_conversion, setTracking_conversion] = useState("");
  const [tracking_contacted, setTracking_contacted] = useState("false");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isMeetingModalOpen, setIsMeetingModalOpen] = React.useState(false);

  const [analyticsSource, setAnalyticsSource] = useState(null);
  const [firstTouch, setFirstTouch] = useState(null);
  const [lastTouch, setLastTouch] = useState(null);
  const [emailLastEng, setEmailLastEng] = useState(null);
  const [emailName, setEmailName] = useState(null);
  const [emailOpenDate, setLastEmailOpenDate] = useState(null);
  const ownerID = localStorage.getItem("ownerId");
  const currentTime = new Date();
  const formattedDate = currentTime.toLocaleString("sv-SE").replace(" ", "T");

  const handleEditClick = (user) => {
    setIsOpenModal(true);
    setUserId(user?.id);
    setFirstname(user?.properties?.firstname || "");
    setMiddle_initial(user?.properties?.middle_initial || "");
    setLastname(user?.properties?.lastname || "");
    setResidence_address_line_1(
      user?.properties?.residence_address_line_1 || ""
    );
    setAge(user?.properties?.age || "");
    setBroad_ethnic_groupings(user?.properties?.broad_ethnic_groupings || "");
    setCanvasser_email(user?.properties?.canvasser_email || "");
    setCell_phone(user?.properties?.cell_phone || "");
    setEducation(user?.properties?.education || "");
    setEmail(user?.properties?.email || "");
    setHispanic_country_origin(user?.properties?.hispanic_country_origin || "");
    setIndividual_ethnic_categories(
      user?.properties?.individual_ethnic_categories || ""
    );
    setLandline_telephone(user?.properties?.landline_telephone || "");
    setLanguages(user?.properties?.languages || "");
    setMarital_status(user?.properties?.marital_status || "");
    setGender(user?.properties?.gender || "");
    setOccupation(user?.properties?.occupation || "");
    setOccupation_industry(user?.properties?.occupation_industry || "");
    setResidence_address__continued_(
      user?.properties?.residence_address__continued_ || ""
    );
    setResidence_city(user?.properties?.residence_city || "");
    setResidence_household_gender_composition(
      user?.properties?.residence_household_gender_composition || ""
    );
    setResidence_state(user?.properties?.residence_state || "");
    setResidence_zip(user?.properties?.residence_zip || "");
    setOpt_in(user?.properties?.opt_in || "");
    setPolitical_party(user?.properties?.political_party || "");
    setNational_voter_cluster(user?.properties?.national_voter_cluster || "");
    setNational_voter_subcluster(
      user?.properties?.national_voter_subcluster || ""
    );
    setVoter_registration_status(
      user?.properties?.voter_registration_status || ""
    );

    setTracking_attempts(user?.properties?.tracking_attempts || "");
    setTracking_contacted(user?.properties?.tracking_contacted_voters || "");
    setTracking_rating(user?.properties?.tracking_rating || "");
    setTracking_conversion(user?.properties?.tracking_conversion || "");

    setAnalyticsSource(user?.properties?.hs_analytics_source || "");
    setFirstTouch(
      user?.properties?.hs_analytics_first_touch_converting_campaign || ""
    );
    setLastTouch(
      user?.properties?.hs_analytics_last_touch_converting_campaign || ""
    );
    setEmailLastEng(
      user?.properties?.hs_email_sends_since_last_engagement || ""
    );
    setEmailName(user?.properties?.hs_email_last_email_name || "");
    setLastEmailOpenDate(user?.properties?.hs_email_last_open_date || "");
  };

  const handleMeetingActivityLog = () => {
    setMeetingStartDate(formattedDate);
    // const endTime = new Date(currentTime.getTime() + 5 * 60000);
    setIsMeetingModalOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      const data = {
        id: userId,
        age: age || "",
        broad_ethnic_groupings: broad_ethnic_groupings || "",
        canvasser_email: canvasser_email || "",
        cell_phone: cell_phone || "",
        education: education || "",
        email: email || "",
        firstname: firstname || "",
        middle_initial: middle_initial || "",
        gender: gender || "",
        marital_status: marital_status || "",
        hispanic_country_origin: hispanic_country_origin || "",
        household_primary_language: household_primary_language || "",
        landline_telephone: landline_telephone || "",
        languages: languages || "",
        lastname: lastname || "",
        occupation: occupation || "",
        occupation_industry: occupation_industry || "",
        presence_of_children_code: presence_of_children_code || "",
        residence_address__continued_: residence_address__continued_ || "",
        residence_address_line_1: residence_address_line_1 || "",
        residence_city: residence_city || "",
        residence_household_gender_composition:
          residence_household_gender_composition || "",
        residence_state: residence_state || "",
        residence_zip: residence_zip || "",
        opt_in: opt_in || "",
        political_party: political_party || "",
        national_voter_cluster: national_voter_cluster || "",
        national_voter_subcluster: national_voter_subcluster || "",
        voter_registration_status: voter_registration_status || "",

        tracking_attempts: tracking_attempts || "",
        tracking_rating: tracking_rating || "",
        tracking_conversion: tracking_conversion || "",
        tracking_contacted_voters: tracking_contacted || "",

        hs_analytics_source: analyticsSource,
      };

      const storedSyncableContacts = await fetchDataFromIndexedDB(
        syncableContactDB,
        "syncableContactsStore"
      );
      const storedSyncableWalkingList = await fetchDataFromIndexedDB(
        syncableWolkingListDB,
        "syncableWalkingListStore"
      );

      if (navigator.onLine === false) {
        const updatedIndexData = {
          id: data?.id,
          properties: {
            ...data,
          },
        };
        delete updatedIndexData.properties.id;
        if (IDBtype === "contact") {
          if (storedSyncableContacts) {
            storeDataInIndexedDB(syncableContactDB, "syncableContactsStore", [
              ...storedSyncableContacts,
              updatedIndexData,
            ]);
            toast.success("Contact list offline updated successfully!");
          } else {
            storeDataInIndexedDB(syncableContactDB, "syncableContactsStore", [
              updatedIndexData,
            ]);
            toast.success("Contact list offline updated successfully!");
          }
        }
        if (IDBtype === "walking") {
          if (storedSyncableWalkingList) {
            storeDataInIndexedDB(
              syncableWolkingListDB,
              "syncableWalkingListStore",
              [...storedSyncableWalkingList, updatedIndexData]
            );
            toast.success("walking list offline updated successfully! ");
          } else {
            storeDataInIndexedDB(
              syncableWolkingListDB,
              "syncableWalkingListStore",
              [updatedIndexData]
            );
            toast.success("walking list offline updated successfully! ");
          }
        }
        await fetchDataBase(1);
        updateDataInIndexedDB(
          db3,
          "walkingListStore",
          data?.id,
          updatedIndexData
        );
        updateDataInIndexedDB(
          db2,
          "databaseContactStore",
          data?.id,
          updatedIndexData
        );
      } else {
        const res = await axios.put(`${REACT_APP_BASE_URL}/database`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.data) {
          toast.success("Contact updated successfully!");
          await fetchDataBase(1);
        }
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsSubmitLoading(false);
    }
  };
  const handleClose = (e) => {
    e.preventDefault();
  };

  const handleRatingChange = (e) => {
    setTracking_rating(parseInt(e.target.value));
  };

  const toggleFirstNameSortingOrder = () => {
    setIsFirstNameAscending((prevState) => !prevState);
    setIsLastNameAscending();
    setIsAddressAscending();
    setIsZipCodeAscending();
  };
  const toggleLastNameSortingOrder = () => {
    setIsLastNameAscending((prevState) => !prevState);
    setIsFirstNameAscending();
    setIsAddressAscending();
    setIsZipCodeAscending();
  };
  const toggleAddressSortingOrder = () => {
    setIsAddressAscending((prevState) => !prevState);
    setIsFirstNameAscending();
    setIsLastNameAscending();
    setIsZipCodeAscending();
  };
  const toggleZipCodeSortingOrder = () => {
    setIsZipCodeAscending((prevState) => !prevState);
    setIsFirstNameAscending();
    setIsLastNameAscending();
    setIsAddressAscending();
  };
  const handleMeetingLogSubmit = async (e) => {
    e.preventDefault();
    setMeetingLoading(true);
    try {
      if (
        userId &&
        meetingTitle?.trim()?.length > 0 &&
        meetingPost?.trim()?.length > 0 &&
        profileEmail &&
        ownerID &&
        meetingOutcome
      ) {
        const data = {
          contactId: userId,
          startTime: meetingStartDate
            ? new Date(meetingStartDate).toISOString()
            : new Date().toISOString(),
          endTime: formattedDate ? new Date(formattedDate).toISOString() : null,
          outcome: meetingOutcome || null,
          title: meetingTitle,
          body: meetingPost,
          hubspotOwnerId: ownerID,
          location: "Doorstep",
          attendees: [profileEmail],
        };
        await axios
          .post(`${REACT_APP_BASE_URL}/logMeeting`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            toast.success(response?.data?.message || "Meeting set!");
            setIsMeetingModalOpen(false);
            setMeetingStartDate("");
            // setMeetingEndDate("");
            setMeetingTitle("");
            setMeetingPost("");
            setMeetingOutcome("");
          })
          .catch((e) => {
            toast.error(e?.response?.data?.message || "Something went wrong");
          });
      } else {
        toast.error("Client: Fields are missing");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      toast.error(e?.message);
    } finally {
      setMeetingLoading(false);
    }
  };
  return (
    <div>
      {isAllCheckActive && (
        <p className="pl-5">
          All {active ? allCount : userData.length} rows on this page are
          selected.
          <button
            onClick={handleClick}
            className="text-troo-primary font-bold underline"
          >
            &nbsp; {active ? "No " : `Select all ${allCount} `}&nbsp;
          </button>
          Select records.
        </p>
      )}
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-troo-userbar">
                  <tr>
                    {isDatabasePage && (
                      <th
                        scope="col"
                        className="px-4 py-3 text-start text-xs text-troo-secondery font-outfit font-bold flex flex-col gap-2 "
                      >
                        <input
                          className="tableCheckbox"
                          type="checkbox"
                          checked={isAllCheckActive}
                          onClick={handleCheckedAll}
                        />
                      </th>
                    )}

                    <th
                      scope="col"
                      className="px-4 py-3 text-start text-xs text-troo-secondery font-outfit font-bold uppercase "
                    >
                      <div className="flex gap-1 items-center">
                        <p>First Name</p>
                        <button onClick={toggleFirstNameSortingOrder}>
                          {isFirstNameAscending ? (
                            <button onClick={sortDatabaseFirstNameDescending}>
                              <FaChevronUp className="h-4 w-4" />
                            </button>
                          ) : (
                            <button onClick={sortDatabaseFirstNameAscending}>
                              <FaChevronDown className="h-4 w-4" />
                            </button>
                          )}
                        </button>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-start text-xs text-troo-secondery font-outfit font-bold uppercase items-center"
                    >
                      <div className="flex gap-1 items-center">
                        <p> Last Name</p>
                        <button onClick={toggleLastNameSortingOrder}>
                          {isLastNameAscending ? (
                            <button onClick={sortDatabaseLastNameDescending}>
                              <FaChevronUp className="h-4 w-4" />
                            </button>
                          ) : (
                            <button onClick={sortDatabaseLastNameAscending}>
                              <FaChevronDown className="h-4 w-4" />
                            </button>
                          )}
                        </button>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-start text-xs text-troo-secondery font-outfit font-bold uppercase"
                    ></th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-start text-xs text-troo-secondery font-outfit font-bold uppercase"
                    ></th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      <div className="flex justify-center gap-2">
                        <p>Address</p>
                        <button onClick={toggleAddressSortingOrder}>
                          {isAddressAscending ? (
                            <button onClick={sortDatabaseAddressDescending}>
                              <FaChevronUp className="h-4 w-4" />
                            </button>
                          ) : (
                            <button onClick={sortDatabaseAddressAscending}>
                              <FaChevronDown className="h-4 w-4" />
                            </button>
                          )}
                        </button>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      <div className="flex justify-center gap-2">
                        <p>Zip Code</p>
                        <button onClick={toggleZipCodeSortingOrder}>
                          {isZipCodeAscending ? (
                            <button onClick={sortDatabaseZipDescending}>
                              <FaChevronUp className="h-4 w-4" />
                            </button>
                          ) : (
                            <button onClick={sortDatabaseZipAscending}>
                              <FaChevronDown className="h-4 w-4" />
                            </button>
                          )}
                        </button>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      <div className="flex justify-center gap-2">
                        <p>Political Party</p>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      <div className="flex justify-center gap-2">
                        <p>National Voter Cluster</p>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      <div className="flex justify-center gap-2">
                        <p>Email</p>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      Canvaser Email
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      Home Phone
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                    >
                      Opt-in
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-troo-primary/40">
                  {loading ? (
                    <div className="text-center flex justify-center items-center h-[50px]">
                      <SyncLoader color="#43524f" size={8} />
                    </div>
                  ) : (
                    <>
                      {filteredData?.map((e, i) => (
                        <tr
                          className="hover:bg-troo-primary/15 group/row text-troo-primary transition-all"
                          key={i}
                        >
                          {isDatabasePage && (
                            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-center">
                              <input
                                className="tableCheckbox"
                                type="checkbox"
                                checked={checkedItems.includes(e.id)}
                                onChange={() => handleCheckedItem(e.id)}
                              />
                            </td>
                          )}
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap">
                            <div className="text-xs flex gap-2 font-bold">
                              <p>{e?.properties.firstname}</p>
                            </div>
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap ">
                            <div className="text-xs flex gap-2 font-bold">
                              <p>{e?.properties.lastname}</p>
                            </div>
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap">
                            <Link
                              target="_blank"
                              to={`https://www.google.com/maps/search/${e?.properties?.residence_address_line_1},${e.properties.mailing_city}`}
                            >
                              <MdOutlineHome className="w-6 h-6 text-troo-secondery group-hover/row:text-black transition-all" />
                            </Link>
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap flex items-center gap-5">
                            <button
                              type="button"
                              onClick={() => handleEditClick(e)}
                              data-hs-overlay="#hs-basic-modal1"
                            >
                              <MdModeEdit className="w-6 h-6 text-troo-secondary group-hover/row:text-black transition-all" />
                            </button>
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap font-outfit text-xs font-normal text-center">
                            {e?.properties?.residence_address_line_1}
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap font-outfit text-xs font-normal text-center">
                            {e?.properties?.residence_zip}
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap text-xs font-outfit font-normal text-center">
                            {e?.properties.political_party}
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap text-xs font-outfit font-normal text-center">
                            {e?.properties.national_voter_cluster}
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap text-xs font-outfit font-normal text-center">
                            {e?.properties.email}
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap text-xs font-outfit font-normal text-center">
                            {e?.properties.canvasser_email}
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap text-xs font-outfit font-normal text-center">
                            {e?.properties?.cell_phone}
                          </td>
                          <td className="px-4 md:py-4 py-2 whitespace-nowrap text-xs font-outfit font-normal text-center">
                            {e?.properties?.opt_in === "true" ? "Yes" : "No"}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {userId && (
        <div
          id="hs-basic-modal1"
          className="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none"
        >
          <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all xl:max-w-6xl lg:max-w-5xl md:max-w-3xl max-w-2xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">

            <div className="flex w-full flex-col bg-white border shadow-sm rounded-xl pointer-events-auto ">
              <div className="p-4 overflow-y-auto">
                <h2 className="flex justify-center mb-8">Contact Details</h2>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    <EditInputField
                      label="First Name"
                      name="firstname"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                    <EditInputField
                      label="Middle Name"
                      name="middle_initial"
                      value={middle_initial}
                      onChange={(e) => setMiddle_initial(e.target.value)}
                    />
                    <EditInputField
                      label="Last Name"
                      name="lastname"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                  <div className="hs-accordion-group w-full flex flex-col items-start gap-4">
                    <Accordian id="one" title="Contact Information">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-x-5 gap-y-2 p-5 border border-troo-primary/15">
                        <EditInputField
                          label="Education"
                          name="education"
                          value={education}
                          onChange={(e) => setEducation(e.target.value)}
                        />
                        <EditInputField
                          label="Gender"
                          name="gender"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          type={
                            dataProperty?.find(
                              (data) => data?.name === "gender"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) => data?.name === "gender"
                            )?.options
                          }
                        />
                        <EditInputField
                          label="Occupation"
                          name="occupation"
                          value={occupation}
                          onChange={(e) => setOccupation(e.target.value)}
                        />
                        <EditInputField
                          label="Email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <EditInputField
                          label="Age"
                          name="age"
                          value={age}
                          onChange={(e) => setAge(e.target.value)}
                        />
                        <EditInputField
                          label="Occupation Industry"
                          name="occupation_industry"
                          value={occupation_industry}
                          onChange={(e) =>
                            setOccupation_industry(e.target.value)
                          }
                          type={
                            dataProperty?.find(
                              (data) => data?.name === "occupation_industry"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) => data?.name === "occupation_industry"
                            )?.options
                          }
                        />
                        <EditInputField
                          label="Cell Phone"
                          name="cell_phone"
                          value={cell_phone}
                          onChange={(e) => setCell_phone(e.target.value)}
                        />
                        <EditInputField
                          label="Marital Status"
                          name="marital_status"
                          value={marital_status}
                          onChange={(e) => setMarital_status(e.target.value)}
                          type={
                            dataProperty?.find(
                              (data) => data?.name === "marital_status"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) => data?.name === "marital_status"
                            )?.options
                          }
                        />
                      </div>
                    </Accordian>
                    <Accordian id="two" title="Address Information">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-x-5 gap-y-2 p-5 border border-troo-primary/15">
                        <EditInputField
                          label=" Residence Address Line 1"
                          name="residence_address_line_1"
                          value={residence_address_line_1}
                          onChange={(e) =>
                            setResidence_address_line_1(e.target.value)
                          }
                        />
                        <EditInputField
                          label="  Residence Address Continued"
                          name="residence_address__continued_"
                          value={residence_address__continued_}
                          onChange={(e) =>
                            setResidence_address__continued_(e.target.value)
                          }
                        />
                        <EditInputField
                          label="Residence City"
                          name="residence_city"
                          value={residence_city}
                          onChange={(e) => setResidence_city(e.target.value)}
                        />
                        <EditInputField
                          label=" Residence State"
                          name="residence_state"
                          value={residence_state}
                          onChange={(e) => setResidence_state(e.target.value)}
                        />
                        <EditInputField
                          label=" Residence Zip"
                          name="residence_zip"
                          value={residence_zip}
                          onChange={(e) => setResidence_zip(e.target.value)}
                        />
                        <EditInputField
                          label=" Landline Telephone"
                          name="landline_telephone"
                          value={landline_telephone}
                          onChange={(e) =>
                            setLandline_telephone(e.target.value)
                          }
                        />
                      </div>
                    </Accordian>
                    <Accordian id="three" title="Demographic Information">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-x-5 gap-y-2 p-5 border border-troo-primary/15">
                        <EditInputField
                          label="Individual Ethnic Categories"
                          name="individual_ethnic_categories"
                          value={individual_ethnic_categories}
                          onChange={(e) =>
                            setIndividual_ethnic_categories(e.target.value)
                          }
                          type={
                            dataProperty?.find(
                              (data) =>
                                data?.name === "individual_ethnic_categories"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) =>
                                data?.name === "individual_ethnic_categories"
                            )?.options
                          }
                        />
                        <EditInputField
                          label="Broad Ethnic Groupings"
                          name="broad_ethnic_groupings"
                          value={broad_ethnic_groupings}
                          onChange={(e) =>
                            setBroad_ethnic_groupings(e.target.value)
                          }
                          type={
                            dataProperty?.find(
                              (data) => data?.name === "broad_ethnic_groupings"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) => data?.name === "broad_ethnic_groupings"
                            )?.options
                          }
                        />
                        <EditInputField
                          label="Hispanic Country Origin"
                          name="hispanic_country_origin"
                          value={hispanic_country_origin}
                          onChange={(e) =>
                            setHispanic_country_origin(e.target.value)
                          }
                        />
                        <EditInputField
                          label="Languages"
                          name="languages"
                          value={languages}
                          onChange={(e) => setLanguages(e.target.value)}
                          type={
                            dataProperty?.find(
                              (data) => data?.name === "languages"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) => data?.name === "languages"
                            )?.options
                          }
                        />

                        <EditInputField
                          label="  Household Primary Language"
                          name="household_primary_language"
                          value={household_primary_language}
                          onChange={(e) =>
                            setHousehold_primary_language(e.target.value)
                          }
                          type={
                            dataProperty?.find(
                              (data) =>
                                data?.name === "household_primary_language"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) =>
                                data?.name === "household_primary_language"
                            )?.options
                          }
                        />
                      </div>
                    </Accordian>
                    <Accordian id="four" title="Canvassing Information">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-x-5 gap-y-2 p-5 border border-troo-primary/15">
                        <EditInputField
                          label="Presence of Children Code"
                          name="presence_of_children_code"
                          value={presence_of_children_code}
                          onChange={(e) =>
                            setPresence_of_children_code(e.target.value)
                          }
                          type={
                            dataProperty?.find(
                              (data) =>
                                data?.name === "presence_of_children_code"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) =>
                                data?.name === "presence_of_children_code"
                            )?.options
                          }
                        />
                        <EditInputField
                          label="Residence Household Gender Composition"
                          name="residence_household_gender_composition"
                          value={residence_household_gender_composition}
                          onChange={(e) =>
                            setResidence_household_gender_composition(
                              e.target.value
                            )
                          }
                          type={
                            dataProperty?.find(
                              (data) =>
                                data?.name ===
                                "residence_household_gender_composition"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) =>
                                data?.name ===
                                "residence_household_gender_composition"
                            )?.options
                          }
                        />
                        <EditInputField
                          label="Political Party"
                          name="political_party"
                          value={political_party}
                          onChange={(e) => setPolitical_party(e.target.value)}
                          type={
                            dataProperty?.find(
                              (data) => data?.name === "political_party"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) => data?.name === "political_party"
                            )?.options
                          }
                        />
                        <EditInputField
                          label="National Voter cluster"
                          name="national_voter_cluster"
                          value={national_voter_cluster}
                          onChange={(e) =>
                            setNational_voter_cluster(e.target.value)
                          }
                          type={
                            dataProperty?.find(
                              (data) => data?.name === "national_voter_cluster"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) => data?.name === "national_voter_cluster"
                            )?.options
                          }
                        />
                        <EditInputField
                          label="National Voter Subcluster"
                          name="national_voter_subcluster"
                          value={national_voter_subcluster}
                          onChange={(e) =>
                            setNational_voter_subcluster(e.target.value)
                          }
                          type={
                            dataProperty?.find(
                              (data) =>
                                data?.name === "national_voter_subcluster"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) =>
                                data?.name === "national_voter_subcluster"
                            )?.options
                          }
                        />
                        <EditInputField
                          label="Voter Registration Status"
                          name="voter_registration_status"
                          value={voter_registration_status}
                          onChange={(e) =>
                            setVoter_registration_status(e.target.value)
                          }
                          type={
                            dataProperty?.find(
                              (data) =>
                                data?.name === "voter_registration_status"
                            )?.fieldType
                          }
                          options={
                            dataProperty?.find(
                              (data) =>
                                data?.name === "voter_registration_status"
                            )?.options
                          }
                        />
                      </div>
                    </Accordian>
                    <Accordian id="five" title="Marketing Information">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-x-5 gap-y-2 p-5 border border-troo-primary/15">
                        <EditInputField
                          type="select"
                          label="Original Source"
                          name="originalSource"
                          value={analyticsSource}
                          options={ANALYTICS_SOURCE}
                          // disabled
                          onChange={(e) => setAnalyticsSource(e.target.value)}
                        />
                        <EditInputField
                          type="text"
                          label="First Touch Converting Campaign"
                          name="hs_analytics_first_touch_converting_campaign"
                          value={firstTouch}
                          disabled
                          onChange={(e) => setFirstTouch(e.target.value)}
                        />
                        <EditInputField
                          type="text"
                          label="Last Touch Converting Campaign"
                          name="hs_analytics_last_touch_converting_campaign"
                          value={lastTouch}
                          disabled
                          onChange={(e) => setLastTouch(e.target.value)}
                        />
                        <EditInputField
                          type="number"
                          label="Sends Since Last Engagement"
                          name="hs_email_sends_since_last_engagement"
                          value={emailLastEng}
                          disabled
                          onChange={(e) => setEmailLastEng(e.target.value)}
                        />
                        <EditInputField
                          type="text"
                          label="Last Marketing Email Name"
                          name="hs_email_last_email_name"
                          value={emailName}
                          disabled
                          onChange={(e) => setEmailName(e.target.value)}
                        />
                        <EditInputField
                          type="date"
                          label="Last Marketing Email Open Date"
                          name="hs_email_last_open_date"
                          value={emailOpenDate}
                          disabled
                          onChange={(e) => setLastEmailOpenDate(e.target.value)}
                        />
                      </div>
                    </Accordian>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-4">
                    <CommonSurveySelection
                      contactEmail={email}
                      userId={userId}
                    />
                    <div className="">
                      <label className="block text-gray-500 font-bold  mb-2">
                        Opt-in
                      </label>
                      <div className="relative inline-flex items-center">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setOpt_in(e.target.checked ? "true" : "false")
                          }
                          checked={opt_in === "true"}
                          id="optin"
                          className="onOffCheck peer"
                        />
                        <label
                          htmlFor="optin"
                          className="absolute right-3 text-xs font-semibold text-troo-primary peer-checked:hidden block"
                        >
                          NO
                        </label>
                        <label
                          htmlFor="optin"
                          className="absolute left-3 text-xs font-semibold text-troo-white peer-checked:block hidden"
                        >
                          YES
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <label className="block text-gray-500 font-bold  mb-2">
                        Door Knocked?
                      </label>
                      <div className="relative inline-flex items-center">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setTracking_attempts(
                              e.target.checked ? "true" : "false"
                            )
                          }
                          checked={tracking_attempts === "true"}
                          id="attempted"
                          className="onOffCheck peer"
                        />
                        <label
                          htmlFor="attempted"
                          className="absolute right-3 text-xs font-semibold text-troo-primary peer-checked:hidden block"
                        >
                          NO
                        </label>
                        <label
                          htmlFor="attempted"
                          className="absolute left-3 text-xs font-semibold text-troo-white peer-checked:block hidden"
                        >
                          YES
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <label className="block text-gray-500 font-bold  mb-2">
                        Door Answered?
                      </label>
                      <div className="relative inline-flex items-center">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setTracking_contacted(
                              e.target.checked ? "true" : "false"
                            )
                          }
                          checked={tracking_contacted === "true"}
                          id="contacted"
                          className="onOffCheck peer"
                        />
                        <label
                          htmlFor="contacted"
                          className="absolute right-3 text-xs font-semibold text-troo-primary peer-checked:hidden block"
                        >
                          NO
                        </label>
                        <label
                          htmlFor="contacted"
                          className="absolute left-3 text-xs font-semibold text-troo-white peer-checked:block hidden"
                        >
                          YES
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <label className="block text-gray-500 font-bold  mb-2">
                        Interested In 1:1 With An Organizer?
                      </label>
                      <div className="relative inline-flex items-center">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setTracking_conversion(
                              e.target.checked ? "true" : "false"
                            );
                            if (e.target.checked) {
                              setTracking_attempts("true");
                            }
                          }}
                          checked={tracking_conversion === "true"}
                          id="converted"
                          className="onOffCheck peer"
                        />
                        <label
                          htmlFor="converted"
                          className="absolute right-3 text-xs font-semibold text-troo-primary peer-checked:hidden block"
                        >
                          NO
                        </label>
                        <label
                          htmlFor="converted"
                          className="absolute left-3 text-xs font-semibold text-troo-white peer-checked:block hidden"
                        >
                          YES
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <label className="block text-gray-500 font-bold  mb-2">
                        Tracking Rating
                      </label>
                      <div>
                        {[...Array(5)].map((_, index) => (
                          <span
                            key={index}
                            onClick={() => setTracking_rating(index + 1)}
                            className={`cursor-pointer text-3xl hover:text-yellow-500 ${
                              index < tracking_rating
                                ? "text-yellow-500"
                                : "text-gray-300"
                            }`}
                          >
                            {index < tracking_rating ? "★" : "☆"}
                          </span>
                        ))}
                      </div>
                      <input
                        type="hidden"
                        name="tracking_rating"
                        value={tracking_rating}
                        onChange={handleRatingChange}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center gap-5 mt-6">
                    <button
                      type="submit"
                      disabled={isSubmitLoading}
                      className="shadow bg-troo-primary text-white font-bold py-2 px-4 rounded flex items-center gap-3"
                    >
                      {isSubmitLoading && <ButtonLoader />}
                      Save
                    </button>
                    <button
                      type="button"
                      className="bg-gray-200 py-2 px-6 rounded"
                      onClick={(e) => handleClose(e)}
                      data-hs-overlay="#hs-basic-modal1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="bg-gray-200 md:w-auto w-full py-2 px-6 rounded"
                      onClick={handleMeetingActivityLog}
                    >
                      Meeting Activity Log
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMeetingModalOpen && (
        <CustomModal
          open={isMeetingModalOpen}
          onClose={() => setIsMeetingModalOpen(false)}
          panelclassName="xl:max-w-7xl lg:max-w-5xl md:max-w-3xl max-w-3xl w-full"
        >
          <h2 className="flex justify-center mb-8">Canvassing Activity Log</h2>
          <form onSubmit={handleMeetingLogSubmit} noValidate>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <EditInputField
                type="text"
                label="Name"
                name="meetingTitle"
                value={meetingTitle}
                onChange={(e) => setMeetingTitle(e.target.value)}
              />
              <EditInputField
                type="datetime-local"
                label="Meeting Start Date"
                name="meetingStartDate"
                value={meetingStartDate}
                onChange={(e) => setMeetingStartDate(e.target.value)}
              />
              {/* <EditInputField
                type="datetime-local"
                label="Meeting End Date"
                name="meetingEndDate"
                value={meetingEndDate}
                onChange={(e) => setMeetingEndDate(e.target.value)}
              /> */}
              <EditInputField
                type="select"
                label="Outcome"
                name="meetingOutcome"
                value={meetingOutcome}
                options={MEETING_OUTCOME}
                onChange={(e) => setMeetingOutcome(e.target.value)}
              />
            </div>
            <EditInputField
              label="Conversation Notes"
              name="meetingPost"
              type="textarea"
              rows={10}
              value={meetingPost}
              onChange={(e) => setMeetingPost(e.target.value)}
            />
            <div className="flex items-center gap-5">
              <button
                type="submit"
                className="shadow bg-troo-primary text-white font-bold py-2 px-6 rounded flex items-center justify-center gap-3"
                disabled={meetingLoading}
              >
                {meetingLoading && <ButtonLoader />}
                Save
              </button>
              <button
                type="button"
                className="bg-gray-200 py-2 px-4 rounded"
                onClick={(e) => setIsMeetingModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </CustomModal>
      )}
    </div>
  );
};

export default ContactTableDatabase;
