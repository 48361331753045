import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BiQuestionMark } from "react-icons/bi";
import { MdUpload } from "react-icons/md";
import { Link } from "react-router-dom";
import { customStyles } from "./CanvassMap";
import Modal from "react-modal";
import Papa from "papaparse";
import { SyncLoader } from "react-spinners";
import toast from "react-hot-toast";

const { REACT_APP_BASE_URL } = process.env;

const ImportFile = () => {
  const token = localStorage.getItem("token");
  const [file, setFile] = useState(null);
  const [importData, setImportData] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const subtitleRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);

  const [modelPreview, setModelPreview] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  function openModal() {
    setIsOpen(true);
    setModelPreview(true);
  }
  function afterOpenModal() {
    if (subtitleRef.current) {
      subtitleRef.current.style.color = "#43182780";
    }
  }
  function closeModal() {
    setIsOpen(false);
    setModelPreview(false);
  }

  const handleFileUpload = (e) => {
    openModal();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      openModal();
    } else {
    }
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        setParsedData(results.data);
        setTableRows(rowsArray[0]);
        setValues(valuesArray);
        setLoading(false);
      },
    });
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `${REACT_APP_BASE_URL}/user/importContacts`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data) {
        toast.success("Data upload Successfully");
        setFile(null);
      }
      console.log("Upload successful:", response.data);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  const fetchImportData = async () => {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/user/getimportContacts`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.data) {
      setImportData(response?.data);
    }
  };
  const handleError = (e) => {
    openModal();
    setErrorList(e.results);
  };
  useEffect(() => {
    if (navigator.onLine) {
      fetchImportData();
    }
  }, []);
  const handleSubmitFile = async (e) => {
    e.preventDefault();
  };
  const handleConflictsButtonClick = () => {
    if (fileInputRef1.current) {
      fileInputRef1.current.click();
    } else if (fileInputRef2.current) {
      fileInputRef2.current.click();
    }
  };

  const ImportFileUrl =
    "https://docs.google.com/spreadsheets/d/1CieE8pHl-b6utOtbV4nuyoYmxHYi4qtytcOu5If__3w/edit#gid=0";

  return (
    <div className="bg-troo-userbar h-screen flex flex-col gap-8">
      <div className="py-3 border-b-2 w-full md:px-12 px-4 flex flex-col md:flex-row justify-end gap-5 ">
        <div className="flex gap-5 items-center flex-wrap">
          <input
            type="file"
            className="csv-form-control"
            id="file"
            accept=".csv"
            onChange={handleFileUpload}
            style={{ display: "none" }}
            ref={fileInputRef1}
          />

          <label htmlFor="file" className="sr-only">
            Upload File
          </label>
          <button
            onClick={handleConflictsButtonClick}
            className="px-3 py-2 bg-troo-secondery flex gap-1 uppercase items-center text-troo-white rounded"
          >
            Select file
          </button>
          <button
            onClick={handleUpload}
            className="px-3 py-2 bg-troo-primary flex gap-1 uppercase items-center text-troo-white rounded"
            disabled={!file}
          >
            <MdUpload />
            Start Import
          </button>
          <Link
            className="px-3 py-2 bg-troo-permissionButton flex gap-1 uppercase items-center text-troo-white rounded"
            to={ImportFileUrl}
            target="_blank"
          >
            Sample Contact File
          </Link>
        </div>
      </div>

      {file && (
        <Modal
          isOpen={modelPreview}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {loading ? (
            <div className="text-center flex flex-col  justify-center items-center ">
              <div className="mb-10">
                <SyncLoader color="#43524f" size={8} />
              </div>
              <div>
                <p>Large data can take time... </p>
                <button
                  className="px-3 py-2 bg-troo-primary flex gap-1 uppercase items-center text-troo-white rounded"
                  onClick={closeModal}
                >
                  Cancel Previewing?
                </button>
              </div>
            </div>
          ) : (
            <div>
              <h3 className="flex justify-center mb-5">Preview Import</h3>
              <div className="flex flex-col max-h-[800px]">
                <div className="overflow-x-auto">
                  <div className="min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-[#F1F1F1]">
                          <tr>
                            {tableRows.map((rows, index) => {
                              return (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-start text-xs text-troo-secondery font-outfit font-bold uppercase"
                                  key={index}
                                >
                                  {rows}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                          {values.slice(0, 20).map((value, index) => {
                            return (
                              <tr
                                className=" dark:hover:bg-gray-700 group/row hover:text-black transition-all bg-troo-white"
                                key={index}
                              >
                                {value.map((val, i) => {
                                  return (
                                    <td
                                      className="px-4 py-4 whitespace-nowrap text-xs font-outfit font-normal text-start"
                                      key={i}
                                    >
                                      {val}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-5">
                <button
                  className="px-3 py-2 bg-troo-primary flex gap-1 uppercase items-center text-troo-white rounded"
                  onClick={closeModal}
                >
                  Close Preview
                </button>
              </div>
            </div>
          )}
        </Modal>
      )}

      <div className="w-full md:px-12 px-4">
        <div className="bg-troo-white md:p-8 p-3 flex flex-col gap-3 rounded">
          <div className="flex items-center gap-5">
            <h1>Import Data</h1>
            <div className="w-6 h-6 bg-troo-secondery text-troo-white rounded-full flex justify-center items-center">
              <BiQuestionMark />
            </div>
          </div>
          <h4 className="text-troo-secondery">
            Upload only your .csv file. You will require certain address fields
            for geomapping of contact addresses.
          </h4>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-troo-primary">
                  <thead className="bg-troo-primary text-troo-white">
                    <tr>
                      <th
                        scope="col"
                        className="px-5 py-3 text-start text-xs font-outfit font-bold uppercase"
                      >
                        Name
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs font-outfit font-bold uppercase"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs font-outfit font-bold uppercase"
                      >
                        Errors
                      </th>
                      {/* <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs font-outfit font-bold uppercase"
                      >
                        Imported File
                      </th> */}

                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs font-outfit font-bold uppercase"
                      >
                        Date
                      </th>
                    </tr>
                  </thead>
                  {importData.length > 0 && (
                    <tbody className="divide-y divide-gray-200 dark:divide-troo-primary/50">
                      {importData?.map((e, i) => (
                        <tr
                          className=" dark:hover:bg-troo-primary/10 group/row hover:text-troo-primary transition-all bg-troo-white"
                          key={i}
                        >
                          <td className="px-4 py-4 whitespace-nowrap text-xs font-outfit font-normal text-start">
                            {e?.importName}
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap text-xs font-outfit font-normal text-center">
                            {e?.state}
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap text-xs font-outfit font-normal text-center">
                            {e?.error_report === "No Errors" ? (
                              <p>No Errors</p>
                            ) : (
                              <button
                                onClick={() => handleError(e.error_report)}
                                className="text-blue-700 underline"
                              >
                                {e.error_report.results.length}
                              </button>
                            )}
                          </td>
                          {/* <td className="px-4 py-4 whitespace-nowrap text-xs  font-outfit font-normal text-center">
                            <a
                              className="text-blue-700 underline"
                              href={e?.imported_file}
                              download
                            >
                              Imported file
                            </a>
                          </td> */}
                          <td className="px-4 py-4 whitespace-nowrap text-xs font-outfit font-normal text-center">
                            {e?.createdAt}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          {importData.length === 0 && (
            <div className="bg-troo-white min-h-[500px] flex flex-col gap-4 items-center justify-end p-3 md:p-8">
              <h3 className="text-troo-secondery">Import your Data </h3>
              <h5>
                Hello and welcome to Canvasser! Choose an option below to begin
                importing your contact file!
              </h5>
              <form
                className="mb-12 flex gap-3 flex-col"
                onSubmit={handleSubmitFile}
              >
                <label htmlFor="fileInput" className="sr-only">
                  Upload contact file
                </label>
                <input
                  type="file"
                  className="csv-form-control"
                  id="file"
                  accept=".csv"
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  ref={fileInputRef2}
                />

                <label htmlFor="file" className="sr-only">
                  Upload File
                </label>
                <button
                  onClick={handleConflictsButtonClick}
                  className="px-3 py-2 bg-troo-secondery flex gap-1 uppercase justify-center items-center text-troo-white rounded"
                >
                  Select file
                </button>
                <div className="flex justify-center">
                  <button
                    onClick={handleUpload}
                    className="px-3 py-2 bg-troo-primary flex justify-between  gap-1 uppercase items-center text-troo-white rounded"
                    disabled={!file}
                  >
                    <MdUpload />
                    Start Import
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      {errorList && (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="max-h-[600px]">
            <h3 className=" font-bold uppercase flex justify-center mb-4">
              Error Details
            </h3>
            <div className="flex flex-col ">
              <div className="overflow-x-auto">
                <div className="min-w-full inline-block align-middle">
                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-[#F1F1F1]">
                        <tr>
                          <th
                            scope="col"
                            className="px-5 py-3 text-start text-xs text-troo-secondery font-outfit font-bold uppercase"
                          >
                            Import Error
                          </th>

                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                          >
                            Column
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                          >
                            Row
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                          >
                            Invalid Value
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {errorList?.map((e, i) => (
                          <tr
                            className=" dark:hover:bg-gray-700 group/row hover:text-black transition-all bg-troo-white"
                            key={i}
                          >
                            <td className="px-4 py-4 whitespace-nowrap text-xs font-outfit font-normal text-start">
                              {e?.errorType}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap text-xs font-outfit font-normal text-center">
                              {e?.knownColumnNumber}
                            </td>

                            <td className="px-4 py-4 whitespace-nowrap text-xs font-outfit font-normal text-center">
                              {e?.sourceData?.lineNumber}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap text-xs font-outfit font-normal text-center">
                              {e?.invalidValue}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ImportFile;
