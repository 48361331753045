import React, { useEffect } from "react";
import toast from "react-hot-toast";
const HUBSPOT_PORTAL_ID = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
const FORM_ID = process.env.REACT_APP_HUBSPOT_FORM_ID;
const AddContactHubspotForm = ({ data, closeModal }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: HUBSPOT_PORTAL_ID,
          formId: FORM_ID,
          target: "#hubspotForm",
          onFormReady: function ($form) {
            const fields = {
              l2_household_id: data?.l2Id,
              residence_state: data?.residence_state,
              residence_zip: data?.residence_zip,
            };

            setTimeout(function () {
              for (const [field, value] of Object.entries(fields)) {
                const input = $form.querySelector(`input[name="${field}"]`);
                if (input) {
                  input.value = value;
                } else {
                  console.warn(`Field ${field} not found in the form.`);
                }
              }
            }, 1000);
          },
          onFormSubmitted: function () {
            toast.success("Contact added successfully!");
            closeModal();
          },
        });
      }
    };
    document.body.appendChild(script);
  }, []);

  return <div id="hubspotForm" className="w-full"></div>;
};

export default AddContactHubspotForm;
