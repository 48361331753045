import doc1 from "../images/doc1.svg"
import doc2 from "../images/doc2.svg"
import doc3 from "../images/doc3.svg"
import doc4 from "../images/doc4.svg"
import doc5 from "../images/doc5.svg"
import doc6 from "../images/doc6.svg"
import doc7 from "../images/doc7.svg"
import doc8 from "../images/doc8.svg"


export const DocumentsData = [ 
    {
        img : doc1,
        name : "Getting Started with Canvassing",
        para : "Setting up and customizing your dashboard"
    },
    {
        img : doc2,
        name : "Canvassing Dashboard",
        para : "Understand and optimize your dashboard to help you unlock the power of your team"
    },
    {
        img : doc3,
        name : "Canvassing Lists",
        para : "Understand how to  create Canvassing Lists"
    },
    {
        img : doc4,
        name : "Canvassing Leader",
        para : "One platform to manage your entire organization"
    },
    {
        img : doc5,
        name : "Contact Database Management",
        para : "All you need now know about your contact data within the Canvassing platform"
    },
    {
        img : doc6,
        name : "Integrations with Canvassing",
        para : "Learn about Canvassing's integration "
    },
    {
        img : doc7,
        name : "Data Privacy & Protection",
        para : "A guide to GDPR compliance."
    },
    {
        img : doc8,
        name : "FAQ",
        para : "Find the answers to our most frequently asked questions"
    },
]