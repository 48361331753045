import React from "react";
import { Pie, PieChart, ResponsiveContainer } from "recharts";

const PiChartComponent = () => {
  const data01 = [
    {
      name: "Group A",
      value: 100,
    },
    {
      name: "Group B",
      value: 50,
    },
    {
      name: "Group C",
      value: 50,
    },
    {
      name: "Group D",
      value: 500,
    },
  ];
  return (
    <div style={{ width: '100%', height: '250px' }}>
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data01}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#5B1E68"
        />
      </PieChart>
    </ResponsiveContainer>
  </div>
  );
};

export default PiChartComponent;
