import React from "react";
import { FaDesktop, FaMobileAlt } from "react-icons/fa";
import { MdTour } from "react-icons/md";
import { MdLockOutline } from "react-icons/md";

const ActionPriview = () => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var currentDate = new Date();
  var monthIndex = currentDate.getMonth();
  var monthName = monthNames[monthIndex];
  const currentDay = currentDate.getDate();
  return (
    <div className="bg-troo-white rounded-lg">
      <div className="flex justify-between gap-5 p-5 border-b-2">
        <h4>Preview</h4>
        <div className="flex items-center gap-5">
          <FaDesktop className="w-6 h-6" />
          <FaMobileAlt className="w-6 h-6" />
        </div>
      </div>
      <div className="p-8 flex flex-col  gap-3">
        <div className="flex flex-wrap gap-4 items-end">
          <div className="shadow-xl">
            <div className="py-1 px-5 bg-[#F3425F] font-semibold text-center uppercase text-troo-white rounded-t-lg">
              {monthName}
            </div>
            <div className="py-2 px-5 font-semibold text-center bg-troo-white text-5xl">
              {currentDay}
            </div>
          </div>
          <div>
            <div className="flex items-center gap-3 rounded px-5 py-1 bg-[#D7D9DE] font-semibold font-outfit">
              Attending
            </div>
          </div>
          <div>
            <div className="flex items-center gap-3 rounded px-5 py-1 bg-[#D7D9DE] font-semibold font-outfit">
              Not Attending
            </div>
          </div>
        </div>
        <h5 className="text-[#F3425F] font-semibold ">
          Nov 27, 2023 9:00PM - Nov 29, 2023 10:00PM CST
        </h5>
        <h2 className="text-troo-secondery">Action name</h2>
        <div className="mt-8 flex flex-col gap-6 p-4 shadow-lg">
          <h3>Details</h3>
          <div className="flex items-center gap-3 font-medium font-outfit">
            <MdLockOutline className="w-6 h-6 text-troo-secondery" /> Action is
            open to all members
          </div>
          <div className="flex items-center gap-3 font-medium font-outfit">
            <MdTour className="w-6 h-6 text-troo-secondery" /> Effort - Canvass
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionPriview;
