import React from 'react'
import CustomModal from './CustomModal'
import ButtonLoader from './ButtonLoader';
import { MdClose } from 'react-icons/md';

const SidebarUnlockModal = ({ open, onClose, handleSubmit, isLoading, setPassword }) => {
  const handleScroll = (event) => {
    event.preventDefault();
    event.target.blur()
  };
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      panelclassName="md:max-w-xl max-w-2xl sm:w-full"
      zIndexclassName="z-[99999]"
    >
      <div className='flex w-full items-center justify-between mb-5 gap-5 flex-wrap'>
        <h4 className="flex justify-center font-bold">ENTER CODE TO UNLOCK</h4>
        <button className='text-xl bg-troo-primary/10 p-2 rounded-xl text-troo-primary' onClick={() => {
          onClose()
          setPassword('')
        }}>
          <MdClose />
        </button>
      </div>
      <form className="w-full " onSubmit={handleSubmit}>
        <div className="md:flex md:items-center mb-6">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-3
              px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white text-base focus:border-purple-500"
            placeholder="Enter code here.."
            id="inline-full-name"
            type="number"
            required
            onWheel={handleScroll}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="flex justify-end items-center gap-x-2">
          <button
            type="submit"
            className="shadow bg-troo-primary text-white font-bold py-3 px-6 rounded-lg flex text-sm items-center justify-center gap-3"
            disabled={isLoading}
          >
            {isLoading && (
              <ButtonLoader />
            )}
            SUBMIT
          </button>
        </div>
      </form>
    </CustomModal>
  )
}

export default SidebarUnlockModal