import React, { useEffect, useRef, useState } from "react";
import filterIcon from "../images/filterIcon.png";
import ContactTableDatabase from "./ContactTableDatabase";
import axios from "axios";
import SearchInputField from "./SearchInputField";
import {
  createDatabase,
  storeDataInIndexedDB,
  fetchDataFromIndexedDB,
} from "../Hooks/IndexedDBUtils";
import toast from "react-hot-toast";
import Select from "react-select";
import ButtonLoader from "./ButtonLoader";
const { REACT_APP_BASE_URL } = process.env;
export const syncableContactDB = createDatabase(
  "syncableContactDB",
  "syncableContactsStore"
);

const DatabaseComponent = () => {
  const [activeTab, setActiveTab] = useState("contacts");
  const [userData, setUserData] = useState([]);
  const [hideLoading, setHideLoading] = useState(false);
  const [searchFirstName, setSearchFirstName] = useState("");
  const [searchLastName, setSearchLastName] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchZip, setSearchZip] = useState("");
  const [searchAge, setSearchAge] = useState("");
  const [searchState_income_decile, setState_income_decile] = useState("");
  const [searchNational_voter_cluster, setSearchNational_voter_cluster] =
    useState("");
  const [searchCensus_block, setSearchCensus_block] = useState("");
  const [searchPresence_of_children_code, setSearchpresence_of_children_code] =
    useState();
  const [filteredData, setFilteredData] = useState(userData);
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(1);
  const [filterPage, setFilterPage] = useState(1);
  const limit = 100;
  const [loading, setLoading] = useState(true);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isAllCheckActive, setAllCheckActive] = useState(false);
  const db2 = createDatabase("databaseContactDB", "databaseContactStore");
  const [dataProperty, setDataproperty] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ id: "", email: "" });
  const [selectedUser2, setSelectedUser2] = useState({ id: "", email: "" });
  const [allCityOptions, setAllCityOptions] = useState([]);
  const [allCount, setAllCount] = useState("");
  const currentUrlRef = useRef(window.location.href);
  const [active, setActive] = useState(false);
  const checkedItemListRef = useRef([]);
  const checkedItemListFilterRef = useRef([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [hideAssignbtn, setHideAssignbtn] = useState(true);
  const [assignError, setAssignError] = useState(false);
  const [listMemberData, setListMemberData] = useState([]);
  const [selectedMemberList, setSelectedMemberList] = useState("");

  useEffect(() => {
    const removeFilterPayload = () => {
      localStorage.removeItem("filterPayload");
    };
    if (currentUrlRef.current !== window.location.href) {
      removeFilterPayload();
    }
    currentUrlRef.current = window.location.href;
    return removeFilterPayload;
  }, []);

  const getListMemberShipList = async () => {
    try {
      const res = await axios.get(
        `${REACT_APP_BASE_URL}/list_membership_contact`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setListMemberData(res?.data?.options);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (listMemberData?.length === 0) {
      getListMemberShipList();
    }
  }, []);

  const getCityOptions = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/getCity`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const options = response.data.cities.map((city) => ({
          value: city.name,
          label: city.name,
        }));
        setAllCityOptions(options);
      }
    } catch (error) {
      console.error("Error fetching city data:", error);
    }
  };

  const fetchDataBase = async (customPage = null, key = null) => {
    setLoading(true);
    const pageNumber = customPage ? customPage : page;
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/batchAssign`,
        {
          offset: (pageNumber - 1) * limit,
          limit: limit,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAllCount(response?.data?.total);
      const newData = response.data.results;
      setAllDataLoaded(newData.length === 0);
      checkedItemListRef.current =
        key === "RESET"
          ? [...newData]
          : [...checkedItemListRef.current, ...newData];
      key === "RESET" && localStorage.removeItem("filterPayload");
      setAllCheckActive(false);
      if (customPage) {
        setCheckedItems([]);
      }
      setUserData(checkedItemListRef.current);
      setFilteredData(newData);
      if (isCheckedAll) {
        const ids = newData.map((data) => data.id);
        setCheckedItems((prevData) => [...prevData, ...ids]);
      }
      setPage(customPage ? customPage + 1 : page + 1);

      if (newData.length > 0) {
        storeDataInIndexedDB(
          db2,
          "databaseContactStore",
          checkedItemListRef.current
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setHideLoading(false);
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const sortDatabase = async (sortBy, direction, customPage = null) => {
    setLoading(true);
    const filterdata = JSON.parse(localStorage.getItem("filterPayload"));
    const pageNumber = customPage ? customPage : page;
    if (navigator.onLine) {
      try {
        const response = await axios.post(
          `${REACT_APP_BASE_URL}/batchAssign`,
          filterdata
            ? {
              ...filterdata,
              offset: (pageNumber - 1) * limit,
              limit: limit,
              sort_by: sortBy,
              direction: direction.toUpperCase(),
            }
            : {
              offset: (pageNumber - 1) * limit,
              limit: limit,
              sort_by: sortBy,
              direction: direction.toUpperCase(),
            },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const newData = response.data.results;
        setAllDataLoaded(newData.length === 0);
        const activeList = isFiltered
          ? checkedItemListFilterRef
          : checkedItemListRef;
        activeList.current = newData
          ? newData
          : [...activeList.current, ...newData];
        setUserData(activeList.current);
        setPage(customPage ? customPage + 1 : page + 1);
        if (isCheckedAll) {
          const ids = newData.map((data) => data.id);
          setCheckedItems((prevData) => [...prevData, ...ids]);
        }
        setFilteredData(newData);
        setLoading(false);
        if (newData.length > 0) {
          storeDataInIndexedDB(db2, "databaseContactStore", newData);
        }
        setIsCheckedAll(false);
        setAllCheckActive(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setHideLoading(false);
      }
    } else {
      const sortedData = [...userData].sort((a, b) => {
        if (direction === "ascending") {
          return a.properties[sortBy] > b.properties[sortBy] ? 1 : -1;
        } else {
          return a.properties[sortBy] < b.properties[sortBy] ? 1 : -1;
        }
      });
      setUserData(sortedData);
      setFilteredData(sortedData);
      setLoading(false);
      setHideLoading(false);
    }
  };

  const sortDatabaseFirstNameDescending = async (customPage = null) => {
    await sortDatabase("firstname", "descending", customPage);
  };
  const sortDatabaseFirstNameAscending = async (customPage = null) => {
    await sortDatabase("firstname", "ascending", customPage);
  };
  const sortDatabaseLastNameDescending = async (customPage = null) => {
    await sortDatabase("lastname", "descending", customPage);
  };
  const sortDatabaseLastNameAscending = async (customPage = null) => {
    await sortDatabase("lastname", "ascending", customPage);
  };
  const sortDatabaseAddressDescending = async (customPage = null) => {
    await sortDatabase("residence_address_line_1", "descending", customPage);
  };
  const sortDatabaseAddressAscending = async (customPage = null) => {
    await sortDatabase("residence_address_line_1", "ascending", customPage);
  };
  const sortDatabaseZipDescending = async (customPage = null) => {
    await sortDatabase("residence_zip", "descending", customPage);
  };
  const sortDatabaseZipAscending = async (customPage = null) => {
    await sortDatabase("residence_zip", "ascending", customPage);
  };

  const handleCheckedItem = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }
    setHideAssignbtn(true);
    setCheckedItems(newCheckedItems);

    if (newCheckedItems.length === userData.length) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }
  };

  const handleAssignedSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let data;
    const filterdata = localStorage.getItem("filterPayload");
    if (selectedUser.email !== "" || selectedUser2.email !== "") {
      if (active && !filterdata) {
        data = {
          assoc_to_email: selectedUser.email || selectedUser2.email,
          tcp_assoc_to_id: selectedUser2.id || "",
          assoc_to_id: selectedUser.id || "",
          all_contacts: true || "",
          last_filtered: {
            firstname: "",
            lastname: "",
            residence_address_line_1: "",
            residence_city: "",
            residence_zip: "",
            age: "",
            state_income_decile: "",
            census_block: "",
            national_voter_cluster: "",
          },
        };
      } else if (active && filterdata) {
        const parsedFilterData = JSON.parse(filterdata);
        data = {
          assoc_to_email: selectedUser.email || selectedUser2.email,
          tcp_assoc_to_id: selectedUser2.id || "",
          assoc_to_id: selectedUser.id || "",
          all_contacts: true || "",
          last_filtered: parsedFilterData || "",
        };
      } else {
        data = {
          assoc_to_email: selectedUser.email || selectedUser2.email,
          tcp_assoc_to_id: selectedUser2.id,
          assoc_to_id: selectedUser.id,
          contact_ids: checkedItems,
          all_contacts: false,
        };
      }

      try {
        const res = await axios.post(`${REACT_APP_BASE_URL}/assoc_user`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.data) {
          toast.success("Contacts Assigned successfully");
          setCheckedItems([]);
          setLoading(false);
          setIsCheckedAll(false);
        }
      } catch (error) {
        console.error("Error contact Assigned:", error);
        toast.error("Error contact Assigned");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setAssignError(true);
    }
  };

  const fetchUserList = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/ownerlist`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newData = response.data.results;
      setOwnerList(newData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const DatabaseProperty = async () => {
    let data = "";
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/databaseProperties`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data) {
        setDataproperty(response.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    if (navigator.onLine) {
      fetchUserList();
      DatabaseProperty();
    }
  }, []);

  const handleUserSelect = (e) => {
    const selectedIndex = e.target.selectedIndex;
    setSelectedUser({
      id: e.target.options[selectedIndex].getAttribute("data-id"),
      email: e.target.value,
    });
    setAssignError(false);
  };
  const handleUser2Select = (e) => {
    const selectedIndex = e.target.selectedIndex;
    setSelectedUser2({
      id: e.target.options[selectedIndex].getAttribute("data-id"),
      email: e.target.value,
    });
    setAssignError(false);
  };

  const handleCheckedAll = () => {
    const activeList = isFiltered
      ? checkedItemListFilterRef.current
      : checkedItemListRef.current;
    const newCheckedItems = isAllCheckActive
      ? []
      : activeList.map((data) => data.id);
    setCheckedItems(newCheckedItems);
    setAllCheckActive(!isAllCheckActive);
    setActive(false);
    setHideAssignbtn(true);
  };

  const handleClick = () => {
    if (active) {
      setIsCheckedAll(false);
      setAllCheckActive(false);
      setHideAssignbtn(true);
    } else if (allCount >= 1000) {
      if (allCount >= 9900) {
        setHideAssignbtn(false);
        toast.error(
          "Batch assignment limit exceeded: Maximum allowed is 9,900 contacts."
        );
      } else {
        toast("It will Take time to Assign Data!!!");
      }
    }

    setActive(!active);
    const newCheckedItems = active ? [] : userData.map((data) => data.id);
    setCheckedItems(newCheckedItems);
  };

  const filterOnline = async (customPage = null, key = null) => {
    setHideAssignbtn(false);
    setHideLoading(true);
    setLoading(true);
    const pageNumber = customPage !== null ? customPage : filterPage;
    const offset = (pageNumber - 1) * limit;
    const filterPayload = {
      offset: offset,
      limit: limit,
      firstname: searchFirstName,
      lastname: searchLastName,
      residence_address_line_1: searchAddress,
      residence_city: searchCity,
      residence_zip: searchZip,
      age: searchAge,
      state_income_decile: searchState_income_decile,
      census_block: searchCensus_block,
      national_voter_cluster: searchNational_voter_cluster,
      presence_of_children_code: searchPresence_of_children_code,
      list_membership: selectedMemberList,
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/batchAssign`,
        filterPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllCount(response?.data?.total);
      const filteritems = response.data.results;
      if (key === "RESET") {
        checkedItemListFilterRef.current = [...filteritems];
      } else {
        checkedItemListFilterRef.current = [
          ...checkedItemListFilterRef.current,
          ...filteritems,
        ];
      }     
      setFilteredData(filteritems);
      setUserData(checkedItemListFilterRef.current);
      setAllCheckActive(false);
      if (customPage !== null) {
        setCheckedItems([]);
      }
      setFilterPage(customPage !== null ? customPage + 1 : filterPage + 1);
      if (isCheckedAll) {
        const ids = filteritems.map((data) => data.id);
        setCheckedItems((prevData) => [...prevData, ...ids]);
      }
      localStorage.setItem("filterPayload", JSON.stringify(filterPayload));
      setAllCount(response?.data?.total);
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
      setHideLoading(false);
    }
  };

  const filterOffline = (e) => {
    let filtered = userData.slice();
    if (searchFirstName) {
      filtered = filtered.filter(
        (user) =>
          user.properties.firstname &&
          user.properties.firstname
            .toLowerCase()
            .includes(searchFirstName.toLowerCase())
      );
    }
    if (searchLastName) {
      filtered = filtered.filter(
        (user) =>
          user.properties.lastname &&
          user.properties.lastname
            .toLowerCase()
            .includes(searchLastName.toLowerCase())
      );
    }
    if (searchAddress) {
      filtered = filtered.filter(
        (user) =>
          user.properties.residence_address_line_1 &&
          user.properties.residence_address_line_1
            .toLowerCase()
            .includes(searchAddress.toLowerCase())
      );
    }
    if (searchCity) {
      filtered = filtered.filter(
        (user) =>
          user.properties.residence_city &&
          user.properties.residence_city
            .toLowerCase()
            .includes(searchCity.toLowerCase())
      );
    }
    if (searchZip) {
      filtered = filtered.filter(
        (user) =>
          user.properties.residence_zip &&
          user.properties.residence_zip.includes(searchZip)
      );
    }
    if (searchAge) {
      filtered = filtered.filter(
        (user) =>
          user.properties.age && user.properties.age === parseInt(searchAge)
      );
    }
    if (searchState_income_decile) {
      filtered = filtered.filter(
        (user) =>
          user.properties.state_income_decile &&
          user.properties.state_income_decile ===
          parseInt(searchState_income_decile)
      );
    }
    if (searchCensus_block) {
      filtered = filtered.filter(
        (user) =>
          user.properties.census_block &&
          user.properties.census_block.includes(searchCensus_block)
      );
    }
    if (searchNational_voter_cluster) {
      filtered = filtered.filter(
        (user) =>
          user.properties.national_voter_cluster &&
          user.properties.national_voter_cluster ===
          searchNational_voter_cluster
      );
    }
    if (searchPresence_of_children_code) {
      filtered = filtered.filter(
        (user) =>
          user.properties.presence_of_children_code &&
          user.properties.presence_of_children_code ===
          searchPresence_of_children_code
      );
    }
    if (selectedMemberList) {
      filtered = filtered.filter(
        (user) =>
          user.properties.list_membership &&
          user.properties.list_membership === selectedMemberList
      );
    }
    setFilteredData(filtered);
  };

  const applyFilters = (e) => {
    e.preventDefault();
    setIsFiltered(true);
    setFilterPage(1);
    if (navigator.onLine) {
      filterOnline(1, "RESET");
    } else {
      filterOffline();
    }
  };

  const handlePagination = () => {
    if (!loading && !allDataLoaded) {
      if (isFiltered) {
        filterOnline();
      } else {
        fetchDataBase();
      }
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      fetchDataBase();
      getCityOptions();
    }
  }, []);

  useEffect(() => {
    fetchDataFromIndexedDB(db2, "databaseContactStore").then((data) => {
      if (navigator.onLine === false) {
        setUserData(data);
        setFilteredData(data);
        setLoading(false);
      }
    });
  }, []);

  const handleReset = () => {
    setIsFiltered(false);
    if (navigator.onLine === true) {
      fetchDataBase(1, "RESET");
    } else {
      fetchDataFromIndexedDB(db2, "databaseContactStore").then((data) => {
        setUserData(data);
        setFilteredData(data);
      });
    }
  };

  return (
    <div>
      <div className=" flex justify-between flex-wrap items-center bg-troo-white py-3 sticky top-[54px]">
        <div className="flex items-center gap-8 pl-5 ">
          <div
            className={` text-troo-directoryText py-2 flex gap-3 items-center cursor-pointer uppercase ${activeTab === "contacts" &&
              "border-b-2 border-troo-primary text-troo-primary"
              }`}
            onClick={() => handleTabClick("contacts")}
          >
            <h6 className="font-semibold">Contacts</h6>(
            {navigator.onLine ? allCount : userData.length})
          </div>
          {userData.length > 0 && checkedItems.length >= 1 && hideAssignbtn && (
            <div className="pl-4">
              <button
                type="button"
                data-hs-overlay="#hs-basic-modal8"
                className="py-2 px-3 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-troo-primary text-troo-white disabled:cursor-progress"
              >
                Assign Canvasser
              </button>
            </div>
          )}
        </div>
        <div></div>
        <div className="flex items-center gap-8 px-5 border-x-2"></div>
        <div className="flex gap-5 items-center pr-5">
          <button type="button" data-hs-overlay="#hs-basic-modal">
            <img className="w-5 h-5" src={filterIcon} alt="filterIcon" />
          </button>
          <button
            className="p-2 bg-troo-secondery bg-opacity-30 rounded"
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
      </div>
      {activeTab === "contacts" && (
        <ContactTableDatabase
          IDBtype="contact"
          isCheckedAll={isCheckedAll}
          active={active}
          handleCheckedAll={handleCheckedAll}
          selectedUser={selectedUser}
          ownerList={ownerList}
          handleUserSelect={handleUserSelect}
          dataProperty={dataProperty}
          handleAssignedSubmit={handleAssignedSubmit}
          handleClick={handleClick}
          checkedItems={checkedItems}
          handleCheckedItem={handleCheckedItem}
          isDatabasePage
          sortDatabaseFirstNameDescending={sortDatabaseFirstNameDescending}
          sortDatabaseFirstNameAscending={sortDatabaseFirstNameAscending}
          sortDatabaseLastNameDescending={sortDatabaseLastNameDescending}
          sortDatabaseLastNameAscending={sortDatabaseLastNameAscending}
          sortDatabaseAddressDescending={sortDatabaseAddressDescending}
          sortDatabaseAddressAscending={sortDatabaseAddressAscending}
          sortDatabaseZipDescending={sortDatabaseZipDescending}
          sortDatabaseZipAscending={sortDatabaseZipAscending}
          fetchDataBase={fetchDataBase}
          setFilteredData={setFilteredData}
          filteredData={filteredData}
          userData={userData}
          allCount={allCount}
          loading={loading}
          isAllCheckActive={isAllCheckActive}
          setAllCheckActive={setAllCheckActive}
          checkedItemListRef={checkedItemListRef}
          db2={db2}
          syncableContactDB={syncableContactDB}
        />
      )}

      {!allDataLoaded &&
        !hideLoading &&
        userData.length >= 10 &&
        navigator.onLine && (
          <div className="w-full flex items-center justify-center p-5">
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-troo-primary text-troo-white disabled:cursor-progress"
              onClick={handlePagination}
              disabled={loading}
            >
              {loading ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
      {/* search Model */}
      <div
        id="hs-basic-modal"
        className="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-2xl  m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto max-h-[800px]">
            <div className="p-4 overflow-y-auto">
              <h4 className="pb-5 flex justify-center">
                Max. 3 search together allowed.
              </h4>
              <form className="w-full" onSubmit={(e) => applyFilters(e)}>
                <SearchInputField
                  label="First Name"
                  onChange={(e) => setSearchFirstName(e.target.value)}
                />
                <SearchInputField
                  label="Last Name"
                  onChange={(e) => setSearchLastName(e.target.value)}
                />

                <SearchInputField
                  label="Street Address"
                  onChange={(e) => setSearchAddress(e.target.value)}
                />
                <div className="md:flex md:items-center justify-between w-full mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-500 font-bold mb-1 md:mb-0 pr-4"
                      htmlFor="input-field"
                    >
                      City
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      id="city"
                      name="city"
                      className="bg-gray-200 appearance-none border-none border-gray-200 rounded w-full  text-gray-700 leading-tight focus:outline-none "
                      options={allCityOptions}
                      value={allCityOptions.find(
                        (option) => option.value === searchCity
                      )}
                      onChange={(selectedOption) =>
                        setSearchCity(
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      isClearable
                    />
                  </div>
                </div>
                <SearchInputField
                  label="Zip Code"
                  onChange={(e) => setSearchZip(e.target.value)}
                />
                <SearchInputField
                  label="Age"
                  onChange={(e) => setSearchAge(e.target.value)}
                />
                <SearchInputField
                  label="State Income Decile"
                  onChange={(e) => setState_income_decile(e.target.value)}
                  type={
                    dataProperty?.find(
                      (data) => data?.name === "state_income_decile"
                    )?.fieldType
                  }
                  options={
                    dataProperty?.find(
                      (data) => data?.name === "state_income_decile"
                    )?.options
                  }
                />
                <SearchInputField
                  label="National Voter Cluster"
                  onChange={(e) =>
                    setSearchNational_voter_cluster(e.target.value)
                  }
                  type={
                    dataProperty?.find(
                      (data) => data?.name === "national_voter_cluster"
                    )?.fieldType
                  }
                  options={
                    dataProperty?.find(
                      (data) => data?.name === "national_voter_cluster"
                    )?.options
                  }
                />
                <SearchInputField
                  label="Census Block"
                  onChange={(e) => setSearchCensus_block(e.target.value)}
                />
                <SearchInputField
                  label={
                    <>
                      Presence of <br className="md:block hidden" />
                      Children Code
                    </>
                  }
                  onChange={(e) =>
                    setSearchpresence_of_children_code(e.target.value)
                  }
                  type={
                    dataProperty?.find(
                      (data) => data?.name === "presence_of_children_code"
                    )?.fieldType
                  }
                  options={
                    dataProperty?.find(
                      (data) => data?.name === "presence_of_children_code"
                    )?.options
                  }
                />

                <SearchInputField
                  label={<>List Membership</>}
                  onChange={(e) => setSelectedMemberList(e.target.value)}
                  type={"select"}
                  options={listMemberData}
                />

                <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
                  <button
                    type="submit"
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-troo-primary text-troo-white "
                    data-hs-overlay="#hs-basic-modal"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs-basic-modal8"
        className="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
            <div className="p-4 overflow-y-auto">
              <form className="w-full" onSubmit={handleAssignedSubmit}>
                <div className="flex flex-col gap-3 mb-6">
                  <label
                    className="text-gray-500 font-bold"
                    htmlFor="assigned-email-select"
                  >
                    Canvasser Owner
                  </label>
                  <div className="w-full">
                    <select
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="assigned-email-select"
                      value={selectedUser.email}
                      onChange={handleUserSelect}
                    >
                      <option value="">Select an Owner</option>
                      {ownerList.map((user) => (
                        <option
                          key={user.id}
                          value={user.email}
                          data-id={user.id}
                        >
                          {user.firstName}&nbsp;{user.lastName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex flex-col gap-3 mb-6">
                  <label
                    className="text-gray-500 font-bold"
                    htmlFor="assigned-email-select"
                  >
                    TCP Canvasser Owner
                  </label>
                  <div className="w-full">
                    <select
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="assigned-email-select"
                      value={selectedUser2.email}
                      onChange={handleUser2Select}
                    >
                      <option value="">Select an TCP Canvasser Owner</option>
                      {ownerList.map((user) => (
                        <option
                          key={user.id}
                          value={user.email}
                          data-id={user.id}
                        >
                          {user.firstName}&nbsp;{user.lastName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <span className="text-red-500">
                    {assignError ? "Atleast Select One Owner" : ""}
                  </span>
                </div>
                <div className="flex justify-end items-center gap-x-2">
                  <button
                    type="submit"
                    disabled={loading}
                    className="py-3 px-6 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-troo-primary text-troo-white"
                  >
                    {loading && <ButtonLoader />}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatabaseComponent;
