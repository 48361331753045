import { MdVpnKey } from "react-icons/md";
import { FaHouseUser } from "react-icons/fa6";
import { TfiWrite } from "react-icons/tfi";
import { FaHand } from "react-icons/fa6";
import { TfiMenuAlt } from "react-icons/tfi";
import { BsFillCloudCheckFill } from "react-icons/bs";
import { AiFillFile } from "react-icons/ai";

export const PrivencyData = [
  {
    text: "Review permission settings",
    icon: <MdVpnKey className="w-6 h-6 text-troo-black text-opacity-55"/>,
  },
  {
    text: "Review consent settings",
    icon: <FaHouseUser className="w-6 h-6 text-troo-black text-opacity-55" />,
  },
  {
    text: "Review signature settings",
    icon: <TfiWrite className="w-6 h-6 text-troo-black text-opacity-55"/>,
  },
  {
    text: "Anonymize data linked to your contacts",
    icon: <FaHand className="w-6 h-6 text-troo-black text-opacity-55"/>,
  },
  {
    text: "Data protection contact details",
    icon: <TfiMenuAlt className="w-6 h-6 text-troo-black text-opacity-55"/>,
  },
  {
    text: "Purpose for collecting data",
    icon: <BsFillCloudCheckFill className="w-6 h-6 text-troo-black text-opacity-55"/>,
  },
  {
    text: "Record of data processing activities",
    icon: <AiFillFile className="w-6 h-6 text-troo-black text-opacity-55"/>,
  },
];
