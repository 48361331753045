import { Dialog, DialogPanel } from '@headlessui/react'
import React from 'react'

const CustomModal = ({ open, onClose, panelClass, zIndexClass, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={`relative ${zIndexClass ? zIndexClass : "z-[999]"}`}
    >
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 bg-troo-primary/20 backdrop-blur-sm">
          <DialogPanel
            transition
            className={`bg-white/5 duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 shadow-2xl ${panelClass || "xl:max-w-7xl lg:max-w-5xl md:max-w-3xl max-w-3xl w-full"}`}
          >
            <div className="w-full sm:mx-auto max-h-[calc(100vh-3.5rem)] bg bg-white border rounded-2xl overflow-y-auto">
              <div className="p-4 overflow-y-auto">
                {children}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default CustomModal