import React, { useState } from "react";
import Sidebar from "../Components/Sidebar";
import Usersbar from "../Components/Usersbar";
import Privacy from "../Components/Privacy";

const PrivacyPage = () => {
  return (

      <Privacy/>
  );
};

export default PrivacyPage;
