import React from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import { TfiMenuAlt } from "react-icons/tfi";

const AssoiatedList = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between gap-5 border-b-2 pb-5">
        <h1>Associate Lists?</h1>
        <form className="flex gap-3 items-center">
          <button>
            <HiOutlineSearch className="w-6 h-6" />
          </button>
          <input
            type="text"
            placeholder="Search users"
            className="border-none bg-[#F6F7FB] rounded font-outfit"
          />
        </form>
      </div>
      <p>
        Select which list(s) of contacts/houses you want participants to focus
        on during this action.
      </p>
      <h4 className="font-bold">IMPORTANT</h4>
      <p>
        Participants will only have access to these selected lists for the
        duration of the action. Should you not select a list then participants
        will have access to any lists that are assigned to them.
      </p>
      <div className="flex flex-col gap-3 mt-8">
        <div className="flex justify-between gap-5 items-center">
          <div className="flex gap-5 items-center">
            <div>
              <TfiMenuAlt className="w-6 h-6 text-troo-secondery" />
            </div>
            <div>
              <h5>Sample Data List</h5>
              <p className="text-troo-secondery">112 contacts</p>
            </div>
          </div>
          <button>
            <FaCheck className="w-5 h-5 text-[#169265]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssoiatedList;
