import React from "react";
import { IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";

const AnalyticsSurveyTable = () => {
  return (
    <div className="rounded bg-troo-white ">
      <div className="py-5 px-8 flex justify-between border-b-2">
        <h5 className="font-semibold">Surveys</h5>
      </div>
      <div>
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-[#F1F1F1]">
                    <tr>
                      <th
                        scope="col"
                        className="px-5 py-4 text-start text-xs text-[#676767] font-outfit font-bold uppercase"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-xs text-[#676767] font-outfit font-bold uppercase"
                      >
                        Questions
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-xs text-[#676767] font-outfit font-bold uppercase"
                      >
                        Completed
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-xs text-[#676767] font-outfit font-bold uppercase"
                      >
                        Questions Answered
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-xs text-[#676767] font-outfit font-bold uppercase"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                      <td className="px-6 py-3 whitespace-nowrap font-outfit text-sm font-normal text-start">
                        New Road - Survey to capture feedback on t…
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center">
                        4
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center">
                        0
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center">
                        0
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center">
                        <Link className="text-[#169265] flex items-center gap-1 uppercase font-semibold font-outfit">
                          Full Overview <IoChevronForward className="w-5 h-5" />
                        </Link>
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 ">
                      <td className="px-6 py-3 whitespace-nowrap font-outfit text-sm font-normal text-start"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                    </tr>
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 ">
                      <td className="px-6 py-3 whitespace-nowrap font-outfit text-sm font-normal text-start"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                    </tr>
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 ">
                      <td className="px-6 py-3 whitespace-nowrap font-outfit text-sm font-normal text-start"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                    </tr>
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 ">
                      <td className="px-6 py-3 whitespace-nowrap font-outfit text-sm font-normal text-start"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-outfit font-normal text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsSurveyTable;
