import React, { useContext, useEffect, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "../images/location-pin.svg";
import GreenPin from "../images/green-pin.svg";
import OrangePin from "../images/orange-pin.svg";
import LiveLocationPin from "../images/live-location-pin.svg";
import BlackPin from "../images/black-pin.svg";
import { LocationContext } from "./LocationContext";

const TrackingMap = ({
  dataList,
  mapOptions,
  latLngMap,
  handleOnLoad,
  loading,
  handleEditClick,
  radius = 8046.72,
}) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const { locationPermission, location } = useContext(LocationContext);
  const [mapCenter, setMapCenter] = useState({
    lat: location?.latitude,
    lng: location?.longitude,
  });

  const mapRef = useRef(null);
  const mapsRef = useRef(null);
  const circleRef = useRef(null); // Ref for the circle

  const defaultCenter = {
    lat: location?.latitude,
    lng: location?.longitude,
  };

  const handleActiveMarker = (markerId) => {
    setActiveMarker(markerId === activeMarker ? null : markerId);
  };

  useEffect(() => {
    if (dataList.length > 0) {
      const avgLat =
        dataList.reduce((sum, data) => sum + latLngMap[data.id]?.lat, 0) /
        dataList.length;
      const avgLng =
        dataList.reduce((sum, data) => sum + latLngMap[data.id]?.lng, 0) /
        dataList.length;

      if (!isNaN(avgLat) && !isNaN(avgLng)) {
        setMapCenter({ lat: avgLat, lng: avgLng });
      }
    }
  }, [dataList, latLngMap]);

  useEffect(() => {
    if (
      locationPermission &&
      Object.keys(location).length > 0 &&
      mapRef.current &&
      mapsRef.current
    ) {
      drawCircle();
      mapRef.current.setZoom(12);
    } else if (mapRef.current && mapsRef.current) {
      mapRef.current.setZoom(9);
      if (circleRef.current) {
        // circleRef.current = null;
        circleRef.current.setMap(null);
      }
    }
  }, [locationPermission, location]);

  useEffect(() => {
    if (locationPermission && mapRef.current && mapsRef.current) {
      drawCircle();
      mapRef.current.setZoom(12);
    }
  }, []);

  const handleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    mapsRef.current = maps;

    if (locationPermission) {
      drawCircle();
    }
  };

  const drawCircle = () => {
    circleRef.current = new mapsRef.current.Circle({
      strokeColor: "#7C83FF",
      strokeOpacity: 0.4,
      strokeWeight: 2,
      fillColor: "#7C83FF",
      fillOpacity: 0.35,
      map: mapRef.current,
      center: { lat: location.latitude, lng: location.longitude },
      radius,
    });
  };

  if (loading) return null;
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBwBtxw1WbNeANR4SlUgLHN3nuhF1qPaWs" }}
        defaultCenter={locationPermission ? defaultCenter : mapCenter}
        center={locationPermission ? defaultCenter : mapCenter}
        defaultZoom={mapOptions.zoom || 9}
        options={mapOptions}
        onClick={() => setActiveMarker(null)}
        onGoogleApiLoaded={handleApiLoaded}
        yesIWantToUseGoogleMapApiInternals
      >
        {dataList?.map((data) => {
          if (data.is_check) {
            const markerPosition = latLngMap[data.id];
            if (!markerPosition) return null;
            return (
              <CustomMarker
                key={data.id}
                lat={markerPosition?.lat}
                lng={markerPosition?.lng}
                householdDisposal={data?.properties?.household_disposition}
                onClick={() => {
                  handleEditClick(data);
                  handleActiveMarker(data.id);
                }}
              />
            );
          }
          return null;
        })}

        {locationPermission && Object.keys(location).length > 0 && (
          <CustomMarker
            lat={location.latitude}
            lng={location.longitude}
            isLive
          />
        )}
      </GoogleMapReact>
    </div>
  );
};

const CustomMarker = ({ lat, lng, onClick, householdDisposal, isLive }) => (
  <img
    src={
      householdDisposal === "Household Completed"
        ? GreenPin
        : householdDisposal === "Household Partially Canvassed"
        ? OrangePin
        : householdDisposal === "No Answer"
        ? LocationPin
        : isLive
        ? LiveLocationPin
        : BlackPin
    }
    alt="Location Pin"
    style={{
      width: "48px",
      height: "50px",
      cursor: "pointer",
      position: "absolute",
      transform: "translate(-50%, -50%)",
    }}
    onClick={!isLive && onClick}
    lat={lat}
    lng={lng}
  />
);

export default TrackingMap;
