import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { createDatabase, fetchDataFromIndexedDB, storeDataInIndexedDB } from "../Hooks/IndexedDBUtils";
const { REACT_APP_BASE_URL } = process.env;

export const surveyEntry = createDatabase("surveyEntryDB", "surveyEntryStore");

const SurveyEntry = () => {
  const [data, setData] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("urserMail");

  const surveyEntryData = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/user`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      axios
        .post(
          `${REACT_APP_BASE_URL}/surveyList`,
          JSON.stringify({
            email: response?.data?.email,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const apiData = res?.data?.results;
          setData(apiData);
          if (apiData) {
            storeDataInIndexedDB(surveyEntry, "surveyEntryStore", apiData);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error?.response?.data?.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDataFromIndexedDB(surveyEntry, "surveyEntryStore").then((data) => {
      if (navigator.onLine === false) {
        setData(data);
      }
    });
  }, []);
  useEffect(() => {
    if (navigator.onLine) {
      surveyEntryData();
    }
  }, []);

  const openModal = (entry) => {
    setSelectedEntry(entry);
  };

  const closeModal = () => {
    setSelectedEntry(null);
  };

  return (
    <div className="md:p-10 p-4">
      <div className="flex justify-between gap-5">
        <h1 className="text-troo-primary pb-2 border-b border-troo-primary mb-12 font-semibold">
          Survey Entries
        </h1>
        <div>
          <Link
            to="/form"
            className="md:py-3 py-2 md:px-4 px-2 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-troo-primary text-white hover:text-troo-primary hover:bg-troo-primary/10 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 transition-all "
          >
            Add Survey
          </Link>
        </div>
      </div>
      <div className="flex flex-col bg-troo-white">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="border overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="md:px-6 px-3 md:py-5 py-2 text-start text-troo-secondery font-outfit font-bold">
                      Name
                    </th>
                    <th className="md:px-6 px-3 md:py-5 py-2 text-start text-troo-secondery font-outfit font-bold">
                      Address
                    </th>
                    <th className="md:px-6 px-3 md:py-5 py-2 text-start text-troo-secondery font-outfit font-bold">
                      Email
                    </th>
                    <th className="md:px-6 px-3 py-3 text-right text-troo-secondery font-outfit font-bold">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((entry, index) => (
                    <tr key={index}>
                      <td className="md:px-6 px-3 md:py-4 py-2 whitespace-nowrap  text-sm font-medium text-gray-800">
                        {entry?.firstname} {entry?.lastname}
                      </td>
                      <td className="md:px-6 px-3 md:py-4 py-2 whitespace-nowrap  text-sm text-gray-800">
                        {entry?.city}
                      </td>
                      <td className="md:px-6 px-3 md:py-4 py-2 whitespace-nowrap text-sm text-gray-800">
                        {entry?.email}
                      </td>
                      <td className="md:px-6 px-3 md:py-4 py-2 whitespace-nowrap  text-end text-sm font-medium">
                        <button
                          type="button"
                          onClick={() => openModal(entry)}
                          className="md:py-3 py-1 md:px-4 px-2 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-troo-primary text-white hover:bg-troo-primary/10 hover:text-troo-primary disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 transition-all"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selectedEntry && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-xl p-8">
            <h2 className="text-lg font-bold mb-4">Details</h2>
            <p>
              <b>ID: </b>
              {selectedEntry.id}
            </p>
            <p>
              <b>Are you a parent?: </b>{" "}
              {selectedEntry.are_you_a_parent_}
            </p>
            <p>
              <b>Address: </b> {selectedEntry.city}
            </p>
            <p>
              <b>Email: </b> {selectedEntry.email}
            </p>
            <p>
              <b>First Name: </b> {selectedEntry.firstname}
            </p>
            <p>
              <b>Last Name: </b> {selectedEntry.lastname}
            </p>
            <p>
              <b>Object Id: </b> {selectedEntry.hs_object_id}
            </p>
            <p>
              <b>Last Modified Date: </b>{" "}
              {selectedEntry.lastmodifieddate}
            </p>
            <p>
              <b>Last created Date: </b> {selectedEntry.createdate}
            </p>
            <p>
              <b>How active are you in the community?: </b>
              {selectedEntry.how_active_are_you_in_the_community_}
            </p>
            <p>
              <b>How long have you lived in the community?: </b>{" "}
              {
                selectedEntry
                  .how_long_have_you_lived_in_the_community_
              }
            </p>
            <p>
              <b>What does our community need the most?: </b>{" "}
              {selectedEntry.what_does_our_community_need_the_most_}
            </p>
            <p>
              <b>
                What issue do you think is most important out of the following?:{" "}
              </b>
              {
                selectedEntry
                  .what_issue_do_you_think_is_most_important_out_of_the_following_
              }
            </p>

            <div className="flex justify-end mt-4">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md mr-2"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SurveyEntry;
