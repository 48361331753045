import React, { useState } from 'react'
import Sidebar from '../Components/Sidebar';
import Usersbar from '../Components/Usersbar';
import { BsThreeDotsVertical } from "react-icons/bs";

const InstructionsPage = () => {
  return (
 
      <div className='p-4'>
        <div className='bg-white flex flex-col items-start p-4 border-b border-troo-tersery'>
          <h2>Instructions</h2>
          <p>Share key updates and messages with your field organizers</p>
        </div>
        <div className='bg-white px-4 py-8 overflow-hidden w-full'>
          <div className="rounded-xl overflow-hidden w-full flex flex-col items-start">
            <div className='font-semibold text-xl text-gray-700 w-full bg-gray-400 bg-opacity-10 p-5'>General Instructions</div>
            <div className='w-full flex items-center text-lg gap-3 justify-between text-gray-700 p-5 bg-white border border-gray-200'>
              <p>Remember to close garden gates and be polite with all members of the public. Also don't forget to leave a door hanger on every door!</p>
              <BsThreeDotsVertical/>
            </div>
            <div className='w-full flex items-center text-lg gap-3 justify-between text-gray-700 p-5 bg-white border border-gray-200'>
              <p>Remember to close garden gates and be polite with all members of the public. Also don't forget to leave a door hanger on every door!</p>
              <BsThreeDotsVertical/>
            </div>
            <div className='w-full flex items-center text-lg gap-3 justify-between text-gray-700 p-5 bg-white border border-gray-200'>
              <p>Remember to close garden gates and be polite with all members of the public. Also don't forget to leave a door hanger on every door!</p>
              <BsThreeDotsVertical/>
            </div>
          </div>
        </div>
    </div>
  )
}

export default InstructionsPage