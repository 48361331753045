import WalkingList from "../Components/WalkingList";


const WalkingListPage = () => {

  return (
      <WalkingList/>
  );
};

export default WalkingListPage;
