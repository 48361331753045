import React from "react";

const EditInputField = ({
  label,
  name,
  value,
  onChange,
  rows,
  type = false,
  disabled,
  options = [],
  step,
  required,
  loading,
}) => {
  if (type === "select" || type === "radio") {
    return (
      <div className="md:mb-4 mb-4">
        <label className="block text-gray-500 font-bold mb-2">{label}</label>
        <select
          name={name}
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 disabled:cursor-not-allowed"
          id="assigned-email-select"
          value={value}
          onChange={onChange}
          disabled={disabled || false}
          required={required}
        >
          <option value="">Select {label}</option>
          {options.map((data, i) => (
            <option key={i} value={data.value}>
              {data.label}
            </option>
          ))}
        </select>
      </div>
    );
  } else if (type === "textarea") {
    return (
      <div className="md:mb-4 mb-4">
        <label className="block text-gray-500 font-bold mb-2">{label}</label>
        <textarea
          name={name}
          value={value}
          rows={rows}
          disabled={disabled || false}
          onChange={onChange}
          required={required}
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 disabled:cursor-not-allowed"
        />
      </div>
    );
  } else {
    return (
      <div className="md:mb-4 mb-4">
        <label className="block text-gray-500 font-bold mb-2">
          {label} {loading ? "Loading ..." : ""}
        </label>
        <input
          type={type || "text"}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled || false}
          step={step}
          required={required}
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 disabled:cursor-not-allowed"
        />
      </div>
    );
  }
};

export default EditInputField;
