import React, { useEffect, useState } from "react";
import logo from "../images/login_Logo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import Input from "./Input";
import userIcon from "../images/userIcon.svg";
import { BsEyeFill } from "react-icons/bs";
import { BsEyeSlashFill } from "react-icons/bs";
import passwordIcon from "../images/passwordIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import ButtonLoader from "../Components/ButtonLoader";

const { REACT_APP_BASE_URL } = process.env;

const validationSchema = yup.object().shape({
  username: yup.string().required("Username is Required"),
  password: yup.string().required("Password is Required"),
});

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const updateLocalStorageTokens = (accessToken, userMail,userRole) => {
    localStorage.setItem("token", accessToken);
    localStorage.setItem("urserMail", userMail);
    localStorage.setItem("userRole", userRole);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setIsLoading(true)
      let data = {
        username: values?.username || "",
        password: values?.password || "",
      };
      await axios
        .post(`${REACT_APP_BASE_URL}/auth/signin`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res?.data?.token) {
            const token = res.data.token;
            const usermail = res.data.email;
            const roles = res.data.roles;
            updateLocalStorageTokens(token, usermail,roles);
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error?.response?.data?.message);
          }
          else{
            toast.error("Something went wrong! Please try again in few minutes.")
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <div className="bg-troo-primary h-screen">
      <div className="mainContainer flex flex-col justify-center items-center w-full h-full py-12 lg:py-24">
        <div className="bg-troo-white py-11 px-5 flex gap-2 flex-col items-center rounded-3xl w-[350px]">
          <div>
            <img src={logo} alt="logo" />
          </div>
          <h1 className="text-troo-primary font-bold">CANVASSING APP</h1>
          <p className="text-[11.25px] text-troo-secondery">
            Enter your email and password below
          </p>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            initialValues={{
              username: "",
              password: "",
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values }) => (
              <Form
                className="flex flex-col gap-5 w-full"
                noValidate
                onSubmit={handleSubmit}
              >
                <div>
                  <Field
                    name="username"
                    type="text"
                    as={Input}
                    value={values?.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon={userIcon}
                    lebel="Username"
                  />
                  <ErrorMessage
                    name="username"
                    component="span"
                    className="text-red-500 text-xs"
                  />
                </div>
                <div>
                  <Field
                    name="password"
                    type="password"
                    as={Input}
                    value={values?.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon={passwordIcon}
                    lebel="Password"
                    BsEyeFill={<BsEyeFill />}
                    BsEyeSlashFill={<BsEyeSlashFill />}
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-xs"
                  />
                </div>
                <Link
                  to="/generate-password"
                  className="text-xs pb-3 font-outfit text-troo-secondery text-left"
                >
                  Generate password?
                </Link>
                <Link
                  to="/forget-password"
                  className="text-xs pb-3 font-outfit text-troo-secondery text-right"
                >
                  Forgot password?
                </Link>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full flex items-center gap-3 justify-center text-center font-outfit text-[12px] uppercase font-bold bg-troo-primary text-troo-white py-3 px-5 rounded-[37px] disabled:cursor-not-allowed disabled:bg-troo-primary/20 disabled:text-troo-primary"
                >
                  {
                    isLoading && (
                      <ButtonLoader/>
                    )
                  }
                  Login
                </button>
              </Form>
            )}
          </Formik>
          {/* <div className="flex gap-5 items-center w-[70%]">
            <div className="w-full h-[1px] bg-troo-secondery"></div>
            <p className="text-troo-secondery font-semibold">OR</p>
            <div className="w-full h-px bg-troo-secondery"></div>
          </div>
          <div className="w-full rounded-[37px] py-3 px-5 border border-troo-secondery flex justify-center items-center font-outfit text-[11px] uppercase font-bold text-troo-gray">
            Login with Organisation ID
          </div> */}
        </div>
        {/* <Link
          to="/resister"
          className="text-troo-white font-outfit mt-4 font-semibold text-[12px]"
        >
          Don't have an account?
        </Link> */}
      </div>
    </div>
  );
};

export default Login;
