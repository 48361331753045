import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
const { REACT_APP_BASE_URL } = process.env;

const CustomForm = (props) => {
  const { region, portalId, formId, handleRefresh, refreshBtn, setRefreshBtn, contactEmail, setIsOpen, setSelectValue, setIsLoading, userId, noRefreshBtn } = props;
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("urserMail");
  const [showError, setShowError] = useState(false)
  const customEmailRef = useRef(null);

  const getCurrentUser = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/user`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      createHubSpotForm(response?.data?.email, contactEmail);
    } catch (error) {
      console.log(error);
    }
  };


  // API call before form submit
  const updateCustomEmail = async () => {
    if (userId) {
      try {
        await axios.post(
          `${REACT_APP_BASE_URL}/updateCustomEmailToEmail`,
          {
            contactId: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((response) => {
          customEmailRef.current = response?.data?.data?.email;
        })
      } catch (error) {
        console.error("Error updating custom email:", error);
        toast.error(error?.response?.data?.messagge || error?.message || "client: Failed to update custom email before form submission.");
      }
    }
  };

  // API call after form submitted
  const makeEmptyEmail = async () => {
    if (userId) {
      try {
        await axios.post(
          `${REACT_APP_BASE_URL}/makeEmptyEmail`,
          {
            contactId: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Error making email empty:", error);
        toast.error(error?.response?.data?.messagge || error?.message || "Failed to make email empty after form submission.");
      }
    }
  };

  const createHubSpotForm = (email, contactEmail) => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        try {
          window.hbspt.forms.create({
            region: region,
            portalId: portalId,
            formId: formId,
            target: "#hubspotForm",
            onFormReady: async function ($form) {
              setIsLoading && setIsLoading(false)
              // Set the canvasser_email value
              const canvasserEmailInput = $form.querySelector('input[name="canvasser_email"]');
              if (canvasserEmailInput) {
                canvasserEmailInput.value = email;
                canvasserEmailInput.dispatchEvent(new Event('input', { bubbles: true }));
              }

              // Set the canvasser_email value
              const userHubId = $form.querySelector('input[name="user_id"]');
              if (userHubId) {
                userHubId.value = userId;
                userHubId.dispatchEvent(new Event('input', { bubbles: true }))
                // Hide the input field with CSS
                userHubId.style.display = "none";
              }

              // Hide the entire container for the user_id field
              const userIdFieldContainer = $form.querySelector('.hs_user_id');
              if (userIdFieldContainer) {
                userIdFieldContainer.style.display = 'none';
              }

              // Set the contact email if provided
              if (contactEmail) {
                const contactEmailInput = $form.querySelector('input[name="email"]');
                contactEmailInput.value = contactEmail;
                contactEmailInput.dispatchEvent(new Event('input', { bubbles: true }));
              } else {
                await updateCustomEmail();
              }
              localStorage.setItem(`hsForm_${formId}`, $form.outerHTML);
              const refreshButton = document.querySelector(".hsRefesh");
              if (refreshButton) {
                refreshButton.classList.add("hidden");
              }
              setRefreshBtn(false)
            },
            // onBeforeFormSubmit: async function ($form) { 
            //   if (!contactEmail || contactEmail.length == 0) {
            //     await updateCustomEmail();

            //     if (customEmailRef.current) {
            //       console.log("customEmail from if", customEmailRef.current);
            //       const emailInput = $form.querySelector('input[name="email"]');
            //       if (emailInput) {
            //         // Set the updated email value
            //         emailInput.value = customEmailRef.current;

            //         // Dispatch 'input' event to trigger any form validation or bindings
            //         emailInput.dispatchEvent(new Event('input', { bubbles: true }));
            //       }
            //     }
            //   }
            // },
            onFormSubmit: function ($form) {
              // Before form submission, check if the email is empty
              const emailInput = $form.querySelector('input[name="email"]');
              if (emailInput && !emailInput.value.trim() && contactEmail) {
                // If email is empty, set it to contactEmail
                emailInput.value = contactEmail;
                emailInput.dispatchEvent(new Event('input', { bubbles: true }));
              } else if (customEmailRef.current && emailInput) { 
                // Set the updated email value
                emailInput.value = customEmailRef.current;

                // Dispatch 'input' event to trigger any form validation or bindings
                emailInput.dispatchEvent(new Event('input', { bubbles: true }));
              }

              setRefreshBtn(true)
            },
            onFormSubmitted: async function ($form) {
              toast.success("Survey Submitted")
              if (!contactEmail || contactEmail.length == 0) {
                // Call the makeEmptyEmail API after the form is submitted
                await makeEmptyEmail();
              }
              setIsOpen && setIsOpen(false)
              setSelectValue && setSelectValue("")
              const refreshButton = document.querySelector(".hsRefesh");
              if (refreshButton) {
                refreshButton.classList.remove("hidden");
              }
            },
          });
        } catch (error) {
          console.error("Error rendering HubSpot form:", error);
          toast.error("An error occurred while loading the form.");
          setShowError(true)
          setIsLoading && setIsLoading(false);
        }
      }
    });
  };

  const attachFormSubmitHandler = () => {
    const formElement = document.querySelector('#hubspotForm form');
    if (formElement) {
      formElement.addEventListener('submit', handleFormSubmit);
    } else {
      console.error("Form element not found!");
    }
  };

  const loadFormFromLocalStorage = () => {
    const storedForm = localStorage.getItem(`hsForm_${formId}`);
    if (storedForm) {
      const formContainer = document.getElementById("hubspotForm");
      formContainer.innerHTML = storedForm;
      attachFormSubmitHandler();
    }
  };

  const handleFormSubmit = (event) => {
    if (!navigator.onLine) {
      event.preventDefault();

      // Collect form data
      const form = event.target;
      const formData = new FormData(form);
      const formObject = {};

      formData.forEach((value, key) => {
        formObject[key] = value;
      });

      // Get the form ID (assuming it's stored in a hidden input field or as an attribute)
      const formId = form.getAttribute('data-form-id');

      // Save form data offline
      saveFormOffline(formId, formObject);
    }
  };

  const saveFormOffline = (formId, formObject) => {
    const offlineSubmissions = JSON.parse(localStorage.getItem('offlineSubmissions')) || [];

    // Find the index of the form submission with the given formId
    // const index = offlineSubmissions.findIndex(submission => submission.formId === formId);


    offlineSubmissions.push({ formId, formObject });

    // Save back to localStorage
    localStorage.setItem('offlineSubmissions', JSON.stringify(offlineSubmissions));
    toast.success("Form is submitted")
    // Reset the form
    const form = document.getElementById(`hsForm_${formId}`);
    if (form) {
      form.reset();
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      setIsLoading && setIsLoading(true)
      getCurrentUser();
    } else {
      loadFormFromLocalStorage()
    }
  }, [contactEmail]);

  return (
    <div className="">
      <div id="hubspotForm"></div>
      {showError && (
        <p>Something went wrong! Please contact Admin</p>
      )}
      {
        !noRefreshBtn && refreshBtn && (
          <button
            className={` mt-5 items-center bg-troo-white hover:bg-troo-tersery gap-2 p-2 rounded font-semibold hsRefesh`}
            type="button"
            onClick={() => handleRefresh(getCurrentUser)}
          >
            <h4 className="uppercase">Refresh</h4>
          </button>
        )
      }
    </div>
  );
};

export default CustomForm;
