import logo from "../images/Header-Logo.svg";
import { Link, useLocation } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import { AiOutlineHome } from "react-icons/ai";
import { BsBarChart } from "react-icons/bs";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { MdOutlineImportExport } from "react-icons/md";
import { BsDatabase } from "react-icons/bs";
import { RiMap2Fill } from "react-icons/ri";
import { HiUsers } from "react-icons/hi";
import { LuAlertTriangle } from "react-icons/lu";
import { MdGpsFixed } from "react-icons/md";
import { BsChatRightText } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { RiQuestionnaireLine } from "react-icons/ri";
import { GrDocumentStore } from "react-icons/gr";
import { SiGooglemeet } from "react-icons/si";
import { useEffect, useState } from "react";

const Sidebar = ({ open, setOpen, isMapPage }) => {
  const location = useLocation();
  const handleToggle = () => setOpen(!open);
  const userRole = localStorage.getItem("userRole");

  const SidebarData = [
    {
      title: "",
      data: [
        {
          text: "Dashboard",
          path: "/dashboard",
          icon: <AiOutlineHome />,
        },
        // {
        //   text: " Analytics",
        //   path: "/analytics",
        //   icon: <BsBarChart />,
        // },
        // {
        //   text: " Privacy",
        //   path: "/privancy",
        //   icon: <MdOutlinePrivacyTip />,
        // },
      ],
    },
    {
      title: "Data",
      data: [
        {
          text: "  Import Data",
          path: "/import-data",
          icon: <MdOutlineImportExport />,
        },
        {
          text: " Walking list",
          path: "/walking-list",
          icon: <BsDatabase />,
        },

        userRole === "ROLE_CANVASSER"
          ? null
          : {
              text: "Database",
              path: "/database",
              icon: <BsDatabase />,
            },
        {
          text: " Canvass Map",
          path: "/canvass-map",
          icon: <MdGpsFixed />,
        },
      ].filter(Boolean),
    },
    {
      title: "Settings",
      data: [
        {
          text: " Directory",
          path: "/directory",
          icon: <HiUsers />,
        },
        // {
        //   text: " Actions",
        //   path: "/actions",
        //   icon: <LuAlertTriangle />,
        // },

        // {
        //   text: "  Instructions",
        //   path: "/instructions",
        //   icon: <BsChatRightText />,
        // },
        {
          text: "Surveys",
          path: "/surveyentries",
          icon: <IoNewspaperOutline />,
        },
        {
          text: "Meet Us",
          path: "/schedule-meeting",
          icon: <SiGooglemeet />,
        },
      ],
    },
  ];

  return (
    <div className="hidden md:block ">
      <div className="absolute top-0 left-[-170px] w-[170px] h-full bg-troo-primary">
        <div
          className=" absolute left-[180px] top-3 cursor-pointer"
          onClick={handleToggle}
        >
          <HiMenu
            className={`w-8 h-8 ${
              isMapPage && "bg-troo-white rounded-full  p-1"
            }`}
          />
        </div>
        <div className="h-screen">
          <div className="bg-troo-primary max-w-[170px] w-full h-full py-5 pr-3">
            <div className="px-3">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="pt-5">
              <div>
                {SidebarData.map((ele, i) => (
                  <div key={i} className="">
                    <div className="pl-3 pt-5 text-troo-white font-outfit font-extrabold">
                      {ele.title}
                    </div>
                    <div className="">
                      {ele.data.map((e, i) => (
                        <Link
                          className={`text-troo-sidebar text-[15px] font-outfit font-medium py-[10px] pl-3 flex items-center gap-3 pr-3 border-b-2 border-troo-primary hover:rounded-e-2xl hover:border-b-2 hover:border-troo-white hover:bg-troo-white hover:bg-opacity-10 hover:mr-3 transition-all duration-500 ease-in-out ${
                            location.pathname === e.path &&
                            "rounded-e-2xl border-b-2 border-troo-white bg-troo-white bg-opacity-10 text-troo-white hover:mr-3"
                          }`}
                          to={e.path}
                          key={i}
                        >
                          {e.icon}
                          {e.text}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
