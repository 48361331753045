import React, { useState } from "react";
import Sidebar from "../Components/Sidebar";
import Usersbar from "../Components/Usersbar";
import Analytics from "../Components/Analytics";

const AnalyticsPage = () => {
  return (

      <Analytics/>
  );
};

export default AnalyticsPage;
