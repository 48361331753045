import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BarchartComponent = () => {
  const data = [
    {
      name: "Page A",
      uv: 5,
    },
    {
      name: "Page B",
      uv: 2,
    },
    {
      name: "Page C",
      uv: 6,
    },
    {
      name: "Page D",
      uv: 10,
    },
    {
      name: "Page D",
      uv: 1,
    },
  ];
  return (
    <div className="p-5" style={{ width: "100%", height: "400px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={730} height={250} data={data}>
          <CartesianGrid strokeDasharray="3 3" vertical={false}/>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="uv" fill="#7EC8E6" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarchartComponent;
