import { FaPen,FaFileAlt  } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { AiFillSetting } from "react-icons/ai";
import { MdDelete } from "react-icons/md";

export const AccountSettingData = [
    {
        text: "Edit Account Name",
        icon: <FaPen className="w-6 h-6 text-troo-black text-opacity-55"/>,
      },
      {
        text: "General Admin",
        icon: <TbWorld className="w-6 h-6 text-troo-black text-opacity-55" />,
      },
      {
        text: "View Logs",
        icon: <FaFileAlt className="w-6 h-6 text-troo-black text-opacity-55"/>,
      },
      {
        text: "Manage Access",
        icon: <AiFillSetting className="w-6 h-6 text-troo-black text-opacity-55"/>,
      },
      {
        text: "Delete Access",
        icon: <MdDelete className="w-6 h-6 text-troo-black text-opacity-55"/>,
      },

]