import Dexie from "dexie";

export const createDatabase = (dbName, storeName) => {
  const db = new Dexie(dbName);
  db.version(1).stores({
    [storeName]: "id",
  });
  return db;
};

export const storeDataInIndexedDB = async (db, storeName, data) => {
  try {
    if (Array.isArray(data)) {
      await db[storeName].bulkPut(data);
    } else {
      const dataArray = [data];
      await db[storeName].bulkPut(dataArray);
    }
    console.log("Data stored in IndexedDB successfully");
  } catch (error) {
    console.error("Error storing data in IndexedDB:", error);
  }
};

export const fetchDataFromIndexedDB = async (db, storeName) => {
  try {
    const data = await db[storeName].toArray();
    if (Array.isArray(data) && data.length > 0) {
      return data;
    } else if (typeof data === "object" && Object.keys(data).length > 0) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data from IndexedDB:", error);
    return {};
  }
};

export const updateDataInIndexedDB = async (db, storeName, id, newData) => {
  try {
    await db[storeName].update(id, newData);
    const updatedData = await db[storeName].get(id);
    return updatedData;
  } catch (error) {
    console.error("Error updating data in IndexedDB:", error);
    return null;
  }
};

export const deleteDataFromIndexedDB = (database, storeName) => {
  Dexie.delete(database).then(function () {
    console.log(" database deleted ");
  });
};

export const deleteAllIndexedDB = async () => {
  try {
    const databases = await indexedDB.databases();
    const deletePromises = databases.map((db) => {
      return new Promise((resolve, reject) => {
        const request = indexedDB.deleteDatabase(db.name);
        request.onsuccess = () => {
          console.log(`Deleted database: ${db.name}`);
          resolve();
        };
        request.onerror = (event) => {
          console.error(`Error deleting database: ${db.name}`, event);
          reject(event);
        };
      });
    });

    await Promise.all(deletePromises);
    console.log("All databases deleted successfully.");
  } catch (error) {
    console.error("Error fetching databases:", error);
  }
};
