import React from "react";
import { BiQuestionMark } from "react-icons/bi";

import GridForm from "./GridForm";
import { IoMdStar } from "react-icons/io";
import LineChartSection from "./LineChartSection";
import PiChartComponent from "./PiChartComponent";
import BarchartComponent from "./BarchartComponent";
import AnalyticsListTable from "./AnalyticsListTable";
import AnalyticsStreetTable from "./AnalyticsStreetTable";
import AnalyticsSurveyTable from "./AnalyticsSurveyTable";

const Analytics = () => {
  return (
    <div className="bg-troo-userbar w-full px-12 py-8 flex flex-col gap-8">
      <div className="bg-troo-white p-8 flex flex-col gap-3 rounded">
        <div className="flex items-center gap-5">
          <h1>Analytics</h1>
          <div className="w-6 h-6 bg-troo-secondery text-troo-white rounded-full flex justify-center items-center">
            <BiQuestionMark />
          </div>
        </div>
        <h4 className="text-troo-secondery">
          For generating and viewing reports on canvassing outcomes and
          analytics.
        </h4>
      </div>
      <GridForm />
      <div className="grid grid-cols-2 md:grid-cols-4 gap-5 bg-troo-white p-8 rounded">
        <div className="flex flex-col gap-1">
          <h3 className="text-troo-secondery font-semibold">Attempts</h3>
          <h3 className="font-normal">44</h3>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-troo-secondery font-semibold">Locations</h3>
          <h3 className="font-normal">14</h3>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-troo-secondery font-semibold">
            Conversion Rate  
          </h3>
          <h3 className="font-normal">70%</h3>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-troo-secondery font-semibold">Rating</h3>
          <h3 className="font-normal flex gap-1 items-center">
            4.4 <IoMdStar />
          </h3>
        </div>
      </div>
      <LineChartSection />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-troo-white rounded">
          <div className="py-5 px-8 border-b-2">
            <h5 className="font-semibold">Conversion Rate</h5>
          </div>
          <div className="flex flex-col gap-6 p-5">
            <PiChartComponent />
            <div>
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <tbody className="">
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Answered</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Call Back</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Not Home</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Not Interested</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-troo-white rounded">
          <div className="py-5 px-8 border-b-2">
            <h5 className="font-semibold">Rating</h5>
          </div>
          <BarchartComponent />
        </div>
      </div>
      <AnalyticsListTable />
      <AnalyticsStreetTable />
      <AnalyticsSurveyTable />
      <div>
        <form className="flex items-center flex-wrap gap-8 ">
          <div className="relative min-w-[200px]">
            <select
              className="outline-none appearance-none w-full bg-transparent border-t-0 border-x-0 border-b border-troo-tersery text-gray-700 py-2 text-base font-medium font-outfit px-2 leading-tight "
              id="grid-state"
            >
              <option>All Contacts</option>
              <option>Canvass1</option>
              <option>Canvass2</option>
            </select>
          </div>
          <div className="flex gap-5 items-center">
            <label
              for="hs-basic-with-description-unchecked"
              className="text-base font-outfit "
            >
              Include Anonymized Contacts
            </label>
            <input
              type="checkbox"
              id="hs-basic-with-description-unchecked"
              className="relative w-10 h-[23px] p-px bg-[#9E9E9E] border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-5 before:bg-[#F0F6F8] checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400 dark:checked:before:bg-blue-200"
            />
          </div>
        </form>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <div className="bg-troo-white rounded">
          <div className="py-5 px-8 border-b-2">
            <h5 className="font-semibold">Gender</h5>
          </div>
          <div className="flex flex-col gap-6 p-5">
            <PiChartComponent />
            <div>
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <tbody className="">
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Answered</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Call Back</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Not Home</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Not Interested</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-troo-white rounded">
          <div className="py-5 px-8 border-b-2">
            <h5 className="font-semibold">Age</h5>
          </div>
          <div className="flex flex-col gap-6 p-5">
            <PiChartComponent />
            <div>
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <tbody className="">
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Answered</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Call Back</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Not Home</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Not Interested</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-troo-white rounded">
          <div className="py-5 px-8 border-b-2">
            <h5 className="font-semibold">Ethnicity</h5>
          </div>
          <div className="flex flex-col gap-6 p-5">
            <PiChartComponent />
            <div>
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <tbody className="">
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Answered</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Call Back</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Not Home</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700 border-y">
                            <td className="pr-5 py-2 whitespace-nowrap flex items-center gap-3 text-sm">
                              <div className="bg-[#5B1E68] w-3 h-3"></div>
                              <p>Not Interested</p>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap font-outfit text-sm font-normal text-center">
                              31
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center">
                              70.5%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
