import React from "react";
import Input from "./Input";
import logo from "../images/login_Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import mailIcon from "../images/mailIcon.svg";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
const { REACT_APP_BASE_URL } = process.env;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is Required")
    .email("Invalid email format"),
});

const ForgetPWd = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate()

  const handleSubmit = async (values, { resetForm }) => {
    try {
      let data = {
        email: values?.email || "",
        domain_path: "https://knockknock.tcpactionfund.org"
      };

      await axios
        .post(`${REACT_APP_BASE_URL}/user/forgot-password`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          navigate(res.data.reset_url)
        })
        .catch((error) => {
          console.log("password Error", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-troo-primary h-screen">
      <div className="mainContainer flex flex-col justify-center items-center w-full h-full py-12 lg:py-24">
        <div className="bg-troo-white py-11 px-5 flex gap-2 flex-col items-center rounded-3xl w-[350px]">
          <div>
            <img src={logo} alt="logo" />
          </div>
          <h1 className="text-troo-primary font-bold">CANVASSING APP</h1>
          <p className="text-[15px] font-bold leading-5 text-troo-secondery">
            Let's get you into your account
          </p>
          <div className="flex flex-col items-center">
            <p className="text-[12px] font-outfit font-bold text-troo-secondery ">
              Tell us one of the following to get started:
            </p>
            <ul className="list-disc text-[12px] font-outfit font-bold text-troo-secondery">
              <li>Sign-in email address or mobile number</li>
              <li>Recovery phone number</li>
              <li>Recovery email address</li>
            </ul>
          </div>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            initialValues={{
              email: "",
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values }) => (
              <Form
                className="flex flex-col gap-5 w-full"
                noValidate
                onSubmit={handleSubmit}
              >
                <div>
                  <Field
                    name="email"
                    type="email"
                    as={Input}
                    value={values?.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon={mailIcon}
                    lebel="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="text-red-500 text-xs"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full font-outfit text-[12px] uppercase font-bold bg-troo-primary text-troo-white py-3 px-5 rounded-[37px]"
                >
                  CONTINUE
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <Link
          to="/resister"
          className="text-troo-white font-outfit mt-4 font-semibold text-[12px]"
        >
          Don't have an account?
        </Link>
      </div>
    </div>
  );
};

export default ForgetPWd;
