import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  Link,
  useLocation,
} from "react-router-dom";
import SurveyEntryPage from "./Pages/SurveyEntryPage";
import Login from "./User/Login";
import ForgetPWd from "./User/ForgetPWd";
import DirectoryPage from "./Pages/DirectoryPage";
import InstructionsPage from "./Pages/InstructionsPage";
import HelpDeskPage from "./Pages/HelpDeskPage";
import DigitalReachPage from "./Pages/DigitalReachPage";
import AnalyticsPage from "./Pages/AnalyticsPage";
import PrivacyPage from "./Pages/PrivacyPage";
import ImportDataPage from "./Pages/ImportDataPage";
import ActionPage from "./Pages/ActionPage";
import { Toaster } from "react-hot-toast";
import AccountSetting from "./Pages/AccountSetting";
import ProfileSetting from "./Pages/ProfileSetting";
import DocumentationPage from "./Pages/DocumentationPage";
import ResetPassword from "./User/ResetPassword";
import HubsportForm from "./Pages/HubsportForm";
import ScheduleMeet from "./Pages/ScheduleMeet";
import Sidebar from "./Components/Sidebar";
import Usersbar from "./Components/Usersbar";
import { useEffect, useState } from "react";
import VoterForm from "./Pages/VoterForm";
import GeneratePassword from "./User/GeneratePassword";
import WalkingListPage from "./Pages/WalkingListPage";
import DatabasePage from "./Pages/DatabasePage";
import CanvassMapPage from "./Pages/CanvassMapPage";
import { RiMenu3Fill } from "react-icons/ri";
import logo from "./images/Header-Logo.svg";
import { SiGooglemeet } from "react-icons/si";
import { IoNewspaperOutline } from "react-icons/io5";
import { HiUsers } from "react-icons/hi2";
import { MdGpsFixed, MdOutlineImportExport } from "react-icons/md";
import { BsDatabase } from "react-icons/bs";
import { AiOutlineHome } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import ReactDOM from "react-dom";
import LockButton from "./Components/LockButton";
import { clearStorage } from "./utils";
const { REACT_APP_VERSION } = process.env;

const CheckLoginRoute = ({ redirectPath = "/dashboard" }) => {
  const auth = localStorage.getItem("token");
  if (auth) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

const ProtectedRoute = ({ redirectPath = "/" }) => {
  const auth = localStorage.getItem("token");
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [showSideBar, setShowSideBar] = useState(
    !(localStorage.getItem("lock") === "true") ? true : false
  );

  const [hideSidebarChecked, setHideSidebarChecked] = useState(
    localStorage.getItem("lock") === "true" ? true : false
  );

  const toggleSidebar = (hide) => {
    setShowSideBar(!hide);
  };

  if (!auth) {
    return <Navigate to={redirectPath} replace />;
  }

  const userRole = localStorage.getItem("userRole");

  const SidebarData = [
    {
      title: "",
      data: [
        {
          text: "Dashboard",
          path: "/dashboard",
          icon: <AiOutlineHome />,
        },
      ],
    },
    {
      title: "Data",
      data: [
        {
          text: "  Import Data",
          path: "/import-data",
          icon: <MdOutlineImportExport />,
        },
        {
          text: " Walking list",
          path: "/walking-list",
          icon: <BsDatabase />,
        },

        userRole === "ROLE_CANVASSER"
          ? null
          : {
              text: "Database",
              path: "/database",
              icon: <BsDatabase />,
            },
        {
          text: " Canvass Map",
          path: "/canvass-map",
          icon: <MdGpsFixed />,
        },
      ].filter(Boolean),
    },
    {
      title: "Settings",
      data: [
        {
          text: " Directory",
          path: "/directory",
          icon: <HiUsers />,
        },
        {
          text: "Surveys",
          path: "/surveyentries",
          icon: <IoNewspaperOutline />,
        },
        {
          text: "Meet Us",
          path: "/schedule-meeting",
          icon: <SiGooglemeet />,
        },
      ],
    },
  ];

  return (
    <>
      <div
        className={`all bg-troo-userbar ${
          open && showSideBar ? "opened-sidebar" : " w-full"
        }`}
      >
        <div
          className={`sticky top-0 z-50 py-3 px-5  flex items-center bg-troo-userbar ${
            showSideBar ? "justify-between" : "justify-end"
          }`}
        >
          {showSideBar && <Sidebar open={open} setOpen={setOpen} />}
          {showSideBar && (
            <button
              className="block md:hidden text-troo-primary drawer-content"
              data-hs-overlay="#hs-overlay-left"
            >
              <RiMenu3Fill className="text-[30px] cursor-pointer" />
            </button>
          )}
          <Usersbar
            hideSidebar={toggleSidebar}
            hideSidebarChecked={hideSidebarChecked}
            setHideSidebarChecked={setHideSidebarChecked}
          />
          <div
            id="hs-overlay-left"
            className="hs-overlay hs-overlay-open:translate-x-0  -translate-x-full fixed top-0 start-0 transition-all duration-300 transform h-full bg-black bg-opacity-0  w-full z-[60] hidden border-r border-troo-white border-opacity-10  [--overlay-backdrop:false] [--body-scroll:true]"
            tabIndex="-1"
          >
            <div className="bg-troo-primary max-w-[220px] w-full h-full py-5 pr-3">
              <div className="flex justify-between items-center">
                <div className="px-3">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
                <button
                  type="button"
                  className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-troo-sidebar hover:text-troo-white"
                  data-hs-overlay="#hs-overlay-left"
                >
                  <RxCross2 className="w-7 h-7" />
                </button>
              </div>
              <div className="pt-5">
                <div>
                  {SidebarData.map((ele, i) => (
                    <div key={i} className="">
                      <div className="pl-3 pt-5 text-troo-white font-outfit font-extrabold">
                        {ele.title}
                      </div>
                      <div className="">
                        {ele.data.map((e, i) => (
                          <Link
                            data-hs-overlay="#hs-overlay-left"
                            className={`text-troo-sidebar text-[15px] font-outfit font-medium py-[10px] pl-3 flex items-center gap-3 pr-3 border-b-2 border-troo-primary hover:rounded-e-2xl hover:border-b-2 hover:border-troo-white hover:bg-troo-white hover:bg-opacity-10 hover:mr-3 transition-all duration-500 ease-in-out ${
                              location.pathname === e.path &&
                              "rounded-e-2xl border-b-2 border-troo-white bg-troo-white bg-opacity-10 text-troo-white hover:mr-3"
                            }`}
                            to={e.path}
                            key={i}
                          >
                            {e.icon}
                            {e.text}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
      <LockButton
        hideSidebar={toggleSidebar}
        hideSidebarChecked={hideSidebarChecked}
        setHideSidebarChecked={setHideSidebarChecked}
      />
    </>
  );
};

const ToastPortal = () => {
  return ReactDOM.createPortal(
    <Toaster
      // position="top-right" // or any position you prefer
      toastOptions={{
        style: {
          zIndex: "999999 !important", // Ensure this is higher than your portal's z-index
        },
      }}
      containerStyle={{
        zIndex: 99999999,
      }}
    />,
    document.body // Appending directly to the body ensures it’s above other portals
  );
};

function App() {
  console.log("appVersion", REACT_APP_VERSION);

  useEffect(() => {
    const clearStorageAndRedirect = () => {
      localStorage.clear();
      sessionStorage.clear();
      if ("caches" in window) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => caches.delete(cacheName));
        });
      }
      localStorage.setItem("isVerify", true);
      localStorage.setItem("version", REACT_APP_VERSION);
    };

    const oldVersion = localStorage.getItem("version");
    const isVersionChecked = localStorage.getItem("isVerify");

    if (REACT_APP_VERSION) {
      if (!isVersionChecked || oldVersion !== REACT_APP_VERSION) {
        clearStorageAndRedirect();
      } else {
        localStorage.setItem("isVerify", true);
      }
    }
  }, []);
  return (
    <>
      <div>
        <BrowserRouter>
          <Routes>
            <Route element={<CheckLoginRoute />}>
              <Route path="/" element={<Login />} />
              <Route path="/forget-password" element={<ForgetPWd />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="/canvass-map" element={<CanvassMapPage />} />
              <Route path="/surveyentries" element={<SurveyEntryPage />} />
              <Route path="/directory" element={<DirectoryPage />} />
              <Route path="/instructions" element={<InstructionsPage />} />
              <Route path="/helpdesk" element={<HelpDeskPage />} />
              <Route path="/dashboard" element={<DigitalReachPage />} />
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/privancy" element={<PrivacyPage />} />
              <Route path="/import-data" element={<ImportDataPage />} />
              <Route path="/walking-list" element={<WalkingListPage />} />
              <Route path="/database" element={<DatabasePage />} />
              <Route path="/documents" element={<DocumentationPage />} />
              <Route path="/actions" element={<ActionPage />} />
              <Route path="/accountSetting" element={<AccountSetting />} />
              <Route path="/profileSetting" element={<ProfileSetting />} />
              <Route path="/form" element={<HubsportForm />} />
              <Route path="/schedule-meeting" element={<ScheduleMeet />} />
            </Route>
            <Route path="/voter-servey" element={<VoterForm />} />
            <Route path="/generate-password" element={<GeneratePassword />} />
          </Routes>
        </BrowserRouter>
      </div>
      <ToastPortal />
    </>
  );
}

export default App;
