import React, { useEffect} from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
const { REACT_APP_HUBSPOT_PORTAL_ID } = process.env;


const VoterForm = () => {
  const [searchParams, ] = useSearchParams()
  const formIdFromUrl = searchParams.get("formid")
  const createHubSpotForm = (email) => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: REACT_APP_HUBSPOT_PORTAL_ID,
          formId: formIdFromUrl,
          target: "#hubspotForm",
          onFormReady: function ($form) {
            $form.querySelector('input[name="canvasser_email"]').value = email;
          },
        });
      }
    });
  };

  useEffect(() => {
    if (formIdFromUrl) {
      const searchParams = new URLSearchParams(window.location.search);
      const saveApiEmail = searchParams.get("CanvasserEmail");
      createHubSpotForm(saveApiEmail)
    } else {
      toast.error("Form Not found")
    }

  }, [formIdFromUrl]);

  return (
    <div className="lg:p-10">
      <h1 className="text-troo-primary pb-2 border-b border-troo-primary mb-12 font-semibold">
        Survey Form
      </h1>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default VoterForm;
